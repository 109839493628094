.filters{
	border:2px solid $c_secondary;
	position:relative;
	strong{font-weight: 900;}

	&__actions{
		border-top:2px solid $c_secondary;
		padding-bottom:2px;
		position:relative;
	}

	&__state{
		padding:20px 20px;

		&__title{
			margin:0 0 10px;
			display:block;
		}

		&__items{
			position:relative;display:block;
			list-style-type:none;
			padding:0;margin:0;
		}

		&__item{
			padding:0;margin:0;

			font-size:rem(16);line-height:rem(18);
			color:$c_dark_grey;

			&__label{
				margin-bottom:3px;
				display:block;

				font-weight:bold;
				color:$c_secondary;

			}

			+ .filters__state__item{ margin-top:8px; }
		}
	}

}

.action.filter-clear{
	padding-left:20px;
	display:block;
	width:100%;
}

.filter{
	position:relative;display:block;

	&:last-of-type{ .filter__title{ margin-bottom:0; } }

	&.active{
		.filter__title{
			background-color:$c_accent;
			border-color:$c_accent;

			&:hover,&:focus{
				background-color:$c_accent;
				border-color:$c_accent;
			}

			.icon--expand{ display:none; }
			.icon--minimize{ display:block; }
		}
	}

	&__title{
		margin-bottom:2px;padding-left:15px;
		position:relative;display:block;
		width:100%;height:auto;
		cursor:pointer;

		.icon--expand{ display:block; }
		.icon--minimize{ display:none; }

		.btn__text{ > span{ display:none; } }
	}

	&__content{
		padding:20px 15px;margin:0;
		position:relative;
		color:$c_primary;
	}

	&__items{
		list-style-type:none;
		padding:0;margin:0;

		&__slider{
			max-width: 185px;margin: 0 auto;
			.btn{padding: 5px;width: 100%;margin-top: 10px;}
			.noUi-touch-area{background:$c_primary;cursor:pointer;}
			input{font-size:1rem;width: 65px;margin:0 auto;padding:0;text-align:center;}
			.slider-input-wrapper{width:50%;float:left;margin-top:10px;text-align:center;}
		}
	}

	&__item{
		position:relative;display:block;
		margin:0;padding:0 0 0 20px;

		font-size:rem(16);line-height:rem(18);
		font-weight:normal;

		&__icon{
			position:absolute;display:block;
			width:auto;height:auto;
			top:0;left:0px;

			font-size:rem(20);line-height:1;
		}

		+ .filter__item{ margin-top:5px; }

		a{ &:hover,&:focus{ font-weight:bold; } }
	}
}