.header{
	position:relative;display:block;
	margin:30px 30px;//margin:0;
	z-index:20; background:$c_primary;
    border:2px solid $c_primary;

    @media all and (max-width:767px ){margin:0;  border-width: 0 0 2px 0;}
	&__content{

		position:relative;
       // background: $c_primary;
        @media all and (max-width: 767px){background: white;}

		> *{ max-width:100%; }

		.mobile-searchbar{display:none;}
	}

	.logo{
		justify-content:center;align-items:center;
		position:relative;display:flex;
		flex-flow:column wrap;
        background: white;
		max-width:200px;width:auto;height:auto;
		float:none;clear:both;
        padding: 6px 0;margin:0;
		flex:0 0 200px;

		img{
			width:100%;height:auto;
			padding:0;margin:0; display: inherit;
			&.small-logo{display: none;}
			&.large-logo{display:block;max-width:185px;margin:0 auto;}
		}
	}

	&__navigation{
		position:relative;display:flex;
		padding:0;margin:0 1% 0 0;width:74%;
        background-color:inherit;
		color:#ffffff;
        z-index: 1;
        @media all and (max-width: 1050px ){width:auto;margin:0;}
	}

	.search{
		position:relative;
        display: flex;width:100%;
        align-items: center;

		.field{ margin:0; }

		.input-text{
			position:relative;display:block;
			padding:0 80px 0 20px;margin:0;
			width:100%;height:60px;max-width:100%;
			border:none;

			font-size:rem(16);line-height:60px;
			color:$c_dark_grey;
			text-align:left;
			font-weight:700;
		}

		.actions{
			position:absolute;display:block;
			width:80px;height:100%;
			padding:0;margin:0;
			top:0;right:0;
		}

		.action--search{
			position:absolute;display:block;
			transform:translate(-50%,-50%);
			top:50%;left:50%;
			background:none;
			border:none;

			font-size:rem(24);line-height:rem(24);
			padding:10px 10px;margin:0;
			color:$c_primary;

			&:hover,&:focus{ &:not(:disabled){ color:$c_dark_blue; } }
		}
	}

	&__customer,&__customer ul{
		list-style-type:none;
		padding:0;margin:0;
	}

	&__customer{
		border-left:2px solid $c_primary;
		position:relative;
		min-width:123px;
		z-index:5;

		li{
			border-bottom:2px solid $c_primary;
			padding:0;margin:0;
			position:relative;

			font-size:rem(12);line-height:rem(14);
			text-transform:uppercase;
			color:$c_primary;
			font-weight:900;
		}

		a{
			justify-content:center;align-items:center;
			position:relative;display:flex;

			padding:12px 12px;margin:0;
			background-color:#ffffff;
			width:100%;height:100%;

			font-size:inherit;line-height:inherit;
			text-transform:uppercase;
			font-weight:inherit;
			text-align:center;
			color:inherit;

			&:hover,&:focus{
				background-color:$c_dark_blue;
				color:#ffffff;
			}
		}

		> li{
			border-bottom:none;
			height:100%;

			&:hover,&:focus{
				> ul{
					pointer-events:auto;
					opacity:1;
				}
			}
		}

		&__subs{
			@include transition(opacity);

			border:solid $c_primary;border-width:2px 0 0 2px;
			position:absolute;display:block;
			pointer-events:none;
			left:-2px;top:100%;
			opacity:0;
			&.logged-in{width:102%;}
		}


	}

	&__additionals{ background-color:$c_darker_blue;
        @media all and (max-width:767px ){order:2;}
		a{
			justify-content:center;align-items:center;
			position:relative;display:flex;

			&.btn{ border:none; }
		}

		&__upper,&__lower{
			position:relative;
			min-height:75px;
		}

        &__top{

            @media all and (max-width: 1050px ){flex-direction: column;}
            &__left{
                margin:15px 2%;padding:0;list-style: none;
                flex-shrink: 1;flex-grow: 1;
                @media all and (max-width: 767px ){flex-direction: column-reverse;padding:20px;}
                li{margin:0 0 0 20px;padding:0;display:flex;
                    @media all and (max-width: 767px ){margin:0 0 10px 0;}
                    &.item--lang{text-transform: uppercase;}
                    a{
                        color:white;font-weight:bold;font-size:rem(14);
                        &:hover{color:$c_accent;}
                    }
                    &::before{
                        content:"";
                        height:10px;width:1px;
                        background-color:$c_accent;
                        display: flex;align-self: center;margin-right: 20px;
                        @media all and (max-width: 767px ){content:none;}
                    }
                    &:first-child{margin:0;
                        @media all and (max-width: 767px ){margin:0 0 10px 0;}
                        &::before{
                            content:inherit;
                        }
                    }
                }
            }
            &__right{border-left: 2px solid $c_primary;max-width: 358px; flex-shrink: 3;flex-grow: 1;
                .search__form{width:100%;}
                @media all and (max-width: 1050px ){max-width: 100%;padding: 0 15px 15px;}
                @media all and (max-width: 767px ){display: none;}
            }
        }

	}
	&-container{width:100%;display: flex;
		&--open{

		}
	}



	.mobile-search{display:none;}
	.nav-toggle{ right: 15px; left:inherit;}
	.main-menu-trigger{
		text-decoration: none;
		cursor: pointer;
		display: none;
		font-size: 0;
		right: 15px;
		position: absolute;
		top: 15px;
		z-index: 50;
		span{ border: 0; clip: rect(0, 0, 0, 0); height: 1px;margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;}
		&::before{-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;	font-size: 28px;
			line-height: inherit;color: #8f8f8f;content: '\e609';font-family: 'icons-blank-theme';
			vertical-align: middle;	display: inline-block;	font-weight: normal;
			overflow: hidden;speak: none;text-align: center;
		}
	}

	.switcher-language{
		padding:0;margin:0 0 12px;
		.switcher-options{ display:block; margin:0;padding:0; }
		.switcher-dropdown{
			justify-content:flex-end;align-items:center;
			flex-flow:row wrap;
			display:flex;

			list-style-type:none;
			margin:0;padding:0;


			li{
				margin:0 5px 0 0;
				&:last-child{ margin-right:0; }
			}
			a{
				margin:0;padding:0;

				font-size:rem(15);line-height:1;
				font-weight:bold;
				color:$c_primary;
			}
		}
	}
}

.header__customer > li > a .icon{
	font-size:rem(21);
	display:none;
}

.menu--main{
    align-items: center; justify-content: space-evenly;
	position:relative;display:flex;
	flex-wrap:wrap;
	width:100%;height:auto;
    padding: 0; margin: 5px 2% 0;

	font-size:rem(15);line-height:0.9;
	text-transform:uppercase;
	letter-spacing:0.065em;
	font-weight:900;


	li,a{ color:inherit; }

	> li{
		padding:8px 8px;margin:0;
		position:relative;

        @media all and (max-width: 767px){
            &:not(.customer-sub-nav__item){margin-bottom:10px !important;padding:8px 8px;}
            &:last-child{ margin-bottom:0 !important; }
        }
        &.item--withchilds{

        }
        .customer-sub-nav{display: none;text-align:center;margin-top:10px;
            @media all and (max-width: 767px){display: block !important;}
            li.customer-sub-nav__item{padding:8px;
                a{font-size:rem(14);text-transform: none;padding:0;margin:0;}
            }
        }
        &.mobile-only{display: none;
            @media all and (max-width: 767px){display: block;}
        }


		&.level0,&.level1{ margin:0;padding:8px 8px; }

		&.nav.item.current strong{font-weight: 900;}

		//&.active a,&.current strong,
		> a:hover, strong:hover,
		> a:focus, strong:hover, a.current, strong.current{
			&::after{ opacity:1;
                @media all and (max-width: 767px){display: none;}
            }
		}

		> a,strong{
			position:relative;display:block;
			padding:0 0 5px 0;margin:0;
            @media all and (max-width: 767px){padding:0;}
			&::after{
				@include transition(opacity);
				position:absolute;display:block;
				background-color:#ffffff;
				width:100%;height:2px;
				bottom:0;left:0;
				content:" ";
				opacity:0;
			}

		}
	}
}


.header-container{    z-index: 10;
    &__wrap{padding: 25px 15px;height:100%;
        @media all and (max-width:767px ){order:1;flex-direction: column;background:$c_primary;}
    }
    .btn-expert{text-align: center;width:25%;display: flex;font-size:rem(14);
        align-self: center;
        @media all and (max-width: 1050px ){width:auto;}
        @media all and (max-width:767px ){ display: none; }
        .btn__text{width:100%;text-align: center;}
        .btn__icon{font-size:24px;}
    }
}

@media(min-width:768px){ a[href^="tel:"] { pointer-events: none;}}

/*FIX IE 10-11*/
/*@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
tml{max-width:1920px; margin:0 auto;}
.header__content{min-height: 90px;}
.header .search .input-text{line-height: 55px;}
body.search-open .mobile-searchbar{position:relative;top:88px; width:100%; left:0; border-width: 0 2px 2px 2px; border-color:$c_blue; border-style: solid;}
body.menu-open .header-container{ margin-top:88px;}
body.menu-open.search-open .mobile-searchbar{border-width: 2px 2px 2px 2px;}
.header__customer .header__customer__subs li{border-right:2px solid $c_primary;}
.header-container{margin-top:-1px;}
.header__additionals{width:26%;}
.header .logo img.large-logo{
    transform: translateY(-50%);
    position:absolute;
    top:50%;left:0;
}
}*/
/*Remove x when typing*/
input#search{ &::-ms-clear, &::-ms-reveal {  display: none; width : 0; height: 0; }}
.navigation .level0.active > a:not(.ui-state-active), .navigation .level0.has-active > a:not(.ui-state-active){border:none;}
.navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon), .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon){margin:0;}


.customers-link{
    .icon-chevron{margin-left:5px;
        @media all and (max-width: 767px){display: none;}
        i{transition:transform  0.3s ease-out;}
    }
    &.active{
        .icon-chevron i {transform: rotate(180deg);}
    }
    @media all and (max-width: 767px){text-align: center;}
}



.megamenu-customers{
    @media all and (max-width: 767px){display: none !important;}
    position: absolute;
    left:0;top:101%;width:100%;
    overflow:hidden;
    transition:max-height 0.7s ease-out;
    max-height:0;
    background:white;
    display: flex;    z-index: 0;
    border-width: 0 2px 2px 2px;border-style: solid;border-color:transparent;

    &.active{
        border-color:$c_primary;
       // height:auto;   overflow:visible;
        max-height:750px; // still have to hard-code a value!
       // .megamenu-customers__content,.megamenu-customers__newsletter{ opacity: 1;}
    }
    //&__content,&__newsletter{ transition: opacity 1s; opacity: 0;}
    &__content{
        background:white;flex-direction: column;
        display: flex;flex-basis:100%;
        &__top{display:flex;flex-wrap:wrap;padding:30px 0 30px 30px;
            @media all and (max-width: 1200px){flex-direction:column;padding:20px;}
            @media all and (max-width: 992px){padding:10px;}
        }
        &__action{display: flex;justify-content: center;max-height: 60px;
            height: 100%; align-items: center;padding:10px;margin-top:auto;
            border-top:2px solid $c_primary;text-align: center;
            a{text-transform: uppercase;font-weight:900;
                    &:hover{color:$c_dark_blue;}
            }
        }
    }
    &__newsletter{
        background: $c_darker_blue;padding:5px 40px 25px;
        display: flex;flex-basis: 25%;flex-direction:column;justify-content: center;
       .btn-subscribe{text-align: center;font-size:rem(14);margin-top:20px;       .btn__icon{font-size:24px;}}
        &__title-main{font-size:40px;text-transform: uppercase;color:white;font-weight: 900;letter-spacing:2px;text-align: center;margin-bottom:30px;
            position:relative;margin-top: -15px;
            &::before{content:"";display: block;width:100%;border-width:1px 0;border-color:white;border-style: solid;height:5px;margin-bottom:10px; }
            &::after{content:"";display: block;width:100%;border-width:1px 0;border-color:white;border-style: solid;height:5px;margin-top:10px; }
            &-icon{position: absolute;left: 50%;transform: translateX(-50%);
                background:$c_darker_blue;    border-radius: 100%;
                width: 46px;
                height: 46px;
                font-size: 25px;
                display: flex;
                align-items: center;
                border:1px solid white;
                justify-content: center;
                i{margin-left:2px;}
            }
        }
        &__text{
            color:white;font-size:rem(16);text-align: center;padding:20px;
        }
        &__form{
            margin: 10px auto 0;width:100%;
            .input-text{color:$c_primary;font-size:rem(14);font-weight: bold;height: 50px;
                &::placeholder {color:$c_primary;}
                &:focus::placeholder {color: transparent; }
            }
            button{width:100%;}
        }
    }

}

.customer-type-box{display:flex;flex-basis: 50%;margin-bottom: 15px; margin-top: 15px; flex-wrap: wrap;
    @media all and (max-width: 1200px){flex-basis: inherit;margin-top:0;margin-bottom: 0;}
    @media all and (max-width: 992px){}
    &__img{flex-basis: 28%;
        @media all and (max-width: 1200px){flex-basis: 14%;align-self: center;}
        @media all and (max-width: 992px){display: none;}
        a{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: flex-start;
        }
    }
    &__text{padding: 20px 30px 20px 20px;flex-basis: 72%;
        @media all and (max-width: 1200px){padding:15px;flex-basis: 86%;}
        @media all and (max-width: 992px){flex-basis: 100%;}
        &__title{margin-bottom:10px; a{font-size:rem(20);color:$c_primary;font-weight:900;line-height: normal;  &:hover{color:$c_dark_blue;}}}
        &__desc{color:$c_primary;font-size:rem(13);}
    }
}
