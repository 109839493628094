.toolbar{
	width:100%;height:auto;
	position:relative;

	&__label{
		margin-right:15px;
		display:block;

		font-size:rem(16);line-height:rem(18);
		font-weight:normal;
		color:$c_primary;
	}

	&__select{
		select{
			border-color:$c_dark_grey;
			padding:0 12px;margin:0;

			text-transform:none;
			font-size:rem(14);
		}
	}

	&__amount{ padding:0;margin:0 30px 0 0; }
}

.page-products .toolbar .limiter{ display:block; }

.limiter{
	&__text{
		margin:0 0 0 15px;
	}

	+ .sorter{ margin-left:30px; }
}

.sorter{
	margin:0;padding:0;

	&__action{
		color:$c_medium_grey;
		margin-left:15px;

		&:hover,&:focus{ color:$c_primary; }
	}
}

// remove tools from top toolbar
.toolbar--products:first-of-type{
	.pages,.pagination,.pager__pages{ display:none; }
}

// remove tools from bottom toolbar
.products + .toolbar{
	.toolbar__amount,
	.toolbar__sorter{
		display:none;
	}
}