#fullpage-slider{
	transition:opacity 240ms ease-in-out;
	opacity:0;

	&__loader{
		width:100%;height:0;

		font-size:36px;line-height:1;
		color:$c_secondary;
		text-align:center;
	}
}

.page-layout-about{
	.page-main{
		padding-left:0;padding-right:0;padding-top:0;padding-bottom:0;
		max-width:100%;
	}
}

.about{
	position:relative;

	.slider.slick-initialized + .slider__loader{ display:none; }

	.slider{
		&__loader{
			position:absolute;display:block;
			font-size:rem(32);line-height:1;
			color:#ffffff;

			transform:translate(-50%,-50%);
			top:50%;left:50%;
		}

		&__arrow{
			width:auto !important;height:auto;
			position:absolute;display:block;
			transform:translateY(-50%);
			margin:0;padding:10px 10px;
			background:transparent;
			border:none;
			z-index:5;
			top:50%;

			&:hover,&:focus{ background-color:transparent;color:$c_primary; }

			line-height:1;
			color:#ffffff;

			.fa{ box-shadow:0px 0px 3px 0px rgba(0,0,0,0.75); }

			&--left{ left:rem(25); }
			&--right{ right:rem(25); }
		}

		&.slick-initialized{
			opacity:1;

			.slide{ width:100%;height:100%; }
		}

		&.slick-slider{ margin-bottom:0; }

		.slick-track,.slick-list{
			height:100% !important;
		}

		.slick-dots{
			align-items:center;justify-content:center;
			flex-flow:row nowrap;

			position:absolute;display:flex;
			transform:translateX(-50%);
			bottom:Vmin(40);left:50%;
			width:auto;height:auto;
			z-index:5;

			li{
				margin:0 rem(13) 0 0;padding:0;
				width:auto;height:auto;
				display:block;

				&:last-child{ margin-right:0; }

				&.slick-active{
					button{
						background-color:$c_primary;
						&:hover,&:focus{ background-color:$c_primary; }
					}
				}

				button{
					box-shadow:0px 0px 3px 0px rgba(0,0,0,0.75);
					transition:240ms ease-in-out;
					background-color:#ffffff;
					width:13px;height:13px;
					padding:0;margin:0;
					border-radius:50%;
					opacity:1;

					&::before,&::after{ display:none; }

					&:hover,&:focus{ background-color:lighten($c_primary,30%); }
				}
			}
		}
	}

	&__section{
		background-repeat:no-repeat;background-position: center center;background-size:cover;
		//padding-top:Vmin(100);padding-bottom:Vmin(100);
		width:100%;min-height:0;height:auto;
		padding-top:0;padding-bottom:0;


		.about__section__title__emphasis{
			@media screen and (max-width: 576px) {font-size:rem(120); margin-top: 12%;}
		}


		&--boxed{ border:30px solid #ffffff;
			@media screen and (max-width: 768px) {border:none;}
		}

		&--fullwidth{
			.about__section__col--inner{
				padding-left:5%;padding-right:5%;

				> *{ width:100%;max-width:100%; }
			}
		}

		&--team{
			.row{margin:0;}
			//.about__section__col{ min-height:auto; }
			.about__section__title__emphasis{margin-top:14%;}

			@media screen and (max-width: 991px) {
				.about__section__col--inner{height:25vh;min-height:25vh;flex-direction: row;padding-top:0;padding-bottom:0;padding-left:8%;
					.about__section__title{ flex-direction: row;height: auto;align-items: center;margin-bottom: 0;
						justify-content: space-around;
						span{margin-bottom: 10%;width: 120px;display: block;font-size:rem(22);}
						.about__section__title__emphasis{margin-top:30%;font-size:rem(120);}
					}
					.about__section__desc{margin-top: 8%;text-align: left;margin-left: 8%;}
				}
				.about__section__col--slider{height: 75vh;min-height:75vh;padding-bottom: 20px;
					.about__team-slider__slide img{max-height: 250px !important;}
				}
				.about__section__title__emphasis{margin-top:0;font-size:rem(140);}
				.about__section__title{margin-top:0;display: flex;flex-direction: column;align-items: center;justify-content: center;height: 50vh;
					span{margin-top: 20%;margin-bottom: 15%;text-align:left;}
				}
			}

			@media screen and (max-width: 576px) {
				height:auto !important;
				.about__section__col--inner{flex-direction: column; height:300px;min-height: 300px;padding-left:5%;
					.about__section__title{flex-direction: column;    align-items: flex-start;    margin-top: 15%;
						span{width:100%;margin:0;text-align:center;}
						.about__section__title__emphasis{ margin: 10% auto 0 auto;}
					}
					.about__section__desc{margin-top:0;text-align: center;margin-left:0; padding: 0 20px;}
				}
				.about__section__col--slider{margin-top:-25%;    height: auto;
					min-height: 500px;
					.about__team-slider__slide.slide img{bottom:0 ;top:inherit;transform: translateX(-50%);min-height: 66%;}
				}
			}
			@media screen and (max-width: 440px) {
				.about__section__col--slider{margin-top:-35%;}
				.about__section__col--inner .about__section__title .about__section__title__emphasis{margin:12% auto 0 auto;}
			}

		}

		&--negation{
			.about__section{
				&__title{
					@include rfs(36);

					&__emphasis{
						font-size:400%;
					}
					span{ font-weight:400; color:$c_accent;  }
				}
			}
		}

		&--missions{
			.about__section__col--inner{ padding:0; }
			.about__missions-slider__slide__content{
				@media screen and (max-width: 1300px) {margin-left:110px;}
				@media screen and (max-width: 576px) {margin:0 auto;}
			}


		}
		&--aboutyou{
			.about__section__desc{margin-top:20px;}
		}
		&--abouttrust{
			.about__section__title__emphasis{margin-top:10%;}
		}


		> .row, > .container, > .container > .row{
			height:100%;
		}

		&__col{
			min-height:100%;height:100%;

			&--inner{
				justify-content:center;align-items:center;
				flex-flow:column nowrap;
				display:flex;

				padding-top:Vmin(100);padding-bottom:Vmin(100);
			}

			&--slider.slider{
				transition:opacity 240ms ease-in-out;
				opacity:0;

				.slick-dots{
					flex-flow:column nowrap;

					left:unset;right:Vmin(40);
					top:50%;bottom:unset;

					li{
						margin:0 0 rem(13) 0;

						&:last-child{ margin-bottom:0; }
					}
				}

				.slide{
					position:relative;
					overflow:hidden;
					width:0;height:0;

					img{
						display:block;position:absolute;
						transform:translate(-50%,-50%);
						max-width:100%;max-height:none;
						min-width:100%;min-height:100%;
						object-fit: contain;
						background:$c_accent;
						width:auto;height:auto;
						top:50%;left:50%;
					}

					&__desc{
						background-blend-mode:multiply;
						background-color:rgba($c_secondary,0.70);
						position:absolute;display:block;
						bottom:0;left:0;//left:- #{Vmin(40)};
						width:395px;max-width:100%;
						padding:rem(15) rem(30);


						text-align:center;

						.headline{
							@include rfs(66);line-height:1.10;
							margin:0;
						}

						span{
							display:block;position:relative;
							margin:0 0 3px;
						}
					}
				}
			}
		}

		&__title{
			@include rfs(28);line-height:1.2;
			margin:0 0 3% 0;

			&__emphasis{
				display:block;position:relative;
				margin:6.5% 0 0 0;padding:0;

				&:first-child{ margin:0 0 6.5% 0; }
				&:first-child:last-child{ margin:0 0 0 0; }

				font-style:normal;font-weight:bold;
				font-size:800%;line-height:0.7;
				font-family:$f_script;
				text-transform:none;
			}
		}

		&__desc{
			max-width:690px;width:100%;height:auto;
			margin:0 auto;padding:0;

			p{
				font-size:rem(18);line-height:1.4;
			}
		}

		&__trigger{
			position:absolute;display:block;
			padding:2% 0;margin:0;
			width:100%;height:auto;
			bottom:0;left:0;
			cursor:pointer;

			font-size:rem(32);line-height:1;
			text-align:center;
		}
	}

	&__missions-slider{
		&__slide{
			background:no-repeat center center;background-size:cover;
			height:100% !important;

			.container{ height:100% !important; }

			&__content{
				width:100%;max-width:50%;
			}

			&__title{
				@include rfs(66);line-height:1;
				font-weight:normal;

				span,strong{ display:block; }
				span{
					font-family:$f_script;
					font-size:50%;

					@media screen and (max-width: 768px) {font-size:75%;}

				}
				strong{
					text-transform:uppercase;
					font-weight:900;
				}
			}
			&__desc{
				@include rfs(24); line-height:1.25;
			}
		}
	}

	&__actions{
		@media screen and (max-width: 768px) {flex-direction: column;
			.about__action:first-child{margin:0 0 10px 0;}
		}
	}
}


html[lang="fr"]{
	.page-layout-about{
		.about__section--intro{
			.about__section__title__emphasis{margin-bottom:30px;}
		}

		.about__section--team{
			.about__section__title__emphasis{margin-top:18%;font-size:rem(160);}

			@media screen and (max-width: 991px) {
				.about__section__title__emphasis{font-size:rem(110);margin-top:24%;}
				.about__section__col--inner{padding-left:5%;
					.about__section__desc{text-align: right;}
				}

				.about__section__col--slider{padding-bottom: 20px;}

			}
			@media screen and (max-width: 576px) {
				.about__section__col--inner{flex-direction: column; height:300px;min-height: 300px;
					.about__section__title{ margin-top: 15%;
						.about__section__title__emphasis{ margin: 12% auto 0 auto;}
					}
					.about__section__desc{margin-top:0;text-align: center;margin-left:0; padding: 0 20px;}
				}
				.about__section__col--slider{margin-top:-25%;height: auto;min-height: 500px;
					.about__team-slider__slide.slide img{bottom:0 ;top:inherit;transform: translateX(-50%);min-height: 66%;}
				}
			}
			@media screen and (max-width: 440px) {
				.about__section__col--slider{margin-top:-35%;}
				.about__section__col--inner{margin-top: 5%;
					.about__section__title .about__section__title__emphasis{margin:15% auto 0 auto;}
				}
			}
		}
	}
}

body.page-layout-about.fancybox-iosfix{top:0 !important;}