@function rem($size){
	$remSize: $size / $baseFontSize;
	@return $remSize * 1rem;
}

@function Vmin($size) {
	$vMinSize: $size / $baseFontSize;
	@return $vMinSize * 1.675vmin;
}

@mixin transition($prop:all,$dur:240ms,$ease:ease-in-out){
	transition:$prop $dur $ease;
}

@keyframes slide-down {
	0% { opacity: 0; -webkit-transform: translateY(-100%); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes slide-up {
	0% { opacity: 1; -webkit-transform: translateY(0); }
	100% { opacity: 0; -webkit-transform: translateY(-100%);  }
}
