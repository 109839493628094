%btn-base-styles{
	@include transition();

	position:relative;display:inline-block;
	width:auto;max-width:100%;height:auto;
	border:1px solid transparent;
	background-color:transparent;
	border-radius:0px;
	padding:0;margin:0;
	appearance:none;

	text-transform:uppercase;text-decoration:none;
	font-size:rem(16);line-height:rem(18);
	font-weight:900;font-style:normal;
	white-space:normal;
	color:$c_primary;
}

a.btn, span.btn, button.btn, .btn{
	@extend %btn-base-styles;

	&--align--left{ text-align:left; }
	&--align--right{ text-align:right; }
	&--align--center{ text-align:center; }

	&--raised,&--outline{
		padding:15px 15px;

		&.btn--icon{


			&--left{
				padding-left:60px;

				.btn__icon{
					left:15px;
					top:50%;

					@media all and (max-width:992px){left:20px;}
					@media all and (max-width:767px){left:25px;}
				}
			}

			&--right{
				padding-right:40px;

				.btn__icon{
					right:15px;
					top:50%;

					@media all and (max-width:992px){right:20px;}
					@media all and (max-width:767px){right:25px;}
				}
			}
		}
		.btn__icon{
			position:absolute;display:block;
			transform:translateY(-50%);
		}
	}

	&--raised{
		background-color:$c_primary;
		border-color:$c_primary;
		color:#ffffff;

		&:hover,&:focus,&:active{
			background-color:darken($c_primary,25%);
			border-color:darken($c_primary,25%);
			color:#ffffff;
		}

		&--dark-blue{
			background-color:$c_dark_blue;
			border-color:$c_dark_blue;

			&:hover,&:focus{
				background-color:darken($c_dark_blue,8%);
				border-color:darken($c_dark_blue,8%);
			}
		}

		&--secondary{
			background-color:$c_secondary;
			border-color:$c_secondary;

			&:hover,&:focus{
				background-color:darken($c_secondary,8%);
				border-color:darken($c_secondary,8%);
			}
		}

		&--accent{
			background-color:$c_accent;
			border-color:$c_accent;

			&:hover,&:focus{
                background-color:darken($c_primary,25%);
                border-color:darken($c_primary,25%);
			}
		}

		&--dark-grey{
			background-color:$c_dark_grey;
			border-color:$c_dark_grey;

			&:hover,&:focus{
				background-color:darken($c_dark_grey,25%);
				border-color:darken($c_dark_grey,25%);
			}
		}

		&--white{
			background-color:#ffffff;
			border-color:#ffffff;
			color:$c_primary;

			&:hover,&:focus{
				background-color:$c_dark_blue;
				border-color:$c_dark_blue;
				color:#ffffff;
			}
		}

		&--black{
			background-color:$c_black;
			border-color:$c_black;
			color:#ffffff;

			&:hover,&:focus,&:active{
				background-color:#000000;
				border-color:#000000;
				color:#ffffff;
			}
		}
	}

	&--outline{
		background-color:transparent;
		border-color:$c_primary;
		color:$c_primary;

		&:hover,&:focus,&:active{
			background-color:$c_primary;
			color:#ffffff;
		}

		&--dark-blue{
			border-color:$c_dark_blue;
			color:$c_dark_blue;

			&:hover,&:focus{
				background-color:$c_dark_blue;
				color:#ffffff;
			}
		}

		&--white{
			border-color:#ffffff;
			color:#ffffff;

			&:hover,&:focus{
				background-color:#ffffff;
				color:$c_dark_grey;
			}
		}

		&--light-grey{
			border-color:$c_light_grey;
			color:$c_light_grey;

			&:hover,&:focus,&:active{
				background-color:$c_light_grey;
				color:#ffffff;
			}
		}

		&--dark-grey{
			border-color:$c_dark_grey;
			color:$c_dark_grey;

			&:hover,&:focus,&:active{
				background-color:$c_dark_grey;
				color:#ffffff;
			}
		}

		&--dark-blue{
			border-color:$c_dark_blue;
			color:$c_dark_blue;

			&:hover,&:focus,&:active{
				background-color:$c_dark_blue;
				color:#ffffff;
			}
		}

		&--secondary{
			border-color:$c_primary;
			color:$c_primary;

			&:hover,&:focus,&:active{
				background-color:$c_primary;
				color:#ffffff;
			}
		}

		&--accent{
			border-color:$c_accent;
			color:$c_accent;

			&:hover,&:focus,&:active{
				background-color:$c_accent;
				color:#ffffff;
			}
		}

		&--black{
			border-color:$c_black;
			color:$c_black;

			&:hover,&:focus,&:active{
				background-color:$c_black;
				color:#ffffff;
			}
		}
	}

	&--small{
		font-size:rem(14);line-height:rem(16);
		padding:6px 10px;
	}

	&--medium{
		font-size:rem(15);line-height:rem(17);
		padding:10px 13px;
	}

	&--large{
		font-size:rem(22);line-height:rem(26);
		padding:20px 26px;
	}

	&--transform--uppercase{ text-transform:uppercase; }
	&--transform--lowercase{ text-transform:lowercase; }
	&--transform--normal{ text-transform:none; }
}

html.ie10 body, html.ie11 body{
	select{padding:0; padding-left:10px;}
}

body.account{
	.field.choice label{cursor:pointer;}
}
