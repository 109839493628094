.expando{
	border-bottom:1px solid $c_primary;
	position:relative;display:block;
	width:100%;height:auto;

	&__trigger{
		display:block;position:relative;
		/*padding:8px 16px;*/margin:0; padding: 8px 50px 8px 16px;
		cursor:pointer;

		@include transition(all,240ms,ease-out);

		&:focus,&:hover{
			background-color:$c_primary;
			box-shadow:none;
			color:#ffffff;
			outline:none;
		}

		&::after{
			border-right:6px solid transparent;
			border-left:6px solid transparent;
			border-top:6px solid currentColor;

			display:block;position:absolute;
			top:50%;right:16px;
			content: ' ';

			@include transition(transform,200ms,ease-out);

			vertical-align: middle;
			margin-right:0;
			transform: translate(-50%,-2px);
		}
	}

	&__toggle{
		display:none;

		&:checked{
			~ .expando__expandable{ max-height: 350px; }

			~ .expando__trigger{
				background-color:$c_primary;
				box-shadow:none;
				color:#ffffff;
				outline:none;

				&::after{ transform:rotate(-180deg) translate(calc(-50% - -9px),4px); }
			}
		}
	}

	&__expandable{
		width:100%;height:auto;max-height:0px;
		position:relative;display:block;
		overflow:hidden;

		@include transition(max-height);
	}

	&__content{
		position:relative;display:block;
		padding:10px 16px 25px 16px;
		width:100%;height:auto;
	}
}