.expertises{
	padding:0;margin-bottom:0;
	list-style-type:none;
	position:relative;

	.line-break{
		display:block;position:relative;
		width:100%;height:1px;
		overflow:hidden;
		margin-top:-1px;
	}

	&__slider{
		.slick-center{ font-size: 3em; }
	}
}

.expertise{
	padding:15px 15px;margin:0 0 15px 0;
	position:relative;display:block;

	//&:nth-last-of-type(3n-1){ margin-left:auto; }
	//&:nth-last-of-type(3n+1){ margin-right:auto; }

	&--view{ padding:0; }

	&__photo{
		align-self:stretch;
		overflow:hidden;
		padding:0;

		min-height:600px;

		&__img{
			display:block;position:absolute;
			transform:translate(-50%,-50%);
			min-width:100%;min-height:100%;
			max-width:none;max-height:none;
			width:auto;height:auto;
			top:50%;left:50%;
		}
	}

	&__header{
		&__intro{
			color:$c_primary;
			padding:6% 6%;

			.headline--main{
				max-width:770px;
				margin-left:-40%;
				@include rfs(150);
			}
		}
	}

	&__recipes{
		+ .expertise__articles{
			@media all and (max-width:575px){margin-top:40px;}
		}
	}

	&__recipes, &__products{
		.recipes__actions{
			padding-top:60px;

			.btn{ min-width:255px; max-width:100%; }
		}
		.recipes__description{
			margin-left:auto;margin-right:auto;
			width:100%;height:auto;
			max-width:680px;

			text-align:center;
			color:$c_primary;

			*{ color:inherit; }

			p,li{
				font-size:rem(18);
			}
		}
	}

	&__certifications{
		background:$c_primary url('../../images/bg-expertise-certifications.jpg') no-repeat center center;background-size:cover;
		position:relative;

		&__title{}
		&__description{
			width:100%;max-width:840px;height:auto;
			margin:0 auto 80px auto;
		}
	}

	&__ctas{
		.btn{
			min-width:255px; max-width:100%;
			margin-right:20px;

			&:last-child{ margin-right:0; }
		}
	}
}

.section-expertise-testimonies{
	position:relative;
	margin:0 -15px;

	.testimonies{
		background-color:transparent;
		color:$c_primary;
		padding:0;

		&__slider{
			.slider__controls{
				width:100%;max-width:100%;
			}

			.slider__arrow{
				background-color:$c_primary;
				color:#ffffff;
				&:hover,&:focus{ background-color:$c_accent; }
				&.slick-disabled,.slick-hidden{
					&:hover,&:focus{ background-color:$c_primary; }
				}
			}
		}
	}
}

.section-expertise-relationships{
	.headline--fancy{ padding-top:0;padding-bottom:0;margin-bottom:15px; }
}

.contentmanager-contenttype-expertises{
	.page-main{ padding-top:0; }
	.recipe__time__value a{ word-break: break-word;
		&::after{ content: ",\00a0\00a0";
			&:last-child{ content: none;}
		}
	}
}