.guestlist-banner{
    margin-top: 15px;margin-bottom: 30px;background-color:$c_bgblue;
    &__content{padding:30px 5%;  display: flex;
        @media all and (max-width: 576px){flex-direction: column}
        &__text{max-width: 560px;width:100%;padding:15px;}
        &__actions{display:flex;width:100%;align-items: center;margin-left:30px;justify-content: center;flex-wrap:wrap;padding: 15px 0;
           @media all and (max-width: 576px){ margin-left:0;padding:0;justify-content: flex-start;}
            a{margin:10px 30px 10px 0;
                @media all and (max-width:576px){margin: 10px 15px;}
                .btn__icon {font-size: 24px;}
            }
        }
    }
}

.guestlist-sidebar{min-width:300px;display: flex;flex-direction: column;
    @media all and (max-width: 767px){flex-direction: row;}
    @media all and (max-width: 576px){flex-direction: column;min-width: auto;}
    &__actions{        display: flex;flex-direction:column;
        @media all and (max-width: 767px){flex-basis: 50%;padding:15px 15px 15px 0;}
        @media all and (max-width: 576px){flex-basis: 100%;padding:0;}
        .btn{margin-bottom:15px;
            .arrow--right {font-size: 24px;}
        }
    }
    &__ad{
        width:300px;height: 600px;background-size: cover;background-position: center;background-repeat: no-repeat;
        @media all and (max-width: 767px){flex-basis: 50%;max-width: 300px;margin-left: auto;}
        @media all and (max-width: 576px){flex-basis: inherit;margin-left:0;max-width: 100%;}
    }
}

.guestlist-list{margin-left:30px; width:100%;
    @media all and (max-width: 767px){margin-left:0;}
}
.guest-list-container{
    margin-top:60px;
    display: flex;position: relative;
    @media all and (max-width: 767px){flex-direction: column-reverse;}
}
.overlay{
    &--mylist{width: 100%;
        height: 100%;
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.3);
        .loading{position: absolute;
            left: 50%;
            top: 10%;
            font-size: 16px;
            transform: translateX(-50%);}
    }
}


.submission-header{
    width:100%;
    display: flex;justify-content: center;align-items: center;
    @media all and (max-width: 767px){flex-direction: column;}
    h1{margin:0;font-size:rem(32);}
}

.custom-contact-link{
    position:absolute;right: 30px;top:20px;color:white;
    @media all and (max-width: 767px){position:relative;top:inherit;right: inherit;padding-top: 3px;}
    &:hover{color:$c_darker_blue;}
    &:visited{color:white;
        &:hover{color:$c_darker_blue;}
    }
}

.mylist-submission-index{
    .breadcrumb__item{display: flex;align-items: center;}
    #compare_list_container{display: none !important;}
      .guest-list-container{margin-top:0;
        @media all and (max-width: 767px){flex-direction: column;}
    }
    @media all and (max-width: 767px){.overlay--mylist{ .loading{ top: 5%;}}}

}
.guestlist-submission-list{ padding: 0 40px 0 15px;
    @media all and (max-width: 767px){margin-bottom:40px;border-bottom:1px solid $c_blue;padding-bottom: 40px;
        margin-left: 15px;margin-right: 15px;}
    .products__list{

        &--more{
            @media all and (max-width: 767px){
                .product--summary {padding: 15px 15px 0;}
                .product__name{margin:0 0 10px;display:flex;}
            }
        }
        &--one{}

        .product{ flex-basis: 100%; max-width: 415px;
            &--more{
                .card{flex-direction:row;
                    .product__details{padding:15px;}
                    .card__photo{flex:0 0 150px;}
                    @media all and (max-width: 767px){
                        .product__sku{margin-bottom:0;}
                        .card__photo{display:none;}
                        .card__content{border:none;padding:0;}
                        .product__inner{display: flex;}
                        h3.product__name.card__title::before {
                            content: "-";font-weight:normal;
                            margin: 0 5px;
                        }
                    }
                }

            }
            &--one{
                @media all and (max-width: 767px){
                    .card{flex-direction:row;
                        .product__details{padding:15px;}
                        .card__photo{flex:0 0 150px;}
                    }
                }
            }

            .card{
                .card__photo{
                    @media all and (max-width: 420px){flex:0 0 125px;}
                }
            }

        }
    }
}
