$c_landing_primary : #00a7b2;
$c_landing_secondary : #e5f6f7;
$c_landing_accent : #d51c3d;

.landing--achat-redonne{
	.section-landing-intro{
		background:#79cdcd center center;
		width:100%;height:100vh;
		background-size:cover;

		.container, .container > .row,.container > .row > [class*="col"]{
			min-height:100%;
		}
	}
	.landing-intro{
		align-items:center;justify-content:flex-start;
		display:flex;position:relative;
		flex-flow:column wrap;

		> *{ max-width:100%; }

		&__text{
			margin-bottom:Vmin(100);
			text-align:center;

			h1,h1.headline--main,
			h2,h2.headline--section{
				font-family:$f_main;
				text-transform:uppercase;
				color:$c_landing_accent;
				font-weight:900;
			}

			h1,h1.headline--main{
				@include rfs(48px);line-height:1.2;
				letter-spacing:-1px;
			}

			p,.p{
				@include rfs(22px);line-height:1.8;
				color:$c_dark_grey;

				@media all and (max-width:640px){
					font-size:rem(17);
				}
			}
		}
	}

	.logo{
		width:173px;height:auto;
		position:relative;
		margin:0 auto;

		img,svg{
			position:relative;display:block;
			width:auto;height:auto;
			max-width:100%;
		}

		&--intro{ margin-bottom:Vmin(70); }
		&--olymel{ margin-top:auto; }
	}
	.section-stats{
		&--donations{

			.stat{
				&__icon{ margin:0; }
				&__main{
					background-color:$c_dark_blue;
					padding:Vmin(20);
					color:#ffffff;

					@media all and (max-width:480px){
						padding:Vmin(35);
					}
				}
				&__amount{
					font-size:rem(40);

					@media all and (max-width:576px){
						font-size:rem(24);
					}
					@media all and (max-width:420px){
						font-size:rem(16);
					}
				}
				&__label{
					@include rfs(20px);

					@media all and (max-width:576px){
						font-size:rem(13);
					}
					@media all and (max-width:420px){
						font-size:rem(9);
					}
				}

			}
		}

		&--portions{
			background-color:$c_landing_secondary;

			.stat{
				&__main{

				}
			}
		}


		.wysiwyg{
			h3,h2{
				text-transform:uppercase;
				color:$c_primary;
			}
		}
	}
	.content-section{
		position:relative;
		&__desc{
			margin-bottom:Vmin(100);
		}
		&__title{ line-height:0.75; }
	}

	.initiatives{
		top:-#{Vmin(200)};
		position:relative;
	}

	.section-map{
		padding-bottom:Vmin(300);
		position:relative;
		min-height:120vh;
		overflow:hidden;

		@media all and (max-width:990px) {
			padding-bottom:Vmin(450);
			min-height: 0px;
		}
		@media all and (max-width:560px){
			padding-bottom:Vmin(200);
		}

		&__content{
			pointer-events:none;
		}

		&__graphic{
			position:relative;display:block;
			margin:0 auto Vmin(75) auto;
			width:74px;height:auto;
		}

		.map{
			position:absolute;display:block;
			transform:translateX(-50%);
			width:75%;max-width:1000px;
			bottom:22%;left:50%;

			@media all and (max-width:990px) {
				width:85%;
			}

			@media all and (max-width:769px){
				width:95%;
			}

			@media all and (max-width:560px){
				display:none;
			}

			&__path{fill:#033b77; }
			&__marker{
				transition:top 320ms ease;
				position:relative;
				cursor:pointer;
				fill:#79cdcd;
				top:0;

				&:hover,&:focus{
					top:-5px !important;
				}

				&--animate{
					path{
						animation-duration: 1.6s;
						animation-iteration-count:2;
						transform-origin:bottom;
						animation-name: bouncing;
						animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
						transform-box:fill-box;
						//transform-style: flat;
					}
				}
			}
			&__container{ position:relative; }
		}
	}
}

@keyframes bouncing {
	0%   { transform: scale(1,1)      translateY(0); }
	10%  { transform: scale(1.1,.9)   translateY(0); }
	30%  { transform: scale(.9,1.1)   translateY(-25px); }
	50%  { transform: scale(1.05,.95) translateY(0); }
	57%  { transform: scale(1,1)      translateY(-4px); }
	64%  { transform: scale(1,1)      translateY(0); }
	100% { transform: scale(1,1)      translateY(0); }
}

.initiative{
	&__location{
		position:relative;display:block;
		margin:0 0 Vmin(12);

		@include rfs(20);line-height:1.2;
		color:$c_landing_primary;
		font-weight:900;

		@media all and (max-width:640px){
			font-size:rem(18);
		}
		@media all and (max-width:480px){
			font-size:rem(15);
		}
		@media all and (max-width:320px){
			font-size:rem(13);
		}
	}

	&__logo{
		margin-top:Vmin(10);margin-bottom:Vmin(10);
		position:relative;display:block;
		width:auto;height:auto;
		max-width:220px;

		@media all and (max-width:769px){
			max-width:140px !important;
		}
	}

	.wysiwyg{
		p,li{
			font-size:rem(18);line-height:1.7;
			color:$c_dark_grey;

			@media all and (max-width:640px){
				font-size:rem(16);
			}
			@media all and (max-width:480px){
				font-size:rem(13);
			}
			@media all and (max-width:320px){
				font-size:rem(10);
			}
		}
	}

	.card{
		&__header{
			flex-wrap:wrap;

			&__left{
				flex-basis:80%;max-width:80%;
				>*{ max-width:100% }

				@media all and (max-width:1024px){
					flex-basis:68%;max-width:68%;
				}
				@media all and (max-width:769px){
					flex-basis:100%;max-width:100%;
				}
				@media all and (max-width:640px){
					flex-basis:60%;max-width:60%;
				}
				@media all and (max-width:420px){
					flex-basis:100%;max-width:100%;
				}
			}
			&__right{
				flex-basis:20%;max-width:20%;
				>*{ max-width:100% }

	             align-items:center;justify-content:flex-end;
	             flex-flow:row wrap;
	             display:flex;

				@media all and (max-width:1024px){
					flex-basis:32%;max-width:32%;
				}
				@media all and (max-width:769px){
					flex-basis:100%;max-width:100%;
				}
				@media all and (max-width:640px){
					flex-basis:40%;max-width:40%;
				}
				@media all and (max-width:480px){
					flex-basis:100%;max-width:100%;
					justify-content:flex-start;
				}
			}

			.headline--block{
				@media all and (max-width:640px){
					font-size:rem(21);
				}
				@media all and (max-width:480px){
					font-size:rem(17);
				}
				@media all and (max-width:320px){
					font-size:rem(15);
				}
			}

		}

	}
}