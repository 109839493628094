.newsletterbar{background-color:#E9EFF6;
    z-index: 105;
    position: fixed;
    bottom: 0;
    width: 100%;
    flex-direction: column;
    min-width: 320px;
    height: auto;max-height: 0;overflow:hidden;  transition:max-height 1.5s ease-in;
    &.active{
        max-height: 300px;
    }
    &__bar{}
    &__content{display:flex;color:$c_blue;align-items: center;
        @media all and (max-width: 1100px){width:100%;padding: 5px 0;}
        @media all and (max-width: 576px){justify-content: center;}
        &__icon{border:1px solid $c_blue;border-radius: 50px;height:50px;min-width:50px;
            display: flex;align-items: center;justify-content: center;font-size:24px;background-color:white;margin-right:15px;
        }
        &__title{white-space: nowrap;font-size:rem(16);font-weight: 900; text-transform: uppercase;text-align:center;
            @media all and (max-width: 576px){font-size:rem(14);}
        }
        &__title-fancy{font-family: $f_script;font-size:rem(52);margin: 20px 20px 0 10px;
            @media all and (max-width: 380px){font-size:rem(33); margin: 15px 20px 0 10px;}
        }
        &__text{font-size:rem(12);max-width:280px;padding-right: 15px;
            @media all and (max-width: 1100px){max-width:100%;}
            @media all and (max-width: 576px){display: none;}
        }
    }
    &__trigger{display:none;margin-left:auto;font-size:rem(30);
        @media all and (max-width: 1100px){display: block;}
        @media all and (max-width: 576px){position: absolute;right:15px;}
        .open{display:block;}
        .close{display:none;}
        &.opened{
            .open{display:none;}
            .close{display:block;}
        }
    }
    &__text-mobile{ display: none;font-size:rem(12);padding:0 0 15px;color:$c_blue;}

    &__form{display: flex;align-items: center;position: relative;margin-left:auto;margin-right:auto;
        button{font-size:rem(14);margin-left:15px;padding-top: 5px;height: 50px;padding-bottom: 5px;}
        .input-text{width:265px;max-width:265px;height:50px;
            &::placeholder {color:$c_blue;}
        }
        @media all and (max-width: 1100px){display: none;}
        .mage-error[for="email"]{position: absolute; top: 0;}
    }
    &__action{display: flex; align-items: center;margin-left:auto;text-align: center;padding-left:15px;
        a{}
        @media all and (max-width: 1100px){display: none;}
    }

    &.opened-bar{
        @media all and (max-width: 1100px){
            .newsletterbar__action{display:block;width:100%;margin:0 auto;padding:20px 15px;}
            .newsletterbar__form{display:block;width:100%;margin:0 auto;}
            .container{flex-direction:column;}
            .input-text{width:100%;max-width:100%;}
            button{width:100%;max-width:100%;margin-left:0;margin-top:15px;}
            .mage-error[for="email"]{position: inherit; top: inherit;}
        }
        @media all and (max-width: 576px){
            .newsletterbar__text-mobile{display: block;text-align: center;}
        }
    }
}
