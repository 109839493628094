.structured-blocks{
	position:relative;
}
.structured-block{
	position:relative;

	.wysiwyg p,
	.wysiwyg li{
		font-size:rem(18);line-height:rem(24);
	}

	&__column{
		&--content{
			text-align:right;
			padding:0 7%;
		}

		&--image{
			padding:0;

			img{
				margin:0 auto;
				display:block;
			}
		}
	}

	&__actions{ margin-top:40px; }

	.structured-blocks__columns{ flex-direction:row; }

	&--pos{
		&--right{
			.structured-blocks{
				&__columns{ flex-direction:row-reverse; }
			}
			.structured-block{
				&__column--content{ text-align:left; }
			}
		}
	}

	&[class*="--bg"]{
		+ .structured-blocks__actions{ margin-top:45px; }
	}
}