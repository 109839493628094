.data{
	max-width:100%;width:100%;
	position:relative;
	overflow-x:auto;
	margin:0 0 40px;

	&:last-child{ margin-bottom:0; }

	&__table{
		border:2px solid $c_primary;
		width:100%;height:auto;
		position:relative;

		font-size:rem(16);line-height:rem(20);
		font-weight:normal;
		color:$c_primary;

		p,span,strong{
			font-size:inherit;line-height:inherit;
			color:inherit;
		}

		strong{ font-weight:900; }

		&__cell{
			border:2px solid $c_primary;
			padding:5px 15px;margin:0;

			&--label{ min-width:33.33%; }
		}


	}
}