/************************************************/
/********************* LOGIN ********************/
/************************************************/
.login-container{ padding-top:45px; }
.login{
	margin:15px auto;padding:40px 3.5%;
	position:relative;display:block;
	width:100%;max-width:430px;
	border:3px solid $c_grey;
	text-align:center;

	&__actions{
		padding:20px 0 0 0;

		.secondary{
			margin-top:15px;
			width:100%;
		}
		.primary{ order:0; }
		.secondary{ order:1; }
		.action{ margin:7px 7px; }
	}
    .field-recaptcha{display: none;}
}

.login-container .fieldset:after{ display:none; }
