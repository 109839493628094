.partners{
	&__slider{
		.slider__arrow{
			background-color:transparent;
			transform:translateY(-50%);
			color:$c_dark_grey;
			position:absolute;
			border:none;
			z-index:5;
			top:50%;

			+ .slider__arrow{ margin-left:0; }

			&--left{ left:0; }
			&--right{ right:0; }

			&:hover,&:focus{ color:$c_primary; }

			&:hover,&:focus{
				&:not(.slick-hidden),&:not(.slick-disabled){
					background-color:transparent;
					border-color:transparent;
					color:$c_dark_grey;
				}
			}
		}
	}


	&__list{
		padding:0 45px;
	}

	.headline{ padding:0 45px;margin:0 0 45px 0; }
}