.cards{
	padding:0;margin:0 0 Vmin(100);
	list-style-type:none;

	&:last-child{ margin-bottom:0; }
}

.card{
	position:relative;display:flex;
	background-color:#ffffff;
	width:100%;height:100%;
	flex-direction:column;

	&--row{
		margin-bottom:Vmin(65);
		flex-flow:row wrap;

		&--reverse{ flex-direction:row-reverse; }

		&:last-child{ margin-bottom:0; }

		.card{
			&__photo{
				width:auto;max-width:28%;
				padding-bottom:29.243%;
				overflow:hidden;
				flex:0 0 28%;

				@media all and (max-width:1024px){
					flex-basis:33.33%;max-width:33.33%;
				}
				@media all and (max-width:769px){
					flex-basis:40%;max-width:40%;
				}
				@media all and (max-width:640px){
					flex-basis:100%;max-width:100%;
					padding-bottom:40%;
				}
				@media all and (max-width:420px){
					flex-basis:100%;max-width:100%;
					padding-bottom:60%;
				}

				img{
					max-width:100vw;max-height:100vh;
					position:absolute;display:block;
					transform:translate(-50%,-50%);
					min-width:100%;min-height:100%;
					width:auto;height:auto;
					top:50%;left:50%;
				}
			}
			&__content{
				justify-content:center;align-items:flex-start;
				flex-flow:column wrap;
				display:flex;

				padding-left:7%;padding-right:7%;
				width:auto;max-width:100%;
				flex:1 0 72%;

				@media all and (max-width:1024px){
					flex-basis:66.66%;max-width:66.66%;
				}
				@media all and (max-width:769px){
					flex-basis:60%;max-width:60%;
				}
				@media all and (max-width:640px){
					flex-basis:100%;max-width:100%;
				}


				> *{ width:100%;max-width:100% }
			}
		}
	}

	> *{ max-width:100%; }

	&__photo,&__content{
		position:relative;display:block;
		border:2px solid $c_secondary;
	}
	&__photo,&__content,&__actions{
		width:100%;max-width:100%;
	}

	&__photo{
		background-color:#ffffff;
		flex:0 0 auto;

		+ .card__content{ margin-top:-2px; }

		a, img{
			width:100%;height:auto;
			display:block;
		}

		img{
			@include transition(opacity);
			object-fit:cover;
			opacity:1;
		}

		a:hover,a:focus{
			img{ opacity:0.85; }
		}
	}

	&__content{
		width:100%;//min-height:200px;
		padding:30px 9% 15px;margin:0;
		flex-grow:1;
        .product__inner__additionals{text-align: right;}
	}

	&__header{
		margin-bottom:Vmin(35);
	}

	&__actions{
		align-items:stretch;justify-content:space-between;
		position:relative;display:flex;
		flex-wrap:wrap;

		border:2px solid $c_secondary;
		margin-top:-2px;

		.actions__primary,.actions__secondary{ flex:1 0 50%; }
        &__secondary{text-transform: uppercase;color:$c_blue;    display: flex;
            justify-content: center;padding: 25px 20px 10px;
            .mylist-remove{cursor: pointer;
            &:hover{color:$c_accent;}}
        }
	}

	&__action{ flex-grow:1; }

	&--bg{
		h1,h2,h3,h4,h5,h6,p,ul,ol,a,
		.h1,.h2,.h3,.h4,.h5,.h6,.p,
		.headline,
		.label,label,label.label{
			color:inherit;
		}

		color:#ffffff;

		&--primary,&--blue{ background-color:$c_primary; }
		&--dark-blue{ background-color:$c_dark_blue; }
		&--secondary{ background-color:$c_secondary; }
		&--accent{ background-color:$c_accent; }
		&--dark-grey{ background-color:$c_dark_grey; }
		&--black{ background-color:$c_black; }
		&--grey{ background-color:$c_grey; }
		&--light-blue{ background-color:$c_light_blue; }
		&--light-grey{ background-color:$c_light_grey; }
	}

	&__photo,&__content,&__actions{

		&--align--left{ text-align:left; }
		&--align--right{ text-align:right; }
		&--align--center{ text-align:center; }

		&--bg{
			color:#ffffff;

			&--light-blue{ background-color:$c_light_blue; }
			&--medium-blue{ background-color:$c_medium_blue; }
			&--blue{ background-color:$c_primary; }
			&--dark-blue{ background-color:$c_dark_blue; }
			&--secondary{ background-color:$c_secondary; }
			&--accent{ background-color:$c_accent; }
			&--light_grey{ background-color:$c_light_grey; }
			&--grey{ background-color:$c_grey; }
			&--medium_grey{ background-color:$c_medium_grey; }
			&--dark_grey{ background-color:$c_dark_grey; }
			&--black{ background-color:$c_black; }
		}


		&--noborder{ border:none; }

		&--border{
			&--white{ border:2px solid #ffffff; }
			&--light-blue{ border:2px solid $c_light_blue; }
			&--medium-blue{ border:2px solid $c_medium_blue; }
			&--blue{ border:2px solid $c_blue; }
			&--dark-blue{ border:2px solid $c_dark_blue; }
			&--secondary{ border:2px solid $c_secondary; }
			&--accent{ border:2px solid $c_accent; }
			&--light_grey{ border:2px solid $c_light_grey; }
			&--grey{ border:2px solid $c_grey; }
			&--medium_grey{ border:2px solid $c_medium_grey; }
			&--dark_grey{ border:2px solid $c_dark_grey; }
			&--black{ border:2px solid $c_black; }

			&--top{
				&--white{ border-top:2px solid #ffffff; }
				&--light-blue{ border-top:2px solid $c_light_blue; }
				&--medium-blue{ border-top:2px solid $c_medium_blue; }
				&--blue{ border-top:2px solid $c_blue; }
				&--dark-blue{ border-top:2px solid $c_dark_blue; }
				&--secondary{ border-top:2px solid $c_secondary; }
				&--accent{ border-top:2px solid $c_accent; }
				&--light_grey{ border-top:2px solid $c_light_grey; }
				&--grey{ border-top:2px solid $c_grey; }
				&--medium_grey{ border-top:2px solid $c_medium_grey; }
				&--dark_grey{ border-top:2px solid $c_dark_grey; }
				&--black{ border-top:2px solid $c_black; }
			}
			&--bottom{
				&--white{ border-bottom:2px solid #ffffff; }
				&--light-blue{ border-bottom:2px solid $c_light_blue; }
				&--medium-blue{ border-bottom:2px solid $c_medium_blue; }
				&--blue{ border-bottom:2px solid $c_blue; }
				&--dark-blue{ border-bottom:2px solid $c_dark_blue; }
				&--secondary{ border-bottom:2px solid $c_secondary; }
				&--accent{ border-bottom:2px solid $c_accent; }
				&--light_grey{ border-bottom:2px solid $c_light_grey; }
				&--grey{ border-bottom:2px solid $c_grey; }
				&--medium_grey{ border-bottom:2px solid $c_medium_grey; }
				&--dark_grey{ border-bottom:2px solid $c_dark_grey; }
				&--black{ border-bottom:2px solid $c_black; }
			}
			&--left{
				&--white{ border-left:2px solid #ffffff; }
				&--light-blue{ border-left:2px solid $c_light_blue; }
				&--medium-blue{ border-left:2px solid $c_medium_blue; }
				&--blue{ border-left:2px solid $c_blue; }
				&--dark-blue{ border-left:2px solid $c_dark_blue; }
				&--secondary{ border-left:2px solid $c_secondary; }
				&--accent{ border-left:2px solid $c_accent; }
				&--light_grey{ border-left:2px solid $c_light_grey; }
				&--grey{ border-left:2px solid $c_grey; }
				&--medium_grey{ border-left:2px solid $c_medium_grey; }
				&--dark_grey{ border-left:2px solid $c_dark_grey; }
				&--black{ border-left:2px solid $c_black; }
			}
			&--right{
				&--white{ border-right:2px solid #ffffff; }
				&--light-blue{ border-right:2px solid $c_light_blue; }
				&--medium-blue{ border-right:2px solid $c_medium_blue; }
				&--blue{ border-right:2px solid $c_blue; }
				&--dark-blue{ border-right:2px solid $c_dark_blue; }
				&--secondary{ border-right:2px solid $c_secondary; }
				&--accent{ border-right:2px solid $c_accent; }
				&--light_grey{ border-right:2px solid $c_light_grey; }
				&--grey{ border-right:2px solid $c_grey; }
				&--medium_grey{ border-right:2px solid $c_medium_grey; }
				&--dark_grey{ border-right:2px solid $c_dark_grey; }
				&--black{ border-right:2px solid $c_black; }
			}
		}
	}

	&--shadow{
		box-shadow: 0px 0px 40px 2px rgba(0,0,0,0.2);
	}
}

/*FOR REPICES*/
.recipes__list{
	.card__photo a, .card__photo a img{margin: 0 auto;}
	.product--summary .product__name{ font-size: 1.1rem; line-height: 1.2rem;}
	.card__content{min-height: 140px;}
}

html.ie11 body, html.ie10 body{
	.category-cms{width:100%;}
	//.card{display:block;}
}
