@media (max-width: 480px) {
	/********************************************************/
	/************************* MISC *************************/
	/********************************************************/
	.modal-footer{
		a.action-primary,button.action-primary,input.action-primary,
		a.action-secondary,button.action-secondary,input.action-secondary{
			margin:0 0 7px 0;
			display:block;
			width:100%;
		}
	}

	.section{
		&--padding{
			&--60,
			&--80,
			&--100,
			&--120,
			&--140,
			&--160{
				padding-top:40px;padding-bottom:40px;
			}
			&--top--60,
			&--top--80,
			&--top--100,
			&--top--120,
			&--top--140,
			&--top--160{
				padding-top:40px;
			}
			&--bottom--60,
			&--bottom--80,
			&--bottom--100,
			&--bottom--120,
			&--bottom--140,
			&--bottom--160{
				padding-bottom:40px;
			}
		}
	}

	/********************************************************/
	/************************ HEADER ************************/
	/********************************************************/

	/********************************************************/
	/*********************** HOMEPAGE ***********************/
	/********************************************************/
	.partners .headline{padding:0;}

	/********************************************************/
	/*********************** CATEGORY ***********************/
	/********************************************************/
	.product--view .product__informations__table__cell--third{width:50%;}

	/********************************************************/
	/*********************** PRODUCTS ***********************/
	/********************************************************/


	.recipe--view {
		.article__ingredients .units-mesure{width:100%;}
	}

	.catalog-product-view{
		.nutrition-facts{margin:0;max-width:280px;width:100%;}
		.product--view .product__informations__table__row--upper{
			.attribute.sku, .product__informations__table__cell{ padding: 22px 0;}
		}
	}

	/********************************************************/
	/*********************** CUSTOMER ***********************/
	/********************************************************/
	.contact-index-index{
		.business__locations{flex-direction: column;
			.location{width:100%;}
		}
	}

	/********************************************************/
	/******************* FLEXIBLE CONTENT *******************/
	/********************************************************/
	.flexible-content{
		&--fullsize{
			.flexible-content__inner{ padding-left:15px;padding-right:15px; }
		}
	}

	/********************************************************/
	/********************** EXPERTISES **********************/
	/********************************************************/


	/********************************************************/
	/************************ FOOTER ************************/
	/********************************************************/



}