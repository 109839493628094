body.newsletter-index-index{

    #maincontent{padding: 40px 15px 4.5%;}
    .whynewsletter-container{margin-top:60px;color:$c_blue;margin-bottom:120px;
        ul{list-style: none;display:flex;margin:60px 0 0 0;padding:0;
            @media all and (max-width: 767px){flex-direction: column;}
        }
        @media all and (max-width: 767px){margin-bottom:60px;}
    }
    .whynewsletter{margin:0;text-align: center; padding:0 20px;
        @media all and (max-width: 767px){display: flex;margin-bottom:20px;padding:0;}
        &__icon{display: flex; justify-content: center;margin-bottom: 40px;
            border: 1px solid $c_primary;
            border-radius: 50%;
            height: 152px;
            width: 152px;
            align-items: center;
            font-size: 48px;
            @media all and (max-width: 767px){margin-bottom: 0;padding:10px 0;}
        }
        &__text{font-size:rem(18);}
        &__title{font-size:rem(20);font-weight:900;margin-bottom:20px;}
        &__content{
            @media all and (max-width: 767px){padding:15px 15px 15px 30px;text-align: center;}
            @media all and (max-width: 576px){padding:15px;text-align: center;}
        }
    }
    .whynewsletter-blocks{color:$c_blue;
        .container{max-width: 1180px;}
        ul{list-style: none;display:flex;flex-direction: column;margin:60px 0 0 0;padding:0;
            li:nth-child(1){background:$c_blue;}
            li:nth-child(2){background:$c_accent;}
            li:nth-child(3){background:$c_darker_blue;}
        }
    }
    .blocknewsletter{color:white;display: flex;margin:0 0 50px 0;padding:0;flex-wrap:wrap;
        @media all and (max-width: 576px){flex-direction: column;margin:0 0 30px 0;}
        &__img{display: flex;justify-content: flex-start;flex:1;
            @media all and (max-width: 576px){justify-content: center;padding:0;}
            img{object-fit: cover;}
        }
        &__text{display:flex;flex-direction: column;flex:1; padding: 6%;text-align: center;
            p{font-size:rem(18);}
        }
        &__title{font-size:rem(24);width:100%;}
    }
    .whynewsletter-footer{color:$c_blue;padding-bottom:60px;
        .headline{font-size:rem(85);margin-bottom: -20px;
            @media all and (max-width: 576px){font-size:rem(70);}
        }
        &__form{display: flex;max-width: 650px; margin:40px auto 0;
            @media all and (max-width: 576px){flex-direction: column;}
            button{width:100%;font-size:rem(14);margin-left:20px;    max-width: 285px;
                .btn__icon {font-size: 24px;}
                @media all and (max-width: 576px){margin-top:20px;margin-left:0; max-width: 100%;}
            }
            .mage-error[for="email"]{margin-bottom:10px;}
            .input-text{background:$c_bgblue;border:none;color:$c_blue;font-size:rem(14);font-weight: bold;height:50px;
                &::placeholder {color:$c_blue;}
                &:focus::placeholder {color: transparent; }
            }
        }
    }


}
body.newsletter-profile-index, body.mylist-submission-index{
     .messages{
         max-width: 757px;
         margin: 30px auto 0;
     }
    #maincontent{padding: 40px 15px 60px;}

    /*HIDE ELEMENTS*/
    .mobile-search,.main-menu-trigger,.mobile-searchbar{display:none !important;}
    @media all and (max-width:767px){
        .header__content{background-color:$c_primary;flex-direction: row;}
        .logo{background-color:$c_primary;}
        .newsletter-header,.submission-header{padding-right: 15px;}
        .newsletter-header h1,.submission-header h1{font-size: 22px;}
    }
}

.newsletter-header{
    width:100%;
    display: flex;justify-content: center;align-items: center;
    h1{margin:0;font-size:rem(32);}
}
/* FORM STYLING FOR NEWSLETTER FORM PAGE AND SUBMISSION FORM PAGE */
.form-page-wrapper{
    h2{font-size:rem(32);
        &.headline--margin--0{margin-bottom:0;}
    }
    .newsletterform,.submissionform {
        .newsletterform-field,.submissionform-field{margin-bottom:30px;
            display: flex;flex-direction: column;color:$c_primary;
            label{font-size:rem(16);font-weight:bold;margin-bottom:5px;
                span{font-weight: normal;}
            }
            &--text{
                input{background-color:$c_bgblue;border:none;height:50px;padding: 15px;}
            }
            &--radio{

                input{ appearance: none;position: relative;margin:0;cursor:pointer;
                    box-shadow:none !important;
                    &::before{content:"";border:1px solid $c_blue;border-radius: 50px;width:16px;height: 16px;
                        display: block;position: relative;top: 3px;left: 0;color:$c_blue;}
                    &:checked{
                        &::after{content:"";background-color:$c_blue;width: 7px;height: 7px;position: absolute;left: 5px;top: 7px;border-radius: 50px;}
                    }
                }
                &--inline{
                    .radio-group{ display:flex;
                        @media all and (max-width: 767px){display: block;}
                        .radio-group__field{margin-right:30px;}
                    }
                }
            }
            &--select{
                select{height:50px;border:1px solid $c_primary;
                    background: white url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkAQMAAABKLAcXAAAABlBMVEUAAAAiYKLDi4IiAAAAAXRSTlMAQObYZgAAAMpJREFUOMvszbENwlAMBNAgCkpGYJSMBpuwCqMwAmWKKEf07yFRMQEp8s+W7Tf9+g7LdzquzU2nrbnpnOamS24jN815tBjpmmeLkZJXi5GSBddqxbXacK2CU91we3UNcM4uzCnY9gXYlfMO4jIOr7jt89c1MdkAugas5NE0YsG6Y06DvHpmzNt3y20OEAfEAXFAHBAHxAHv5YApB0w5IA6IA+KAOCAOiAPigDggDogD4oA4IA6IA+KAOCAOiAPigDjgMv2/90YBVgAAE34dZNnFKxEAAAAASUVORK5CYII=') no-repeat right;
                    appearance: none;
                    background-position-x: 95%;
                    background-size: 16px;
                    background-position-y: 20px;
                }
            }
            &--textarea{
                textarea{border:1px solid $c_blue;padding: 15px;}
            }

            input.field_error{  border:2px solid rgba(255,0,0,0.8);background: rgba(255,0,0,0.4);
                &+label{color:red;}
            }
        }
        .radio-group{ margin-top: 15px;
            &__field{margin-bottom:10px;
                    label{margin-left: 5px;cursor:pointer;  }
            }
        }
        &__action{
            @media all and (max-width: 767px){
                button.btn{width:100%;}
            }
        }
    }
}

.form-sucess{margin-top:60px;margin-bottom:60px;background:$c_bgblue;
    @media all and (max-width: 768px){margin-top:40px;margin-bottom:30px;}
    &__content{padding:60px;display: flex;justify-content: center;
        @media all and (max-width: 576px){padding:30px;}
        &__icon{img{background-color: white;border-radius: 100%;min-width: 50px;} display:flex;align-items: flex-start;padding-top:30px;}
        &__text{padding:30px;display: flex;flex-direction: column;justify-content: center;
            .headline{margin-bottom:15px;}
        }
    }
}
