.articles{
	position:relative;

	&__list{
		transition:opacity 240ms ease-out;
		margin:-#{rem(10)};padding:0;
		height:1px;width:unset;
		opacity:0;

		&.slick-initialized{
			height:auto;
			opacity:1;
		}

		&.slick-slider{
			.article{
				padding:rem(10);
			}
		}
	}

	.slider{
		&__controls{ margin-bottom:rem(25); }
		&__arrow{
			background-color:$c_primary;
			border-color:$c_primary;
			color:#ffffff;

			&:hover,&:focus{
				background-color:$c_primary;
				border-color:$c_primary;
			}

			&:hover:focus:not(.slick-hidden):not(.slick-disabled){
				background-color:$c_accent;
				border-color:$c_accent;
			}
		}
	}

	&__actions{
		margin-top:Vmin(80);
	}
}

.article{
	margin-left:0;margin-right:0;
	position:relative;

	&--summary{
		align-items:flex-start;justify-content:flex-start;
		flex-direction:column;
		display:flex;

		position:relative;
		width:100%;
	}

	&__details{
		padding:20px 0;
		max-width:100%;
		.p{ line-height:rem(20); }
	}

	&__photo, &__photo__image, &__photo__link{
		position:relative;display:block; object-fit: cover;
		width:100%;height:auto; overflow:hidden;
	}
}

.article__card{
	text-align:left;

	.card{
		&__title{
			margin-bottom:rem(6);
			display:block;

			font-size:rem(20);line-height:1.25;
			text-transform:none;
			color:$c_primary;
			font-weight:900;
		}
	}

	&__header{
		margin-bottom:rem(22);
	}

	&__details{
		position:relative;display:block;
		padding:0;margin:0;

		font-size:rem(16);line-height:1;
		text-transform:none;
		color:$c_primary;
		font-weight:400;
	}

	&__summary{
		position:relative;display:block;
		padding:0;margin:0 0 rem(5);

		font-size:rem(14);line-height:1.45;
		font-weight:normal;
		color:$c_dark_grey;
	}

	.action--readmore{
		font-weight:bold;
		color:$c_primary;

		&:hover,&:hover:focus{ color:$c_dark_blue; }
	}
}