.testimonies{
	background-color:$c_secondary;
	padding:4% 6.5%;margin:0;
	position:relative;
	color:#ffffff;

	h1,h2,h3,h4,h5,h6,p,ul,ol,
	.h1,.h2,.h3,.h4,.h5,.h6,.p,
	.headline,
	.label,label,label.label,
	blockquote{
		color:inherit;
	}

	&__headline{
		text-transform:uppercase;
		font-weight:bold;
		margin:0 0 35px;
	}

	&__list{
		padding:0;margin:0 0 40px;
		list-style-type:none;
		position:relative;
	}

	&__actions{
		position:relative;
		width:100%;
	}

	&__button{ margin-left:auto; }

	&__slider{
		position:static;

		.slider__controls{
			width:100%;max-width:460px;
			@media screen and(min-width : 768px) and (max-width : 1080px) {top:50px;}
		}

		.slider__arrow{
			background-color:#ffffff;
			border-color:#ffffff;
			color:$c_primary;

			&:hover,&:focus{
				background-color:#ffffff;
				border-color:#ffffff;
				color:$c_primary;
			}

			&:hover,&:focus{
				&:not(.slick-hidden),&:not(.slick-disabled){
					background-color:$c_dark_blue;
					border-color:$c_dark_blue;
					color:#ffffff;
				}
			}
		}
	}
}

html[lang="fr"]{
	.testimony__quote{
		&::before{ content:"«"; }
		&::after{ content:"»"; }
	}
}

.testimony{
	margin:0;padding:0;
	position:relative;
	border-left:none;

	&__quote{
		position:relative;
		margin:0 0 30px;

		font-size:rem(36);line-height:rem(42);
		text-transform:none;
		font-weight:300;
		color:inherit;

		&::before,&::after{
			font-size:rem(52);line-height:rem(42);
			position:relative;display:inline;
		}

		&::before{ content:"“"; }
		&::after{ content:"”"; }

		p{
			display:inline;

			font-size:inherit;line-height:inherit;
			text-transform:inherit;
			font-weight:inherit;
			color:inherit;
		}
	}
	&__footer{ color:inherit; }

	&__quotee{
		font-size:rem(24);line-height:rem(26);
		text-transform:none;
		font-weight:normal;
		color:inherit;
	}
}