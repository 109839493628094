/* Configs */
$baseFontSize : 16;
$opacityHover : 0.85;
$mobileMenuHeight : 85px;

/* FONTS */
$f_main : 'Lato',sans-serif;
$f_script : 'Viva',serif;


/* COLORS */
$c_light_blue : #e8eff6;
$c_medium_blue : #006eb7;
$c_blue : #2260a2;
$c_dark_blue : #002f6c;
$c_darker_blue : #173068;
$c_turquoise : #00a7b4;
$c_bgblue : #E4F1FA;

$c_light_grey : #edebeb;
$c_grey : #c3c1c1;
$c_medium_grey : #8f8f8f;
$c_dark_grey : #444444;
$c_black : #191919;

$c_success : #39ad44;
$c_warning : #c99c3d;
$c_error : #b62c2c;

$c_primary : $c_blue;
$c_secondary : $c_dark_blue;
$c_accent : $c_turquoise;

/* Size & layout vars */
$page_max_width : 2530px;
$content_max_width : 1380px;
