.tabs{
	align-items:stretch;justify-content:flex-start;
	flex-wrap:wrap;
	display:flex;

	position:relative;
	margin:0 0 20px;
	width:100%;

	&__input{
		margin:0;padding:0;

		&--hidden{ display:none; }
	}

	&__label{
		padding:15px 25px;margin:0 0 0 -2px;
		border:2px solid $c_secondary;
		background-color:#ffffff;
		position:relative;

		@include transition();


		/*display:block;align-items:flex-end;*/
		display:flex; align-items: center;
		cursor:pointer;
		min-height: 78px;
		flex:1;


		font-size:rem(18);line-height:rem(20);
		text-transform:uppercase;
		color:$c_secondary;
		text-align:left;
		font-weight:900;

		&::after{
			///margin:0 0 0 10px;
			display:block;
			/*float:right;*/
			position: absolute;
			top: 33%;
			right: 7%;
			content:'+';
		}
	}
	&__content{
		position:relative;display:none;
		width:100%;max-height:0;
		padding:70px 0 0 0;
		top:0px;left:0px;
		overflow:hidden;
		/*flex: 1 1 100%;*/
		opacity:0;

		@include transition();
	}
	#select-findby{display:none;}
}

$tabs: 10;

@for $i from 1 through $tabs {
	#tab#{$i}:checked ~ #tab-content#{$i}{
		max-height:2000px;
		display:block;
		opacity:1;
	}

	#tab#{$i}:checked + label{
		background-color:$c_secondary;
		color:#ffffff;
		flex:2;

		&::after{ content:'-'; }
	}

	.section--bg{
		#tab#{$i}:checked + label{
			border-bottom-color:#ffffff;
			background-color:#ffffff;
			color:$c_secondary;
			flex:2;

			&::after{ content:'-'; }
		}
	}
}

.section--bg{
	.tabs{
		&__content{
			background-color:#ffffff;
			color:$c_dark_grey;
			padding:5% 5%;
		}
		&__label{
			background-color:$c_secondary;
			border-color:#ffffff;
			margin:0 0 0 -2px;

			font-size:rem(16);line-height:rem(18);
			text-transform:none;
			font-weight:900;
			color:#ffffff;

		}
	}
}