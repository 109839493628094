.banners{
	position:relative;
	height:100%;
	z-index:40;
}

.banner{
	position:relative;display:block;
	width:100%;height:auto;

	&__link,&__image{
		position:relative;display:block;
	}

	&--large{
		margin:15px -15px;padding:rem(15);
		background-color:$c_light_blue;
		width:auto;

		.banner__link{
			width:100%;max-width:600px;
			margin:0 auto;padding:0;
		}

		@media all and (min-width:1025px){
			background-color:transparent;
			margin:0 auto;padding:0;
			width:100%;height:auto;
			position:sticky;
			top:0;
		}

		&[data-aos^="fade"],&[data-aos^="fade"]{
			@media all and (max-width:1024px){
				transform:translate3d(0, 0, 0);
				transition-duration:0ms;
				opacity:1;
			}
		}
	}

	&--mobile{
		transition:bottom 240ms ease,opacity 600ms ease;

		border-top:2px solid $c_primary;
		width:100%;height:auto;
		bottom:0;left:0;
		position:fixed;
		z-index:40;
		opacity:1;

		@media all and (min-width:576px){
			top:initial;bottom:initial;
			left:initial;right:initial;
			position:relative;
		}

		&--boundaries{
			pointer-events:none;
			bottom:-240px;
			opacity:0;
		}
	}
}