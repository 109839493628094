.products{
	position:relative;display:block;
	margin:20px 0 0 0;padding:0;

	&__list{
		padding:0;margin:0 -15px;
		list-style-type:none;
		position:relative;
	}
}

.products-landing.page__titlebar{margin-top: 50px; margin-left: 25px;}
/*.category-our-products .category__intro, .category-nos-produits .category__intro{border:none;}
.category-our-products .page-main{max-width:100%;}*/



.mylist-add{
    font-size: 12px;
    border: 1px solid $c_blue;
    padding: 4px 12px; font-weight: bold;
    color:$c_blue;cursor: pointer;border-radius:2px;
    &__icon{font-size:14px;margin-right: 5px;   }
    &__text{}
    &__text,&__icon{
        &--added{display: none;}
    }

    &:hover{
        color:$c_accent;border-color:$c_accent;
    }
    &.added{
        color:$c_accent;border-color:$c_accent;
        .mylist-add__text,.mylist-add__icon{
            &--base{display:none;}
            &--added{display:inline;}
        }
    }
}
.product-mylist-btn{

    &__icon{
        position: absolute;
        display: block;
        transform: translateY(-50%);
        left: 15px;
        top: 50%;
    }
    &__text,&__icon{
        &--added{display: none;}
    }
    &:hover,&:active,&:focus{color:$c_accent !important;border-color:$c_accent;background-color:transparent !important;}
    &.added{ color:$c_accent;border-color:$c_accent;
        .product-mylist-btn__text,.product-mylist-btn__icon{
            &--base{display:none;}
            &--added{display:inline;}
        }
    }
}

body.page-products{
	.sorter__options{ -webkit-appearance: none; appearance: none;background: url("../../images/ui/select-arrow.png") no-repeat right white;	padding: 0 30px 0 15px;
		&::-ms-expand {	display: none; }
	}
	.limiter__options{ -webkit-appearance: none; appearance: none;background: url("../../images/ui/select-arrow.png") no-repeat right white;	padding: 0 30px 0 15px;
		&::-ms-expand {	display: none; }
	}

}
