.social-networks{
	padding:0;margin:35px 0 0 0;
	list-style-type:none;
	color:inherit;

	justify-content:flex-start;align-items:flex-start;
	flex-wrap:wrap;
	display:flex;
}


.social-network{
	width:auto;height:auto;
	margin-right:20px;
	display:block;

	color:$c_primary;

	&:last-child{ margin-right:0; }

	a{
		border:2px solid $c_primary;border-radius:50%;
		position:relative;display:block;
		background-color:transparent;
		width:48px;height:48px;
		color:inherit;

		font-size:rem(24);line-height:44px;
		text-align:center;

		&:hover,&:focus{
			background-color:$c_dark_blue;
			border-color:$c_dark_blue;
			color:#ffffff;
		}
	}
}

.header{
    .social-networks{
        justify-content:flex-end;align-items:center;
        align-self:center;
        margin:0 0 0 auto;
    }

    .social-network{
        color:white;
        margin:0;

        a{
            border-color:currentColor;
            background:transparent;
            width:42px;height:42px;

            font-size:rem(19);line-height:38px;
            color:inherit;

            &:hover,&:focus-within{ color:$c_darker_blue; }
        }
    }
}
