.perks{
	list-style-type:none;

	width:100%;height:auto;
	margin:0;padding:0 5%;
	position:relative;

	@media all and (max-width:1024px){
		padding-left:0;padding-right:0;
	}
}

.perk{
	position:relative;display:block;
	flex:0 0 32%;max-width:32%;
	padding:1.5% 1.5%;margin:0;
	width:auto;height:auto;

	@media all and (max-width:1024px) {
		.perk {
			flex: 0 0 33.33%;max-width:32%;

			&__icon{
				&__wrap{ border-width:2px; }
			}
		}
	}

	&__icon,&__name{
		position:relative;display:block;
		text-align:center;
	}

	&__icon{
		width:100%;max-width:135px;
		margin:0 auto Vmin(35) auto;

		&__wrap{
			position:relative;display:block;
			border:3px solid $c_primary;
			padding:0 0 100% 0;margin:0;
			background-color:#ffffff;
			width:100%;height:auto;
			border-radius:50%;

			> i{
				position:absolute;display:block;
				transform:translate(-50%,-50%);
				top:50%;left:50%;
				@include rfs(52);
			}
		}
	}

	&__name{
		@include rfs(17);line-height:1.4;
		text-transform:uppercase;
		font-weight:bold;

		@media all and (max-width:767px) {
			@include rfs(14);line-height:1.4;
		}
		@media all and (max-width:500px) {
			@include rfs(11.5);line-height:1.4;
		}
		@media all and (max-width:380px) {
			@include rfs(9);line-height:1.4;
		}
	}

}