.page.messages{
	position:relative;display:block;
	padding:0;margin:0 auto;
	max-width:1320px;

	a{
		text-decoration:underline;
		color:inherit;

		&:visited{ color:inherit; }

		&:hover,&:focus{
			text-decoration:underline;
			color:inherit;
		}
	}
}

.page-layout-1column-full,
.page-layout-1column_full{
	.page.messages{padding:0 5%;}
}

.message{
	position:relative;display:block;
	width:100%;height:auto;


	&.success,&.error,&.warning,&.notice,&.info{
		border-left:4px solid;//border:1px solid;border-left-width:4px;
		padding:8px 20px 10px 40px;margin:10px 0;
		background-color:#f1f1f1;
		position:relative;
		box-shadow:none;

		font-size:rem(18);line-height:rem(20);
		font-weight:500;

		> :first-child::before{
			position:absolute;display:block;
			width:auto;height:auto;
			top:17px;left:5px;

			font-size:rem(30);line-height:1;
		}
	}

	&.error{
		border-color:$c_error;
		color:$c_error;

		> :first-child::before{ color:$c_error; }
	}

	&.success{
		border-color:$c_success;
		color:$c_success;

		> :first-child::before{ color:$c_success; }
	}

	&.notice,&.warning,&.info{
		border-color:$c_warning;
		color:$c_warning;

		> :first-child::before{ color:$c_warning; }
	}
}