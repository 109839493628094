.tools-index-index{



}


.tools{
	&__header{ display: flex;
		&__icon{@include rfs(35);display: flex;margin-top:7px;}
		&__icon--rotated{transform: rotate(-15deg);margin-top: 11px;align-items: flex-start;}
		&__title{ margin-left: 20px;}
	}
	&__btn{ min-height: 60px;background: white; display: flex;border:1px solid $c_dark_grey;align-items: center; position:relative; padding: 5px 10px;margin-bottom: 20px;
		color:$c_primary;&:visited{color:$c_primary;}
			&:hover,&:focus{color:$c_dark_blue;}
		&__img{ width: 60px;height: 60px;;font-size:45px;display: flex;justify-content: center;align-items: center; position: absolute; left:20px;
			@media all and (max-width:480px){left:5px;}
		}
		&__title{@include rfs(18);margin-left: 80px; @media all and (max-width:480px){margin-left: 60px;}}
		&__arrow{position: absolute;right: 30px;@include rfs(40); .close{display:none;}}
		&--seemore{float: right;cursor:pointer;position: absolute;margin-left: 5px;
			&:hover,&:focus{color:$c_primary;}
		}
	}
	&__moreinfos{display: none;background: $c_light_blue;padding: 5px;}
	&__section-title{font-weight: 700;line-height: 1;font-size: 1.1rem;margin-bottom: 1rem;margin-top: 1rem;}
	&__selector{ display:flex;justify-content: center;
		@media all and (max-width:576px){flex-direction: column;}
		.btn-calculators,.btn-comparators{ display:flex; justify-items: center; margin-bottom:20px;
			.btn__icon{position:relative;display: inline-block;transform: none;margin-right: 10px;}
			@media all and (max-width:576px){justify-content: center;}
		}
		a{cursor:pointer;margin:0 20px;}
	}
}
.calculator-row{
	.pdf-name{ font-weight: bold;margin-bottom: 20px;text-align: center;
		span{margin-right:10px;}
	}
	input{
		padding:0 5px;
		width:70px;

		text-align:center;
		font-size:rem(14);
	}
	select { width:70px;padding:0;padding-left:15px; text-transform: capitalize; -webkit-appearance: none; background: url("../../images/ui/select-arrow.png") no-repeat right white; }
	select::-ms-expand {display: none; }
	.row{align-items: center;margin:0;min-height: 50px;margin-bottom: 5px;font-size:1rem;}
	.row.withdescription{align-items: baseline;margin:0;min-height: 50px;margin-bottom: 5px;font-size:1rem;padding-top: 3px;}

	.row.head{
		margin-bottom:10px;

		div[class*="col-"]{

		}
	}

	.row.subtotal{border-top: 2px solid black;	margin-top: 5px;padding-top: 10px;padding-bottom: 10px;}
	.row.total{border-top: 2px solid black;	margin-top: 5px;padding-top: 10px;padding-bottom: 10px; &:last-child{margin-bottom: 25px;}}
	td{padding: 5px 2px;vertical-align: middle;}
	.row.deli{ .col-sm-custom{width:100%; max-width:11%; min-width: 70px;} .col-sm-title{width:16%;min-width: 11%;}}
	.mobileonly{display:none;}

	div[class*="col-"]{
		padding-left:6px;padding-right:6px;
	}
}
.calculator.turkey{.turkey{font-weight: bold;}.turkey_roast{font-weight: bold;}}
.calculator.deli-cost{
	input#sku_2,input#sku_1{
		width:100%;max-width:170px;
	}
	.row.total{.col-sm-3{align-items: center; .mobileonly{margin-right: auto;}}}
}
@media all and (max-width:576px){
	.calculator-row{
		.tools__btn__title{padding-right: 12%;}
		.calculator .row span:last-child{width:70px; text-align: center;height: 42px;display: flex;align-items: center;justify-content: center;}
		.row {margin-bottom: 10px;}
		.mobileonly{display: flex;align-self: center; width:auto; margin-right:auto; }
		.desktoponly{display:none;}
		.tools__moreinfos{margin-bottom:5px;}
		.calculator{.row div:first-child{font-weight: bold;}}
		.calculator.turkey, .calculator.wings-raw-fc{.flex--justify-center{justify-content: space-evenly;margin-bottom: 10px;}}
		.calculator.bacon-precooked-vs-raw{.col-sm-4{margin-bottom:10px;}.col-sm-2{margin-bottom:10px;}}
		.calculator.bacon{.flex--justify-center{justify-content: flex-end;}}
		.calculator.ribs{ .a-center{text-align: left;} .col-sm-3{display:flex;justify-content: center;margin-bottom:10px;align-items: center; br{display:block;}}}
		.calculator.turkey-yields{.col-sm-2{display:flex;justify-content: center;margin-bottom:10px;align-items: center;}}
		.calculator.wings{ .row.withdescription{align-items: center;} .row.total span{margin:0 auto;} .section--padding--top--20{padding-bottom:20px;}}
		.inlinetable{.col-sm-8{flex-basis:50%;}	.col-sm-4{flex-basis:50%;display: flex;justify-content: center;}}
		.tableseparator{border-top:2px solid black; margin-top:20px;padding-top: 20px;}
		.savingtable{.col-sm-8{flex-basis:50%;}	.col-sm-4{flex-basis:50%;@media all and (max-width:450px){justify-content: flex-end;}}}
		.calculator.bacon{ .col-sm-8{flex-basis:50%;} .col-sm-4{flex-basis:50%;}}
		.colwithinput{.tools__btn--seemore{order:3;} .tools__moreinfos{order:4; flex-wrap: wrap; &::after  {content: ''; width: 100%;}}}
		.col-sm-3 br{display:none;}

	}
	.calculator.deli-cost{input#sku_2,input#sku_1 {width: 150px;} .row div:first-child{margin-bottom: 10px;}}
}
@media all and (max-width:690px){
	.calculator-row {
		.calculator.deli-labor{
			.row div:first-child{font-weight: bold;}
			.mobileonly{display: flex;align-self: center;margin-right: 20px;width:170px;}
			.desktoponly{display:none;}
			.row.deli { flex-direction:column;.col-sm-custom {width: 100%;max-width: 100%;min-width: 100%;}.col-sm-title{width: 100%;}}}
	}
	.ie11{.tools__btn__arrow{top:8px;}}
}
.ie11{.tools__btn__img{top:0;}.tools__btn__arrow{top:4px;}.tools__btn__title{margin-top:12px;}}