.container{
	position:relative;

	&--large{ max-width:$content_max_width; }
	&--full{ max-width:$page_max_width; padding:0 30px;
		@media screen and (max-width: 480px) {padding:0;}
	}
}

.page-wrapper{
	max-width:$page_max_width;
	margin:0 auto;position:relative;
}

.page-main{
	max-width:$content_max_width;
	padding:4.5% 30px;
	position: relative;
	z-index: 1;
}

.page-layout-fullwidth,
/*.contentmanager-contentlist-view,*/
.contentmanager-content-view{
	.page-main{
		padding-left:0;padding-right:0;
		max-width:100%;
	}
	.container:not(.container--full){ max-width:$content_max_width; }

	&.page-layout-2columns-right,
	&.page-layout-2columns-left{
		.page-main{
			padding-left:30px;padding-right:30px;
		}
	}
}

.page-layout-2columns-left,
.page-layout-2columns-right{
	.columns{
		.sidebar-main{ width:25%;
			/*@media all and (max-width:1200px){width:25%;}*/
		}
		.column.main{ width:75%; }
	}

}

.page-layout-empty{
	.page-main{
		padding:20px 20px;margin:0 auto;
		max-width:1280px;
	}
}

.columns .column.main{ padding-bottom:0; }

.sidebar-main{ padding-left:0; }

.section, .column, .article{
	position:relative;
	margin:0 auto;

	&--padding--bottom--0{ padding-bottom:0; }
	&--padding--top--0{ padding-top:0; }
	&--padding--0{ padding:0; }

	&--padding--bottom--20{ padding-bottom:20px; }
	&--padding--top--20{ padding-top:20px; }
	&--padding--20{ padding:20px 0; }

	&--padding--bottom--40{ padding-bottom:40px; }
	&--padding--top--40{ padding-top:40px; }
	&--padding--40{ padding:40px 0; }

	&--padding--bottom--60{ padding-bottom:60px; }
	&--padding--top--60{ padding-top:60px; }
	&--padding--60{ padding:60px 0; }

	&--padding--bottom--80{ padding-bottom:80px; }
	&--padding--top--80{ padding-top:80px; }
	&--padding--80{ padding:80px 0; }

	&--padding--bottom--100{ padding-bottom:100px; }
	&--padding--top--100{ padding-top:100px; }
	&--padding--100{ padding:100px 0; }

	&--padding--bottom--120{ padding-bottom:120px; }
	&--padding--top--120{ padding-top:120px; }
	&--padding--120{ padding:120px 0; }

	&--padding--bottom--140{ padding-bottom:140px; }
	&--padding--top--140{ padding-top:140px; }
	&--padding--140{ padding:140px 0; }


	&--bg{
		h1,h2,h3,h4,h5,h6,p,ul,ol,
		.h1,.h2,.h3,.h4,.h5,.h6,.p,
		.headline,
		.label,label,label.label{
			color:inherit;
		}

		color:#ffffff;

		&--primary,&--blue{ background-color:$c_primary; }
		&--secondary{ background-color:$c_secondary; }
		&--accent{ background-color:$c_accent; }
		&--dark-blue{ background-color:$c_dark_blue; }
		&--dark-grey{ background-color:$c_dark_grey; }
		&--black{ background-color:$c_black; }
		&--grey{ background-color:$c_grey; }
		&--light-blue{ background-color:$c_light_blue; }
		&--light-grey{ background-color:$c_light_grey; }
		&--white{ background-color:#ffffff; color:$c_dark_grey; }

		&--light-blue,&--light-grey{ color:$c_primary; }
	}

	&--arrow{
		&::before{
			position:absolute;display:block;
			border:rem(40) solid $c_primary;
			width:0;height:0;
			content:" ";
		}

		&--color{
			&--primary,&--blue{ &::before{border-color:$c_primary;} }
			&--dark-blue{ &::before{border-color:$c_dark_blue;} }
			&--secondary{ &::before{border-color:$c_secondary;} }
			&--accent{ &::before{border-color:$c_accent;} }
			&--dark-grey{ &::before{border-color:$c_dark_grey;} }
			&--black{ &::before{border-color:$c_black;} }
			&--grey{ &::before{border-color:$c_grey;} }
			&--light-blue{ &::before{border-color:$c_light_blue;} }
			&--light-grey{ &::before{border-color:$c_light_grey;} }
			&--white{ &::before{border-color:#ffffff;} }
		}

		&--up{
			&::before{
				border-left-color:transparent;border-right-color:transparent;
				border-top:none;

				transform:translateX(-50%);
				left:50%;top:-#{rem(40)};
			}
		}
		&--down{
			&::before{
				border-left-color:transparent;border-right-color:transparent;
				border-bottom:none;

				transform:translateX(-50%);
				left:50%;bottom:-#{rem(40)};
			}
		}
		&--left{
			&::before{
				border-top-color:transparent;border-bottom-color:transparent;
				border-left:none;

				transform:translateY(-50%);
				left:-#{rem(40)};top:50%;
			}
		}
		&--right{
			&::before{
				border-top-color:transparent;border-bottom-color:transparent;
				border-right:none;

				transform:translateY(-50%);
				right:-#{rem(40)};top:50%;
			}
		}
	}

	&__title-bar{
		position:relative;
		padding:30px 0;
		color:#ffffff;

		&--primary,&--blue{background-color:$c_primary; }
		&--dark-blue{background-color:$c_dark_blue; }
		&--secondary{background-color:$c_secondary; }
		&--accent{background-color:$c_accent; }
		&--dark-grey{background-color:$c_dark_grey; }
		&--black{background-color:$c_black; }
		&--grey{background-color:$c_grey; }
		&--light-blue{background-color:$c_light_blue; color:$c_dark_grey; }
		&--light-grey{background-color:$c_light_grey; color:$c_dark_grey; }
		&--white{background-color:#ffffff; color:$c_primary; }
	}

	&__inner{
		position:relative;
		padding:60px 0;
	}
}

.section-filters{
	border-bottom:2px solid $c_primary;
	padding-bottom: 10px;
}

.ho-hint-container {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	border:1px dashed red !important;
	margin-top: 2px !important;
}

.page__status{
	position:relative;display:block;
	padding:0 30px;margin:0;
	width:100%;height:auto;
}
.columns .column.main{max-width:100%;}
.ie10 .page-wrapper, .ie11 .page-wrapper{height: auto;}

.catalogsearch-result-index {
	.headline--fancy, .sub-search-for-content h2{text-align:center; font-family: $f_main; font-weight: 900; @include rfs(34);margin-bottom: 2rem;text-transform: uppercase; line-height: normal;}

}

.page-main > .page__titlebar,
.page-main > .page__titlebar__title,
.expertise--view .page__titlebar,
.column.main > .page__titlebar,
.column.main > .page__titlebar__title,
.iconography > .page__titlebar{
	margin-top:-25px;
}

.cms-noroute-index{
	.page__content{
		.col-md-8{ margin:0 auto; }
	}
}