/****************************************************************************************************/
/******************************************* Viva Beautiful Font *******************************************/
/****************************************************************************************************/
// Normal Regular
@font-face {
	font-family: 'Viva';
	src: url('../../fonts/viva_beautiful_pro_thefontsmaster.com-webfont.eot');
	src: /*url('../../fonts/viva_beautiful_pro_thefontsmaster.com-webfont.eot?#iefix') format('embedded-opentype'),*/
		 url('../../fonts/viva_beautiful_pro_thefontsmaster.com-webfont.woff2') format('woff2'),
		 url('../../fonts/viva_beautiful_pro_thefontsmaster.com-webfont.woff') format('woff'),
		 url('../../fonts/viva_beautiful_pro_thefontsmaster.com-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}


// Bold Regular
@font-face {
	font-family: 'Viva';
	src: url('../../fonts/viva_beautiful_pro_b_thefontsmaster.com-webfont.eot');
	src: /*url('../../fonts/viva_beautiful_pro_b_thefontsmaster.com-webfont.eot?#iefix') format('embedded-opentype'),*/
		 url('../../fonts/viva_beautiful_pro_b_thefontsmaster.com-webfont.woff2') format('woff2'),
		 url('../../fonts/viva_beautiful_pro_b_thefontsmaster.com-webfont.woff') format('woff'),
		 url('../../fonts/viva_beautiful_pro_b_thefontsmaster.com-webfont.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}