.ratings--summary,
.ratings__stars{
	justify-content:flex-start;align-items:center;
	flex-wrap:nowrap;
	display:flex;
}

.ratings__stars{
	list-style-type:none;
	padding:0;margin:0 8px 0 0;
	position:relative;
}

.ratings__star{
	position:relative;
	margin:0 3px 0 0;

	font-size:rem(18);line-height:rem(20);
	color:inherit;
}

.ratings{
	&--summary{
		margin:30px 0;

		&:last-child{ margin-bottom:0; }

		.ratings__count{
			font-size:rem(16);line-height:rem(18);
			display:block;
		}
	}
}