.recipes{
	position:relative;

	&__list{
		padding:0;margin:0 -15px;
		list-style-type:none;
	}
}

.section-recipe-main{
	background:linear-gradient(#ffffff 30%, $c_light_blue 30%);
}

.recipe{
	&__time{
		font-size:rem(14);line-height:rem(16);

		&__label{
			text-transform:uppercase;
			font-weight:900;
			display:block;
		}
		&__value,&__label{ display:inline-block; }
	}

	&__photo, &__photo__image{
		position:relative;display:block;
		width:100%;height:auto;
	}

	&__card{
		flex-flow:column nowrap;
		width:100%;height:100%;
		display:flex;

		.card__content{
			width:100%;height:auto;min-height:1px;
			flex-flow:column nowrap;
			display:flex;
			flex-grow:1;

			.headline{ flex-grow:1; }
		}
	}
}

.product__recipes{
	position:relative;

	&__list{
		list-style-type:none;
		padding:0;margin:0;
	}
}

.recipe--view{
	.recipe{
		&__portion{
			@media all and (max-width:768px){margin-bottom:10px;}
			&__label{
				margin:8px 10px 8px 0;

				font-size:rem(16);line-height:rem(18);
				text-transform:none;
				font-weight:normal;
			}
			&__input{
				padding:0 8px;margin:0 10px 0 0;
				width:55px;height:auto;
				text-align:center;

				font-size:rem(16);line-height:1;
				font-weight:bold;
			}

			&__button{ font-weight:normal;text-transform:none; }
		}


		&__times{
			border-bottom:2px solid $c_primary;
			border-top:2px solid $c_primary;
			position:relative;display:block;
			padding:7px 7px;margin:0 0 40px;
		}

		&__time{
			justify-content:center;align-items:stretch;
			flex-wrap:nowrap;
			display:flex;

			padding:7px 7px;

			@media all and (max-width:576px){ flex-direction: column;}

			&__icon{
				display:block;
				flex:0 0 60px;

				&__image{
					width:auto;height:auto;
					display:block;
					margin:0 auto;
				}
			}

			&__icon,&__details{
				justify-content:center;align-items:flex-start;
				flex-direction:column;
				flex-wrap:nowrap;
				display:flex;

				max-width:100%;
				@media all and (max-width:576px){ text-align: center;align-items: center;}
			}

			&__details{
				padding:10px 15px;margin:0;
				flex:1 0 auto;
			}

			&__label,&__value{
				font-size:rem(16);line-height:rem(18);
				color:$c_dark_grey;
			}
			&__label{ font-weight:normal; text-transform:none; }
			&__value{ font-weight:bold; }
		}

		&__details{
			position:relative;

			&__block{
				margin-bottom:4rem;

				&:last-child{ margin-bottom:0; }
			}

			ol{
				counter-reset:item;

				li{
					counter-increment:item;
					list-style-type:none;
					text-indent:-2em;
					padding:0em;

					&::before{
						margin-right:10px;padding-right:0.5em;
						display:inline-block;
						width:1.5em;

						content:counter(item) ".";
						font-weight:bold;
						text-align:right;
					}
				}
			}
		}
	}
	.portion-calculator{ position:relative;
		@media all and (max-width:768px){margin-bottom:10px;}
		.recipe__portion__label{margin:0 0 10px 0; align-self: flex-start;}
		&__content{position:relative;flex-direction: column;align-items: flex-start;
			input{min-height: 50px;}
		}
		&__actions{	position: absolute;left: 80px;top: 28px;}
	}
}

.section-recipe-ingredients{
	padding-left:6%;padding-right:6%;
}

.ingredients{
	&__units{
		justify-content:flex-start;align-items:stretch;
		flex-wrap:nowrap;
		display:flex;

		width:100%;max-width:300px;
		margin-bottom:15px;
	}
	&__unit{
		background:$c_medium_grey;
		padding:6px;
		width:50%;

		@include transition();

		font-size:rem(16);line-height:1;
		text-align:center;
		font-weight:bold;
		cursor:pointer;
		color:white;

		&--metric{ border-top-left-radius:6px; }
		&--imperial{ border-top-right-radius:6px; }

		&--active{
			background:$c_primary;
			cursor:not-allowed;
		}

		&:not(.ingredients__unit--active):hover{ background-color:darken($c_light_blue,30%); }
	}

	.data-table{
		font-size:1rem;
	}
}

.ingredient{
	&__value{ min-width: 80px; }
}

/*
&__instructions{
	ol{  counter-reset:item;
		li{ padding:0em; text-indent:-2em; list-style-type:none; counter-increment:item;
			&::before{ margin-right: 10px; display:inline-block; width:1.5em; padding-right:0.5em; font-weight:bold; text-align:right; content:counter(item) ".";}
		}
	}
} */