.slider{
	position:relative;

	&__controls{
		justify-content:flex-end;align-items:center;
		position:relative;display:flex;
		flex-wrap:wrap;
	}

	&__arrow{
		position:relative;display:block;
		border:1px solid $c_dark_grey;
		background-color:#ffffff;
		width:42px;height:42px;
		padding:0;margin:0;
		border-radius:0;

		@include transition();

		font-size:rem(24);line-height:42px;
		color:$c_dark_grey;
		text-align:center;


		+ .slider__arrow{ margin-left:10px; }

		&.slick-hidden,&.slick-disabled{
			cursor:not-allowed;
			display:block;
			opacity:0.5;
		}

		&:hover,&:focus{
			border:1px solid $c_dark_grey;
			background-color:#ffffff;
			color:$c_dark_grey;
		}

		&:hover,&:focus{
			&:not(.slick-hidden),&:not(.slick-disabled){
				background-color:$c_primary;
				border-color:$c_primary;
				color:#ffffff;
			}
		}
	}

	.slide__icon{
		position:absolute;display:block;
		transform:translate(-50%,-50%);
		top:50%;left:50%;
		color:#ffffff;

		text-shadow:0px 0px 5px rgba(0,0,0,0.83);
		font-size:rem(32);

		@include transition();
	}

	.slide:hover,&:focus{
		.slide__icon{ color:$c_medium_blue; }
	}
}
.partners .slider{
	.slick-track{margin:0 auto;
		.slide{margin:0 auto;
			img{margin: 0 auto;}
		}
	}
}

.product__gallery__images .icon--play{
	font-size:rem(64);
}