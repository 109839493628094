@media (max-width: 992px) {
	/********************************************************/
	/************************ HEADER ************************/
	/********************************************************/
	.header .logo{flex:0 0 175px;}
	.navigation .level0{font-size:inherit;border-top:none;}
	.navigation .level0.active > a:not(.ui-state-active){}


	/********************************************************/
	/************************ LAYOUT ************************/
	/********************************************************/
	.page-layout-2columns-left,.page-layout-2columns-right{
		.columns{
			.column.main{ width:66.66%; }
			.sidebar-main{ width:33.33%; }
		}
	}


	/********************************************************/
	/*********************** HOMEPAGE ***********************/
	/********************************************************/
	.home{
		&__expertises{
			padding:6% 0;
			&__title.headline{ margin:0 0 30px; }
		}
	}
	.row.expertises{ margin-bottom:-30px; }

	/********************************************************/
	/******************* FLEXIBLE CONTENT *******************/
	/********************************************************/
	.flexible-content{
		&--fullsize{
			.flexible-content__inner{ padding-left:15px; }
		}
	}

	/********************************************************/
	/*********************** CATEGORY ***********************/
	/********************************************************/
	.catalog-product-view{
		.page-status-wrap{flex-direction:column;}
	}
	.category{
		&__intro{ margin-bottom:2rem; }
	}

	/********************************************************/
	/*********************** PRODUCTS ***********************/
	/********************************************************/
	.catalog-product-view{
		.nutrition-facts{margin:0;max-width:280px;width:100%;}
		#tab-content4 .product__block{padding:30px 10px;}
	}

	.product{
		&--view{
			.product__informations__table__cell{
				flex:1 0 auto;
				max-width:62%;
				width:auto;

				&--third{ flex:0 0 40%;max-width:40%; }
			}
			.product__informations__table{
				&__row--upper{
					align-items:stretch;justify-content:flex-start;
					flex-wrap:wrap;

					.product__informations__table__cell{
						border-width:2px 2px 2px 0;
						width:auto;max-width:50%;
						margin:-2px 0 0 0;
						flex:0 0 50%;

						&:last-child{ border-right-width:0; }
					}
				}
			}
			.attribute.sku{
				width:auto;max-width:100%;
				border-width:0;
				flex:0 0 100%;
				margin:0;
			}
		}
	}

	.toolbar{
		&__amount{
			display:block;
			margin:0 0 8px;
			width:100%;
		}
	}
	.limiter__label,.sorter__label{ display:none; }

	/********************************************************/
	/******************* Filters & products *****************/
	/********************************************************/
	.filter__title .btn__text, .product a .btn__text{font-size:0.75rem;}

	/********************************************************/
	/************************* TOOLS ************************/
	/********************************************************/
	.calculator-row{
		.row.head{
			margin-bottom:10px;

			div[class*="col-"]{
				font-size:rem(14);
			}
		}
	}

	/********************************************************/
	/************************ FOOTER ************************/
	/********************************************************/
	.footer__columns{padding:0;}
	.footer__column--links{border-right: 2px solid $c_blue;}

	/********************************************************/
	/************************ OTHERS ************************/
	/********************************************************/
	.expertise__photo{ min-height:475px; }


}