.advantages{
	padding:0;margin-bottom:0;
	list-style-type:none;
	position:relative;

	> li{ margin:0;padding:0; }
}

.advantage{
	align-items:flex-start;justify-content:flex-start;
	flex-direction:column;
	display:flex;

	width:100%;height:100%;
	padding:5% 7%;margin:0;
	position:relative;


	text-align:center;

	> *{ width:100%; }

	&__title{
		font-size:rem(22);line-height:rem(26);
		text-transform:none;
		color:$c_primary;
		font-weight:900;

		margin:17px 0 12px 0;
		display:block;
	}

	&__icon{
		img{
			max-width:170px;height:auto;
		}
	}
}