/*
 * MXO Theme (https://mxo.agency/)
 * Copyright 2018 MXO Agency Team
 */

// Configs, utils and misc.
@import "partials/vars";
@import "../../../bower_components/rfs/scss/rfs";
@import "partials/mixins";

/////////////////////////////////////////////////
// Extra large devices (large desktops, 1200px and down)
@import "responsive/1200px";
// Large devices (desktops, 992px and down)
@import "responsive/992px";
// Medium devices (tablets, 769px and down)
@import "responsive/769px";
// Transitionnal (landscape phones, 577px and down)
@import "responsive/577px";
// Small devices (landscape phones, 480px and down)
@import "responsive/480px";