@media (max-width: 577px) {
	/********************************************************/
	/************************ HEADER ************************/
	/********************************************************/
	//.header__navigation{padding: 25px 20px 0 20px;
		/*ul li a{font-size: .9rem;}*/
//	}
	/*
	.header__additionals .btn-expert{font-size:.9rem;}
	.header__additionals__upper{border:none;}
	.header__customer__subs{display: flex;justify-content: center;border-top:2px solid $c_primary;border-bottom:2px solid $c_primary;
		li{width:50%;
			&:first-child{border-right:1px solid $c_primary;}
			&:last-child{border-left:1px solid $c_primary;}
		}
	}
	*/
	/********************************************************/
	/*********************** HOMEPAGE ***********************/
	/********************************************************/
	.testimonies{ width: 100%; max-width: 100%; flex: 0 0 100%;}
	.tabs__content{padding: 40px 0 0 0;}


	/********************************************************/
	/************************* MISC *************************/
	/********************************************************/
	.catalogsearch-result-index{
		.page__titlebar{
			.container{ padding-left:0; }
		}

		.headline--fancy, .sub-search-for-content h2{ }
	}


	/********************************************************/
	/*********************** CATEGORY ***********************/
	/********************************************************/
	.toolbar__widgets{
		.toolbar__limiter{ margin-bottom: 10px; margin-top: 10px; }
	}

	/********************************************************/
	/*********************** PRODUCTS ***********************/
	/********************************************************/


	/********************************************************/
	/*********************** WISHLIST ***********************/
	/********************************************************/
	.form-wishlist-items{
		.actions-toolbar{
			align-items:flex-start;justify-content:flex-start;
			flex-direction:column;
			flex-wrap:nowrap;

			width:auto;height:auto;

			> .primary, > .secondary{
				align-items:flex-start;justify-content:flex-start;
				flex-direction:column;
				flex-wrap:nowrap;

				width:100%;max-width:100%;height:auto;

				> *{
					width:100%;max-width:100%;height:auto;
					margin-bottom:8px;

					&:last-child{ margin-bottom:0; }
				}

				.btn.btn--icon{
					padding-left:15px;padding-right:15px;
					.btn__icon{
						position:relative;display:inline;
						top:initial;bottom:initial;
						left:initial;right:initial;
						transform:translate(0);
						margin:0;padding:0;
					}
				}
			}
			> .primary{ order:1; }
			> .secondary{ order:2; }
		}
	}
	.abs-margin-for-forms-desktop,
	.fieldset .legend,
	.column:not(.sidebar-main) form .actions-toolbar,
	.column:not(.sidebar-additional) form .actions-toolbar,
	.login-container .fieldset::after,
	.fieldset > .field:not(.choice) > .label,
	.fieldset > .field:not(.choice) > .control,
	.login-container .fieldset > .field > .control{

	}


	/********************************************************/
	/*********************** SEGMENTS ***********************/
	/********************************************************/
	.expertises{

	}

	.expertise{
		&__photo{
			min-height:initial;

			&__img{
				min-width:unset;min-height:unset;
				max-width:100%;max-height:none;
				width:auto;height:auto;
				position:relative;
				transform:none;
				top:0;left:0;
			}
		}
		&__header{
			.row{
				margin-left:0;margin-right:0;
			}

			&__intro{
				padding:40px 15px;
				.headline--main{
					margin:0 0 11% 0;padding-top:7%;
					line-height:0.75;
					max-width:100%;
				}
			}
		}
		&__ctas{
			.flex{
				flex-flow:column nowrap;
			}

			.btn{
				width:300px;max-width:100%;
				margin:0;

				+ .btn{ margin-top:12px; }
			}
		}
	}

	.advantage{
		&__icon{
			img{ max-width:130px;height:auto; }
		}
	}

	.certifications__slider{
		padding-left:37px;
		padding-right:37px;
		.slider__arrow{

		}
	}


	/********************************************************/
	/*********************** CUSTOMER ***********************/
	/********************************************************/


	/********************************************************/
	/************************ FOOTER ************************/
	/********************************************************/


	/********************************************************/
	/************************ OTHERS ************************/
	/********************************************************/

}
