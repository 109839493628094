.producttechnicalsheet-product-view,
.producttechnicalsheet-product-download{
	font-family:Arial,Helvetica,sans-serif !important;

	.page-main{ padding:0;margin:0 auto; }
	.page-wrapper{ padding:15px; }

	margin:0;padding:0;

	body, .really-empty{margin:0; padding:0;}
}

.technical-sheet{
	border:2px solid $c_primary;border-collapse:collapse;
	margin:0;padding:0;
	table-layout:fixed;
	width:100%;

	&__body{
		padding:0;margin:0;
		position:relative;
		border:none;

		font-size:rem(14);line-height:1.25;

		p,li,.attribute__label,.attribute__value{ font-size:inherit; }

		> thead,
		> tbody{
			> tr > th{ padding:0; }
		}
		> tr > th{ padding:0; }
	}

	&__wrapper{ padding:0;margin:0; }

	&__header{
		border:solid $c_primary;border-width:0 0 2px 0;
		padding:15px 15px;margin:0;
		table-layout:fixed;
		position:relative;

		td{ vertical-align:middle; }

		&__metas{
			padding:15px 0 15px 15px;margin:0;
			width:70%;
		}

		&__additionals{
			border-left:2px solid $c_primary;
			width:20%;height:auto;
			padding:0;

			text-align:center;vertical-align:middle;

			&__table{
				width:100%;height:100%;
				position:relative;
				padding:0;
			}

			.attribute{
				border-bottom:2px solid $c_primary;
				text-transform:uppercase;
				font-weight:bold;
				color:$c_primary;
			}

			tr:last-child .attribute{ border-bottom:none; }
		}

	}
	&__brand{
		text-align:center;vertical-align:middle;
		border-left:2px solid $c_primary;
		width:200px;
	}

	&__logo{
		padding:15px 0;margin:0;
		width:180px;

		&__img{
			display:block;position:relative;
			width:100%;height:auto;
			float:none;clear:both;
			margin:0;padding:0;
		}
	}

	&__footer{
		border:solid $c_primary;border-width:2px 0 0 0;
		padding:15px 15px;margin:0;
		position:relative;
	}

	&__aside{
		border-left:2px solid $c_primary;
		padding:15px 15px;margin:0;
		position:relative;
		width:98px;

		vertical-align:middle;text-align:center;
	}

	&__label{
		writing-mode:vertical-rl;text-orientation:mixed;
		text-align:center;vertical-align:middle;
		font-size:49px;line-height:53px;
		letter-spacing:1.5px;
		color:$c_primary;
	}

	&__main{
		position:relative;display:block;
		width:100%;height:auto;
		padding:15px 15px;
	}

	.nutrition-facts{
		width:100%;
		float:none;
		margin:0;
	}

	&__col{
		position:relative;

		&--attributes{ padding:0 15px 0 0; }
		&--nutrition-facts{ padding:0; width:275px; }
	}

	.attributes{
		position:relative;
		margin:0 0 25px;

		&__label{
			margin:0 0 10px;
			display:block;

			font-size:rem(21);line-height:rem(25);
			text-transform:uppercase;
			font-weight:600;
			color:$c_primary;
		}
		&__box{
			//border:1px solid $c_blue;
			//padding:10px 10px;
			padding:7px 0;
		}

		&:last-child{ margin-bottom:0; }
	}
	.attribute{
		margin-bottom:6px;
		position:relative;
		max-width:100%;

		&:last-child{ margin-bottom:0; }

		&--inline{
			display:inline-block;
			vertical-align:top;
			margin-right:10px;
		}

		&__label, &__value{
			position:relative;display:inline-block;
			vertical-align:top;
			margin:0;

			text-transform:none;
		}
		&__label{
			margin-right:6px;
			color:$c_primary;
			font-weight:600;
		}
		&__value{
			color:$c_dark_grey;
			font-weight:normal;
		}
	}
	/* IF PDF goes on 2 page -> remove padding */
	.attributes-table{
		td,th{
			padding:2px 0;
		}
		td:first-child{ width:34%; }
	}
}