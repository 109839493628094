@media (max-width: 1200px) {
	/********************************************************/
	/************************ HEADER ************************/
	/********************************************************/


	/********************************************************/
	/*********************** HOMEPAGE ***********************/
	/********************************************************/


	/********************************************************/
	/*********************** CATEGORY ***********************/
	/********************************************************/


	/********************************************************/
	/*********************** PRODUCTS ***********************/
	/********************************************************/
	.catalog-product-view{
		.product__socials{display: flex;align-self: flex-end;margin-top: 20px;}
		.breadcrumb__items{flex-direction:column;align-items: flex-start;}
	}

	/********************************************************/
	/*********************** CUSTOMER ***********************/
	/********************************************************/


	/********************************************************/
	/************************ FOOTER ************************/
	/********************************************************/
}

@media (max-width: 1200px) and (min-width: 768px) {
	/*.header{padding:30px 1%;}
	.header .search .input-text{height:75px;}
	.header .header-container{ flex-direction: column; }
	.header__navigation{order: 2; padding:25px 0;}
	.header__additionals .btn-expert{width:140px; min-width:140px;}
	.header__additionals{order: 1;flex-direction: row; justify-content: flex-end;}
	.header__additionals__lower{border-top:none; border-left:2px solid $c_primary; order:1;    width: 100%;}
	.header__additionals__upper{order:2; }
	.navigation ul{padding:0;}
	.header__additionals{width:100%;}*/
}
