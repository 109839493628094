.account-nav{
	border:2px solid $c_primary;

	.content{
		background-color:$c_light_blue;
	}

	.item{
		.delimiter{
			border-color:$c_primary;
			display: none;
		}
		&.current{ a,strong{ border-color:transparent;color:$c_dark_grey; } }

		a:hover,a:focus{ background-color:darken($c_light_blue,15%); }
	}
}

.account{
	.column.main{
		.block:not(.widget){
			.block-title{
				justify-content:flex-start;align-items:center;
				display:flex;
				width:100%;

				> strong{
					@extend .headline,.headline--block,.headline--uppercase,.headline--color--primary,.headline--weight--black;
					margin:0 10px 0 0;
				}
				.action{
					@extend .btn,.btn--outline,.btn--small;
				}
			}
			.box-title{
				justify-content:flex-start;align-items:center;
				display:flex;
				width:100%;

				> span{
					@extend .headline,.headline--sub,.headline--weight--bold;
					margin:0 10px 0 0;
				}
			}
		}
		.box-actions{
			justify-content:flex-start;align-items:center;
			display:flex;
			width:100%;

			.action{
				@extend .btn,.btn--raised,.btn--small;
				margin:0 8px 8px 0;
			}
			.action + .action{ @extend .btn--raised--black; }
		}
	}


	form{
		> .row > div[class*="col-"]{
			margin-bottom:30px;
		}
	}

}

.form-create-account{
	.register-column{
		select{font-size:1rem;}
		@media screen and (max-width: 769px) {margin-bottom:30px;}
	}

	.field.newsletter{
		label{
			font-size:rem(20);
			font-weight:bold !important;
			color:$c_primary;

			.icon{
				position:relative;
				font-size:rem(23);
				top:1px;
			}
		}
	}
}
.customer-address-form{
	select{font-size:1rem;}
}