.section-home-expertises{
	background:transparent url('../../images/bg-expertises-2.jpg') no-repeat center center;background-size:cover;

	&::before{
		background:rgba(0,0,0,0.3);
		width:100%;height:100%;
		position:absolute;
		top:0;bottom:0;
		left:0;right:0;
		content:" ";
	}

	.section__inner{ padding-bottom:15%; }
}

.cms-index-index{
	.page__banner{
		width:100%;max-width:1350px;height:auto;
		padding:0;margin:/*-4%*/0 auto 0 auto;
		position:relative;display:block;
		z-index:5;

		p{
			position:relative;display:block;
			/*padding:0 0 56.25% 0;*/padding:0;margin:0;
			width:100%;height:auto;
		}

		iframe {
			width:100%;height:100%;
			position:absolute;
			top:0;left:0;
		}
	}
}

.section-home-articles{
	.headline--fancy__sub{ margin-top:0; }
	.section{
		margin-top:Vmin(80);
	}
}

.section-home-indexation{
	//margin-top:-100px;
	z-index:5;

	.section{
		&__title-bar{
			padding-top:Vmin(130);padding-bottom:Vmin(90);

			@media screen and (max-width: 576px) {padding-top:100px;}

		}
		&__inner{
			padding-top:8%;padding-bottom:6%;
		}
	}

	.headline--main.headline--font--script{ @include rfs(110);line-height:0.85; }



}

.home{
	&__indexation{
		//position:relative;

		&__content{
			position:relative;
			max-width:560px;

			.wysiwyg{ margin-bottom:40px; }
		}

		&__image{
			transform:translateY(-50%);
			position:absolute;
			top:50%;right:0;

			height:auto;max-height:740px;
			width:50%;max-width:850px;
			overflow:hidden;

			img{
				width:auto;height:auto;
				display:block;
			}

			p{ margin-bottom:0; }
		}
	}

	&__expertises{
		position:relative;
		z-index:10;

		&__column{
			&--side{
				justify-content:center;align-items:flex-start;
				flex-flow:column wrap;
				display:flex;

				> *{
					max-width:100%;
					flex:0 0 auto;
				}
			}
		}

		&__title{
			@include rfs(64);
			margin-top:0;
		}

		.row.expertises{ margin:-15px -15px -100px -15px; }
	}
}

.slogan{
	width:100%;height:auto;max-width:920px;
	position:relative;display:block;
	padding:0 20px rem(30);margin:0 auto;
	//pointer-events:none;
	z-index:10;
}

html[lang="en"]{
	.slogan{
		max-width:725px;
	}
}

.hero-banner{
    padding: 0; margin: 0 auto 30px auto;
    width: 100%; max-width: 1350px;height: auto;
    position: relative; display: block; z-index: 5;
    @media all and (max-width:767px){margin:0 auto;}

    .desktoponly{display:block;
        @media all and (max-width: 767px){display: none;}
        &.nomobile{display:block;
            @media all and (max-width: 767px){display: block;}
        }
    }
    .mobileonly{
        display: none;
        @media all and (max-width: 767px){display: block;}
    }
}
.hero-img{background-repeat: no-repeat; background-position: center; background-size: cover; width: 100%; height: 525px;
    @media all and (max-width:767px){height: 325px;}
    @media all and (max-width:576px){height: 225px;}
}
.hero-banner-slider-wrapper{
    width: 100%; height: 525px;
    @media all and (max-width:767px){height: 325px;}
    @media all and (max-width:576px){height: 225px;}
}
.hero-banner-slider{
    width: 100%;
    .hiddenofstart{
        display: none;
    }
    &.slick-initialized{
        .hiddenofstart{
            display: block;
        }
    }
    img{width: 100%;object-fit: cover;height:525px;
        @media all and (max-width:767px){height: 325px;}
        @media all and (max-width:576px){height: 225px;}
    }

    .slick-next{right:12px;
        &::before{content:url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJhbmdsZS1yaWdodCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWFuZ2xlLXJpZ2h0IGZhLXctOCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgNTEyIj48cGF0aCBmaWxsPSIjMjI2MGEyIiBkPSJNMjI0LjMgMjczbC0xMzYgMTM2Yy05LjQgOS40LTI0LjYgOS40LTMzLjkgMGwtMjIuNi0yMi42Yy05LjQtOS40LTkuNC0yNC42IDAtMzMuOWw5Ni40LTk2LjQtOTYuNC05Ni40Yy05LjQtOS40LTkuNC0yNC42IDAtMzMuOUw1NC4zIDEwM2M5LjQtOS40IDI0LjYtOS40IDMzLjkgMGwxMzYgMTM2YzkuNSA5LjQgOS41IDI0LjYuMSAzNHoiPjwvcGF0aD48L3N2Zz4=");}
    }
    .slick-prev{left:12px;
        &::before{content:url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJhbmdsZS1sZWZ0IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtYW5nbGUtbGVmdCBmYS13LTgiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjU2IDUxMiI+PHBhdGggZmlsbD0iIzIyNjBhMiIgZD0iTTMxLjcgMjM5bDEzNi0xMzZjOS40LTkuNCAyNC42LTkuNCAzMy45IDBsMjIuNiAyMi42YzkuNCA5LjQgOS40IDI0LjYgMCAzMy45TDEyNy45IDI1Nmw5Ni40IDk2LjRjOS40IDkuNCA5LjQgMjQuNiAwIDMzLjlMMjAxLjcgNDA5Yy05LjQgOS40LTI0LjYgOS40LTMzLjkgMGwtMTM2LTEzNmMtOS41LTkuNC05LjUtMjQuNi0uMS0zNHoiPjwvcGF0aD48L3N2Zz4=");}

    }
    .slick-prev, .slick-next{z-index: 200;
        border:none !important;

    }

}
.hero-box{
    position:absolute;left:7%;top:50%;transform: translateY(-50%);
    background:rgba(255,255,255,0.9);width:100%;max-width: 565px;padding:30px;

    @media all and (max-width: 767px){position:inherit;left:0;top:0;padding:20px;max-width: 100%;transform: none;}
    &__flag{color:$c_blue;font-size:rem(18);
        @media all and (max-width: 767px){display: none;}
    }
    &__main-title{color:$c_blue;font-size:rem(30);font-weight:900;line-height: normal;margin-bottom: 10px;
        @media all and (max-width: 767px){font-size:rem(24);}
    }
    &__text{color:$c_blue;font-size:rem(16);
        @media all and (max-width: 767px){font-size:rem(14);}
    }
    &__form{margin-top: 20px;
        button{width:100%;font-size:rem(14);
            .btn__icon {font-size: 24px;}
        }
        .mage-error[for="email"]{margin-bottom:10px;}
        .input-text{background:#E4F1FA;border:none;color:$c_blue;font-size:rem(14);font-weight: bold;    margin-bottom: 10px;
            &::placeholder {color:$c_blue;}
            &:focus::placeholder {color: transparent; }
        }
    }
}
.alreadymember{color:$c_primary;padding:15px;}
