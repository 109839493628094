.category{
	&__intro{
		/*justify-content:space-between;align-items:stretch;
		display:flex;flex-direction:row;*/
		//flex-wrap:wrap;

		position:relative;display:block;
		//border:2px solid $c_primary;
		margin:0 0 60px 0;

		.category-cms{order:2;}

		.category-image{
			margin:0;padding:0;

			+ .category__intro__text{
				transform:translateY(-50%);
			}
		}

		&__content{
			padding:8% 8%;
			flex-grow:1; order:1;
		}

		&__left,&__content{
			justify-content:center;align-content:center;
			flex-direction:column;
			position:relative;
			display:flex;

			max-width:100%;

			> *{ max-width:100%; }
		}

		&__left{
			padding:6% 6%;
			width:30%;

			+ .category__intro__content{
				//border-left:2px solid $c_primary;
			}
		}

		.page__titlebar{
			padding:4% 2% 1% 2%;margin:0;
			background-color:$c_accent;
			width:auto;height:auto;
			display:inline-block;

			&__title{
				padding:0;margin:0;
				line-height:1;
				color:#ffffff;

				.headline--fancy__text{ padding-left:0;padding-right:0; }
			}

			.container{ padding-left:0;padding-right:0; }
		}

		&__text{
			width:auto;
		}

		.headline--fancy{
			@include rfs(110);
			overflow:visible;

			&.a-center{ text-align:left; }

			&__text{
				&::before,&::after{ display:none; }
			}
		}
	}

	&__description{
		margin:60px 0 0 0;padding:40px 0 0 0;
		border-top:1px solid $c_light_blue;
	}

	&__icon{
		&__image{
			max-width:100%;max-height:100%;
			width:auto;height:auto;
			display:block;
		}
	}
	&__filters{
		h2{margin-left:20px;}
		&__list{list-style: none; margin:0; padding:0;
			li{
				@media all and (max-width:992px){ padding: 0 10px;}
			}
		}
	}
}

.category-cms{
	.section-home-categories{
		.container{
			max-width:100%;
			padding:0;
		}
	}
}

/*
body.category-our-products .category__intro{
	.category-cms{order:1;}
	.category__intro__content{order:2;}
}*/


html.ie10 body, html.ie11 body{
	.page-main{ height: 100%;}
	.category__intro{flex-wrap:wrap; /*height: 300px;*/}
	.category__intro__content{width:70%;}
	.category__icon{overflow:hidden;}
}