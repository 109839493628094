.mpblog-category-view .columns .column.main{float:none !important;width:100% !important;}
.mpblog-category-view,.mpblog-post-index{
	.columns .column.main{ position:relative; max-width: 1140px;}
	.page__titlebar__title{ padding-bottom:3rem; }
	.pager__amount{ display:none;}
	.pager{ font-size:16px; font-weight: normal; strong{ font-weight: normal; }}
	//.post-list-content{ padding-bottom: 60px; }
	.pager__page--next,.pager__page--previous{ border: 1px solid $c_primary; }
	.post-list-body{ display:flex; flex-wrap:wrap; justify-content: start;  padding-top: 75px;
		@media all and (max-width:991px){ justify-content: start; }
		@media all and (max-width:576px){ justify-content: center; }
	}
	.blog__select{display: flex; align-items: center; justify-content: start; position: absolute; left: 0; top: 0;z-index: 18;
		@media all and (max-width:769px){ flex-direction: column;}
		@media all and (max-width:480px){ position: relative; }
		.select-text{ color:#2260a2; font-size:16px; margin-right:20px;
			@media all and (max-width:769px){ margin-right: 0; margin-bottom: 5px;}
		}
		#category-selector{ width: 255px; padding-left: 15px; text-transform: capitalize; line-height: 1.5; border:1px solid #444444; height: 34px;
			-webkit-appearance: none;background: url("../../images/ui/select-arrow.png") no-repeat right white;
			&::-ms-expand { display: none;	}
		}
	}
	.blog__nav{
		&--top{
			@media all and (max-width:480px){ margin-top:25px; }
			#limiter{ line-height: 1; border:1px solid #444444; height: 34px; -webkit-appearance: none; background: url("../../images/ui/select-arrow.png") no-repeat right white; padding: 0 30px 0 15px;
				&::-ms-expand { display: none;	}
			}
			.pager__pages{ display:none; }
			.pager{ justify-content: flex-end;
				@media all and (max-width:480px){ justify-content: center; }
			}
			.pager__limiter{ margin:0;
				@media all and (max-width:769px){ flex-direction:column; margin-top: 5px;
					strong{margin-bottom:5px;}
					.limiter-text{margin-top:5px;}
				}
				@media all and (max-width:480px){ flex-direction:row;
					strong{margin-bottom:0;}
					.limiter-text{margin-top:0;}
				}
			}
		}
		&--bottom{ padding-top: 20px;
			@media all and (max-width:576px){ padding-top: 0; }
			.pager__limiter{ display:none; }
			.pager__pages{ position: absolute; left: 50%; top: 0; transform: translateX(-50%);}
		}
	}
	.blog__noposts{padding-top: 70px;}
}

/*POST VIEW*/
.mpblog-post-view{
	.hidden{display:none;}
	.page-main{ padding: 0; }

	/*.page-content-wrapper{ position:relative; max-width: 1140px; margin-left: auto; margin-right: auto; padding:3% 0;
		@media all and (max-width:1200px){ padding: 6% 30px; }
	}*/
	.blog-post-view{
		&__aside{
			flex:0 0 300px;max-width:300px;
			@media all and (max-width:1024px){
				flex:1 0 100%;max-width:100%;
			}
		}

		&__title{font-weight: 900;
			@media all and (max-width:769px){ margin-top:20px; }
		}
		&__image{
			img{object-fit: cover;}
		}
		&__content{
			max-width:900px;
			@media all and (max-width:1024px){ max-width:100%; }

			.wysiwyg{
				h2{color:$c_primary;font-size:24px;}
				h3{color:#444444;font-size:18px; margin-bottom:0;}
				p{color:#444444;}
			}
		}
		&__date{ color:$c_primary; margin-bottom: 30px; display: block;}
		&__link{ margin-top: 50px;
			a{ text-transform: uppercase; color:$c_primary; font-weight: bold; font-size:14px;
				svg,i{ margin-right: 20px; }
			}
		}
	}
	.comments-title{ font-size:24px;font-weight: 900; color:$c_primary; margin-bottom: 20px;}
	.blog__comments{ background:$c_light_blue; padding-top:60px;
		@media all and (max-width:1200px){ padding-left:30px; padding-right:30px; margin-top: 30px;}
		.block-blog-related{ padding-bottom:92px; max-width: 1140px; margin:0 auto;}
		.related-content-container{ max-width:1140px; padding:0;
			textarea{ max-width: 900px; border: none;}
		}
		.cmt-row__reply-row{width:100%;}
		.reply-form__form-input{width:100%;max-width:100%; flex:0 0 100%;}
		div.default-cmt__content__cmt-content{margin-top:70px; margin-bottom: 20px;}
		li.default-cmt__content__cmt-content__cmt-row.cmt-row{
			border-top:none;
			width:100%;
		}
		ul.default-cmt__content__cmt-content{max-width:900px; padding-right: 20px;flex-direction: column;
			&:first-child{padding-left:20px !important;}
		}
		.default-cmt__content__cmt-block__cmt-box__cmt-btn{ float:left; margin-top: 20px;}
		li .default-cmt__content__cmt-content .default-cmt__content__cmt-content__cmt-row {border-left:3px solid $c_primary; padding-left:15px;margin:30px 0 30px 20px;}
	}
	.cmt-row{
		&__cmt-content{
			position:relative;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.mp-blog-view{
	padding:Vmin(80) 0;
}

.default-cmt__content{
	.messages{ max-width:900px; }
}

.mpblog-post-view .blog__comments .default-cmt__content__cmt-block__cmt-box__cmt-btn{
	float:none;
}

div.default-cmt__content__cmt-content{
	background-color:#ffffff;
	float:none;clear:both;
	max-width:900px;
	padding:5% 5%;
}
