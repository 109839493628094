.vipzone{
	strong{font-weight:900;}
	&__cmsBlock{ padding-bottom:100px; padding-top: 30px;
		@media screen and (max-width: 768px) {padding-bottom:60px;}

	}
	&__cards{ padding-bottom:40px;
		.cards-wrapper{ padding-top:20px;    justify-content: center;
			@media screen and (max-width: 768px) {flex-direction: column;}
			@media screen and (max-width: 577px) {padding-top:0;}
		}
	}
	&__subtitle{ margin-bottom:40px; @include rfs(36); line-height:1;
		@media screen and (max-width:480px) {font-size:24px;}
	}
	&__card{ margin:0 20px; width:30%;overflow: hidden;
		@media screen and (max-width: 768px) {width: 60%; margin: 0 auto;margin-bottom:20px;}
		@media screen and (max-width: 480px) {width: 90%; margin: 0 auto;margin-bottom: 20px;}
	}
	&__account{ background:$c_light_blue; min-height: 500px; display:flex; margin-bottom: 60px;
		.account-wrapper{position: relative; justify-content: flex-end;}
		&__list{ margin-left: auto;	margin-right: 18%; padding: 0 30px;display: flex;flex-direction: column;justify-content: center;
			.btn{margin-left: 90px;
				@media screen and (max-width:577px) {margin-left: 0;}
			}
			@media screen and (max-width:992px) {width:100%;align-items: center;margin:50px auto;}
			@media screen and (max-width:480px) {padding: 0 15px;}
		}
		&__tablet{display: flex;width: 35%;justify-content: flex-end;
			img{max-width:600px;position: relative;top: 50px;
				@media screen and (max-width:1200px) {right:-15%;}
			}
			@media screen and (max-width:992px) {display:none;}
		}

		&__steps{ counter-reset: myOrderedListItemsCounter; margin:0; padding:0;
			li{ list-style-type: none; position: relative; display: flex; align-items: center; margin-bottom: 30px;font-size:20px;
				@media screen and (max-width:480px) {font-size:16px;}
				&:before{
					content: counter(myOrderedListItemsCounter); counter-increment: myOrderedListItemsCounter;
					background:$c_blue; color:white;margin-right: 30px;	text-align: center; font-weight: bold;
					display: flex; flex-direction: column; justify-content: center;border-radius: 100%;
					width:60px; height:60px;@include rfs(36);
					@media screen and (max-width: 768px) {width:45px; height:45px;}
					@media screen and (max-width:480px) {margin-right: 20px;}
				}
			}
		}
	}
}
.ie11 {
	.vipzone__account .account-wrapper{justify-content: flex-start;}
	.vipzone__account__tablet{position:absolute;right:0;}
	.vipzone__account__tablet img{width:600px;   height: 612px; max-width: 100%;top:0;}
	.vipzone__account__list{margin: 30px 110px; padding-left:16%;padding-top:20px;}
	@media screen and (max-width:1200px) {.vipzone__account__list{margin: 30px 110px; padding-left:6%;}	.list-side__actions{margin-bottom:60px;}}
	@media screen and (max-width:992px) {.vipzone__account__list{margin-left:auto;margin-right:auto;margin-bottom:0;margin-top:30px;}}
	@media screen and (max-width:577px) {.vipzone__account{min-height: auto; padding-top:30px;}}
	@media screen and (max-width:480px) {.vipzone__account__list{margin:0;}}
}

.privatezone{
	position:relative;

	&__quicklinks{
		margin:-8px -8px 0 -8px;
		position:relative;

		> div[class*="col-"]{
			padding:8px 8px;
		}

		.tile__title--smaller{
			font-size:2.25rem;
		}
	}
}