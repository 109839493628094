#newsletter-modal-container{
	.modal{padding-top:50px;background:none;left:50%;top:55%;transform: translate(-50%,-50%);}
	.modal-content{max-width:1200px;display:flex;border:none;
		span.close{cursor:pointer;font-size: 45px;position:absolute;top:-28px;right:-28px; width: 56px;height: 56px;border-radius: 50%;background: $c_medium_blue;display: flex;align-items: center;justify-content: center;
			@media screen and (max-width: 769px) {font-size:35px;width:46px;height:46px;top:-23px;right:-23px;}
		}
		&__content{text-align: center;display:flex;flex-direction: column;width:58%;padding: 40px 8%;
			@media screen and (max-width: 769px) {padding: 30px 20px;width:100%;}
			.headline--block{
				@media screen and (max-width: 576px) {margin-bottom:5px;}
			}
			.headline--main{margin-top: 20px;margin-bottom: 20px;
				.larger{font-size:85%;}
			}
			p{font-size:rem(16);padding: 0 8%;color: black;
				@media screen and (max-width: 769px) {padding:0 20px;}
				@media screen and (max-width: 576px) {font-size:rem(14);padding:0 15px;}
			}
			form{text-align:left;
				@media screen and (max-width: 769px) {max-width:500px;margin:0 20px;}
				@media screen and (max-width: 480px) {margin:0;}

				label{font-size:rem(16);color:#08609f;}
				input[type="text"],input[type="email"]{background:#e4f1fa;border:none;outline:none;margin-bottom: 15px;}
				button[type="submit"]{width: 100%;max-width: 260px;}
				.form__action{display:flex;align-items: center;justify-content: center;margin-top:40px;
					@media screen and (max-width: 576px) {margin-top:25px;}
				}
			}
		}
		&__image{background-image:url('../../images/popupimg.png');	background-repeat: none;background-size: cover;background-position: center top;	width:42%;
			@media screen and (max-width: 769px) {display:none;}
		}
	}
}
html[lang="fr"]{
	#newsletter-modal-container{
		.modal-content__content .headline--main{margin-bottom:10px;}
	}
}
body.popup-active{
	.page__banner{z-index: 0;}
	.slogan{z-index: 0;}
	.overlay-popup{ opacity:1;display:block;}
	.page__header{z-index:0;top:0 !important;
		@media screen and (max-width: 769px) {visibility: hidden;}
	}
}
.overlay-popup{ display:none;opacity:0;background-color:rgba(0, 0, 0, 0.75);width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 1;transition: all 500ms ease-out;}