%input{
	border:1px solid $c_grey;
	position:relative;display:block;
	background-color:#ffffff;
	padding:0 20px;margin:0;
	width:100%;height:42px;
	border-radius:0;
	box-shadow:none;

	font-size:rem(18);line-height:40px;
	font-family:$f_main;
	color:$c_dark_grey;
	font-weight:400;

	@include transition();

	&[readonly],&[disabled]{
		cursor:not-allowed;
		opacity:0.6;
	}

	&:not([disabled]):focus{
		border-color:$c_primary;
		box-shadow:none;
		outline:none;
	}
}

%label{
	position:relative;display:block;
	margin:0 0 14px 0;padding:0;

	font-size:rem(16);line-height:rem(18);
	font-family:$f_main;
	text-transform:none;
	color:$c_dark_grey;
	font-weight:600;
}

.fieldset .field > label,
.fieldset .field > .label,
.label{
	@extend %label;
}


input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="date"],
input[type="datetime"],
input[type="url"],
input[type="tel"],
select{
	@extend %input;
}

textarea,
textarea.form-control,
textarea.input-text{
	@extend %input;

	min-height:100px;height:220px;max-height:280px;
	line-height:rem(22);
	padding:10px 10px;
	resize:vertical;

	@include transition(border);
}

select{
	//text-transform:uppercase;
	cursor:pointer;

	&:not([multiple]),
	&.form-control:not([multiple]),
	&.input-text:not([multiple]){
		border:1px solid $c_grey;
		height:42px;line-height:40px;
	}
}

.flex > .field{
	margin-bottom:25px;
	width:100%;

	&:last-child{ margin-bottom:0; }
}

.field{
	margin-bottom:25px;
	position:relative;


	&--width{

		&--full,&--half,&--tier,&--quart{
			padding:0 15px;
		}

		&--full{ width:100%; }
		&--half{ width:50%; }
		&--tier{ width:33.33%; }
		&--quart{ width:25%; }
	}

	.control{ position:relative;display:block; }

	&.choice{
		&--fake{

			label{ cursor:pointer; }

			input{
				display:block;position:absolute;
				width:1px;height:1px;
				clip:rect(0,0,0,0);
				margin:0;padding:0;
				top:-1px;left:-1px;
				overflow:hidden;

				~ .icon{
					&--checked{ display:none; }
					&--unchecked{ display:inline; }
				}

				&:checked{
					~ .icon{
						&--checked{ display:inline; }
						&--unchecked{ display:none; }
					}
				}
			}
		}
	}
}

.dropdown{
	position:relative;

	select{
		appearance:none;
		padding-right:70px;

		&:focus ~ .dropdown__trigger{ border-color:$c_primary; }
	}

	&__trigger{
		position:absolute;display:block;
		border-left:1px solid $c_grey;
		@include transition(border);
		width:55px;height:100%;
		pointer-events:none;
		top:0;right:0;

		&__icon{
			position:absolute;display:block;
			transform:translate(-50%,-50%);
			top:50%;left:50%;
			color:$c_primary;
		}
	}
}

.actions,
.actions__primary,
.actions__secondary,
.actions-toolbar,
.actions-toolbar > .primary,
.actions-toolbar > .secondary{
	justify-content:space-between;align-items:center;
	position:relative;display:flex;
	flex-wrap:wrap;

	&::before,&::after{ display:none; }
}

.actions-toolbar,
.actions{
	> .primary,.actions__primary{ order:2; }
	> .secondary,.actions__secondary{ order:1; }
}

.action__primary,
.actions-toolbar > .primary .action,
.action.primary{
	@extend .btn,.btn--raised;
}
.actions-toolbar > .secondary .action{
	@extend .btn;//,.btn--outline,.btn--outline--black;
}

button.action.reload{ @extend .btn,.btn--outline,.btn--outline--black,.btn--small; }

// Reset Magento Blank horizontal labels
.abs-margin-for-forms-desktop,
.fieldset .legend,
.column:not(.sidebar-main) form .actions-toolbar,
.column:not(.sidebar-additional) form .actions-toolbar,
.login-container .fieldset:after,
.fieldset > .field:not(.choice) > .label,
.fieldset > .field:not(.choice) > .control,
.login-container .fieldset > .field > .control{
	float:none;clear:both;
	text-align:left;
	margin-left:0;
	width:100%;
}

.fieldset > .field.choice:before, .fieldset > .field.no-label::before{ display:none; }

div.mage-error[generated]{
	font-size:rem(13);line-height:rem(15);
	font-weight:bold;
	color:$c_error;
}

.password-strength-meter{
	text-indent:-999px;
	overflow:hidden;
	height:3px;
}

.fieldset .legend,
.fieldset > .legend{
	@extend .headline,.headline--block,.headline--weight--black,.headline--color--primary,.headline--uppercase;
}

.form-address-edit{
	.actions-toolbar .action.primary{
		font-size:rem(16);line-height:rem(18);
		padding:15px 15px;
	}
}

.field.required > .label:after,
.fields > .field.required > .label:after,
.field._required > .label:after,
.fields > .field._required > .label:after{
	content: '*';
	color: #e02b27;
	font-size: 1.2rem;
	margin: 0 0 0 5px;
}

.fieldset > .field .additional,
.fieldset > .fields > .field .additional{
	margin-top:12px;margin-bottom:12px;
}

.form-create-account{
	.fieldset > legend,.fieldset > .legend{ min-height:67px; }
}