html{
	font-size:#{$baseFontSize}px;
	overflow-x:hidden;
	width:100%;height: 100%;
}

body{
	/* fix pour font trop bold sur mac (requis firefox25 pour le moz-osx-font-smoothing) */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width:100%;height: 100%;
	font-size:inherit;
}

*{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

img{
	-ms-interpolation-mode: bicubic;
	max-width:100%;height:auto;
	border: 0;
}
// Fix gif location
.slick-loading .slick-list{
	background: #fff url('../../images/ajax-loader.gif') center center no-repeat;
}

/*
:not(html, .page__header) {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}*/

.grecaptcha-badge{
	opacity:0 !important;
}