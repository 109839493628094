body{
	font-family:$f_main;
	color:$c_dark_grey;
}

.page__titlebar{
	position:relative;
	display:block;

	&--margin--bottom--0{ padding-bottom:0; }
	&--margin--top--0{ padding-top:0; }
	&--margin--0{ padding:0; }

	&--margin--bottom--20{ padding-bottom:20px; }
	&--margin--top--20{ padding-top:20px; }
	&--margin--20{ padding:20px 0; }

	&--margin--bottom--40{ padding-bottom:40px; }
	&--margin--top--40{ padding-top:40px; }
	&--margin--40{ padding:40px 0; }

	&--margin--bottom--60{ padding-bottom:60px; }
	&--margin--top--60{ padding-top:60px; }
	&--margin--60{ padding:60px 0; }

	&--margin--bottom--80{ padding-bottom:80px; }
	&--margin--top--80{ padding-top:80px; }
	&--margin--80{ padding:80px 0; }

	&--margin--bottom--100{ padding-bottom:100px; }
	&--margin--top--100{ padding-top:100px; }
	&--margin--100{ padding:100px 0; }

	&--margin--bottom--120{ padding-bottom:120px; }
	&--margin--top--120{ padding-top:120px; }
	&--margin--120{ padding:120px 0; }

	&--margin--bottom--140{ padding-bottom:140px; }
	&--margin--top--140{ padding-top:140px; }
	&--margin--140{ padding:140px 0; }
}

a{
	@include transition();
	text-decoration:none;
	color:$c_primary;

	&:hover,&:focus{
		text-decoration:none;
		color:$c_dark_blue;
	}
	&:visited{ color:$c_primary; }
}

.h1{
	margin:0 0 20px 0;

	@include rfs(100);line-height:0.6; //fix line height for viva pro font
	font-weight:normal;
}
.h2{
	margin:0 0 30px 0;

	@include rfs(34);line-height:1.2;
	font-weight:900;
}
.h3{
	margin:0 0 20px 0;

	@include rfs(28);line-height:1.2;
	font-weight:700;
}
.h4{
	margin:0 0 15px 0;

	font-size:rem(22);line-height:1.2;
	font-weight:600;
}
.h5{
	font-size:rem(18);line-height:1.2;
	margin:0 0 10px 0;
	font-weight:500;
}
.h6{
	font-size:rem(16);line-height:1.2;
	margin:0 0 5px 0;
	font-weight:500;
}

.p{
	font-size:rem(16);line-height:rem(28);
	font-weight:400;
}

.h1, .h2, .h3, .h4, .h5, .h6, .headline, .p{
	position:relative;

	&:last-child{ margin-bottom:0; }

	&--margin--0{ margin-bottom:0; }
	&--margin--5{ margin-bottom:5px; }
	&--margin--10{ margin-bottom:10px; }
	&--margin--15{ margin-bottom:15px; }
	&--margin--20{ margin-bottom:20px; }
	&--margin--25{ margin-bottom:25px; }
	&--margin--30{ margin-bottom:30px; }
	&--margin--35{ margin-bottom:35px; }
	&--margin--40{ margin-bottom:40px; }
	&--margin--45{ margin-bottom:45px; }
	&--margin--50{ margin-bottom:50px; }
	&--margin--55{ margin-bottom:55px; }
	&--margin--60{ margin-bottom:60px; }
	&--margin--65{ margin-bottom:65px; }
	&--margin--70{ margin-bottom:70px; }
	&--margin--75{ margin-bottom:75px; }
	&--margin--80{ margin-bottom:80px; }
	&--margin--85{ margin-bottom:85px; }
	&--margin--90{ margin-bottom:90px; }
	&--margin--95{ margin-bottom:95px; }
	&--margin--100{ margin-bottom:100px; }

	&--centered{text-align: center;}
	&--lowercase{ text-transform:lowercase; }
	&--uppercase{ text-transform:uppercase; }
	&--no-transform{ text-transform:none; }

	&--weight--thin{ font-weight:100; }
	&--weight--extra-light{ font-weight:200; }
	&--weight--light{ font-weight:300; }
	&--weight--normal{ font-weight:400; }
	&--weight--medium{ font-weight:500; }
	&--weight--semi-bold{ font-weight:600; }
	&--weight--bold{ font-weight:700; }
	&--weight--extra-bold{ font-weight:800; }
	&--weight--black{ font-weight:900; }

	&--italic{ font-style:italic; }

	&--color--primary{ color:$c_primary; }
	&--color--secondary{ color:$c_secondary; }
	&--color--dark-blue{ color:$c_dark_blue; }
	&--color--accent{ color:$c_accent; }
	&--color--dark-grey{ color:$c_dark_grey; }
	&--color--light-grey{ color:$c_light_grey; }
	&--color--grey{ color:$c_grey; }
	&--color--black{ color:$c_black; }
	&--color--white{ color:#ffffff; }

	&--font--sans-serif{ font-family:$f_main; }
	&--font--script{ font-family:$f_script; }

    &--onlyprint{display:none;}
	&--fancy{
		padding-top:1.5rem;padding-bottom:0.8rem;
		font-family:$f_script;
		position:relative;
		overflow:hidden;

		&__text{
			display:inline-block;position:relative;
			padding-right:1rem;padding-left:1rem;

			&::before,&::after{
				position:absolute;display:inline-block;
				background-color:$c_primary;
				width:205px;height:2px;
				overflow:hidden;
				content:" ";
				top:50%;
			}
			&::before{ right:calc(100% + 30px); }
			&::after{ left:calc(100% + 30px); }
		}

		&__sub{
			position:relative;display:block;
			margin-top:10px;

			font-family:$f_main;
			font-size:25%;line-height:1;
			text-align:center;
		}
	}

	.xsmaller{ font-size:50%;line-height:1; display:inline-block; }
	.smaller{ font-size:75%;line-height:1; display:inline-block; }
	.larger{ font-size:124%;line-height:124%; }
	.xlarger{ font-size:150%;line-height:150%; }

	&--font--script{
		.xsmaller,.smaller,.larger,.xlarger{
			line-height:0.8;
		}
	}
}

.headline{
	&--main{ @extend .h1; }
	&--section{ @extend .h2; }
	&--block{ @extend .h3; }
	&--sub{ @extend .h4; }
	&--xs{ @extend .h5; }

	a,a:hover,a:active{
		color:inherit;
	}

	&--fancy{ line-height:1.3; }
}

.wysiwyg{
	h1{
		@extend .h1;

		font-family:$f_script;
		text-align:center;
		color:$c_primary;
	}

	h2{ @extend .h2; }
	h3{ @extend .h3; }
	h4{ @extend .h4; }
	h5{ @extend .h5; }
	h6{ @extend .h6; }
	p{ @extend .p; }

	&--margin--0{ margin-bottom:0; }
	&--margin--10{ margin-bottom:10px; }
	&--margin--20{ margin-bottom:20px; }
	&--margin--30{ margin-bottom:30px; }
	&--margin--40{ margin-bottom:40px; }
	&--margin--50{ margin-bottom:50px; }
	&--margin--60{ margin-bottom:60px; }
	&--margin--70{ margin-bottom:70px; }
	&--margin--80{ margin-bottom:80px; }
	&--margin--90{ margin-bottom:90px; }
	&--margin--100{ margin-bottom:100px; }
}
