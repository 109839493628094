.category-tile{margin-bottom:30px;}

div[class*="col-"] > .tile{ height:100%; }

.tile{
	flex-direction:column;
	display:flex;

	position:relative;
	width:100%;height:auto;
	overflow:hidden;

	&:hover,&:focus{
		.tile__title{
			background-color:$c_primary;
			color:#ffffff;
		}
	}

	&:hover,&:focus{
		//.tile__photo__img{ opacity:0.45; }
	}

	&__title,&__photo,&__photo__img{
		display:block;
		height:auto;
	}

	&__title{
		justify-content:center;align-items:center;
		flex-direction:column;

		padding:30px 15px 15px 15px;margin:0;
		position:relative;display:flex;
		background-color:$c_light_blue;
		width:100%;height:auto;
		flex-grow:1;

		@include rfs(50);line-height:1;
		font-family:$f_script;
		text-transform:none;
		text-align:center;
		color:$c_primary;

		@include transition();

		&--smaller{
			@include rfs(50);
			@media all and (max-width:1200px){	@include rfs(43);}
			@media all and (max-width:768px){ font-size: 2.65rem;}
			@media all and (max-width:400px){ font-size: 2rem;}
		}
	}

	&__photo{
		position:relative;display:block;
		/*padding:0 0 100% 0;*/margin:auto;
		background-color:$c_secondary;
		width:100%;height:auto;

		&__img{
			@include transition(opacity);

			display:block;
			width:100%;height:auto;

			/*position:absolute;display:block;
			transform:translate(-50%,-50%);
			width:calc(100% + 1px);
			top:50%;left:50%;*/
			//opacity:0.70;
		}
	}

	&__counter{
		position:absolute;display:block;
		background-color:#ffffff;
		width:40px;height:40px;
		border-radius:50%;
		top:8px;right:8px;

		text-align:center;
		line-height:40px;
		color:$c_primary;
		font-weight:900;
		z-index:10;
	}
}

.ie11{
	.tile__photo{overflow: hidden;}
}