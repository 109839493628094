.pager{
	position:relative;

	font-size:rem(14);line-height:rem(16);
	font-weight:bold;
	color:$c_primary;

	&__amount{
		margin:0 10px 0 0;padding:0 4px;
		position:relative;
	}

	&__pages{
		position:relative;

		&:last-child{ margin-left:auto;margin-right:auto; }

		&__items{
			list-style-type:none;
			padding:0;margin:0;
		}
	}

	&__page{
		margin:0;padding:4px;
		display:block;

		&--next,&--previous,&--jump{
			.pager__page__item{
				background-color:transparent;
			}
		}

		&__item{
			background-color:#ffffff;
			padding:6px 11px;
			display:block;
		}

		a{ color:inherit; }

		a:hover,a:focus,
		&--current .pager__page__item{
			background-color:$c_primary;
			color:#ffffff;
		}
	}

	&__limiter{ margin-left:10px; }
}