.breadcrumbs{margin:inherit;}

.breadcrumb,.breadcrumbs{
	position:relative;display:block;
	/*margin:0 20px 0 0;*/padding:0;
	//flex-grow:1;

	font-family:$f_main;
	color:$c_dark_grey;

	&__items,.items{
		align-items:center;
		position:relative;
		display:flex;

		list-style-type:none;
		padding:0;margin:0;
	}
	&__item, .item{
		position:relative;display:block;
		margin:0;padding:0;

		font-size:rem(14);line-height:rem(16);
		text-transform:none;
		font-weight:400;
		color:inherit;

		&.home{
			color:$c_primary;
			font-weight:900;
		}

		&.search{
			text-align:left;
			width:auto;
		}

		a,strong{
			font-weight:inherit;
			color:inherit;
		}

		a:hover,&:focus{ color:$c_primary; }

		&::after{ display:none; }

		&:not(:last-child) .icon{
			position:relative;display:inline-block;
			margin:0 6px;padding:0;

			//font-size:rem(24);line-height:rem(22);
			vertical-align:top;text-align:center;
			font-weight:normal;
			color:$c_primary;
		}
	}
}