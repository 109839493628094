.nutrition-facts{
	margin:20px;padding:0.5rem;
	width:280px;height:auto;
	border:1px solid black;
	float:left;


	&__title {
		margin: 0 0 0.25rem 0;

		font-weight:bold;
		font-size:rem(32);
		color:#000000;
	}

	&__header{
		padding: 0 0 0.25rem 0;margin: 0 0 0.5rem 0;
		border-bottom: 10px solid black;

		p{ margin: 0; }
	}

	&__table{
		border-collapse:collapse;
		width:100%;

		thead{
			th,td{
				border: 0;
			}
		}

		th,td{
			border-top:1px solid black;
			padding:0.25rem 0;

			font-weight:normal;
			white-space:nowrap;
			text-align:left;
		}

		td{ &:last-child{ text-align:right; } }

		.blank-cell {
			border-top:0;
			width:1rem;
		}

		.thick-row{
			th,td {
				border-top-width:5px;
			}
		}
	}

	.small-info{ font-size: 0.7rem; }

	.thick-end{ border-bottom: 10px solid black; }
	.thin-end{ border-bottom: 1px solid black; }
}