.certifications{
	justify-content:flex-start;align-items:center;
	flex-wrap:wrap;

	position:relative;display:flex;
	padding:0;margin:0 0 20px;
	list-style-type:none;

	&__slider{
		padding-left:50px;padding-right:50px;

		.certification{
			margin:0;padding:4%;
		}
		.slick-list{ overflow:hidden;padding-left:0; }
		//.slick-track{ margin-left:0;margin-right:0; }
		.slider__controls{
			justify-content:space-between;align-items:center;
			display:flex;

			transform:translateY(-50%);
			width:100%;height:auto;
			position:absolute;
			top:50%;left:0;
			z-index:0;
		}
		.slider__arrow{
			&.slick-hidden,&.slick-disabled{ opacity:0; pointer-events:none; cursor:none; }
			width:32px;height:32px;line-height:32px;
			background:transparent;
			color:#ffffff;
			border:none;

			&:hover,&:focus{ color:$c_primary; background:transparent; }
		}
	}
	&__list{
		//padding-left:50px;padding-right:50px;
		z-index:1;
	}
}

.certification{
	padding:0;margin:0 20px 20px 0;

	&__icon{
		max-width:85px;height:auto;
		display:block;

		img{
			width:auto;height:auto;
			max-width:100%;
			display:block;
		}
	}
}