
.team-member h1{
    color: rgba(11, 111, 184, 1);
    font-size: 64px;
    text-align: center;
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}
.team-member .ct-field-repeater_fields{
    display: flex;flex-wrap:wrap;
}
.team-member .expando__content{
    padding:24px 15px;
    background-color: rgba(246, 247, 249, 0.89);
    border-top: 1px solid #ddd;
}
@media (max-width: 576px){
    .team-member .expando__content{padding:24px 0;}
}
.team-member .expando__trigger{
    font-size: 16px;padding:12px 15px;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: #2260a2;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
    outline: none;
}
