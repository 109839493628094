@import "partials/vars";

@media print{
	/***********************************************/
	/******************* GENERAL *******************/
	/***********************************************/
	.no-print{ display:none!important; }
	.print-only{ display:block!important; }
    .headline--onlyprint{display:block;}
	* {
		text-shadow: none !important;
		color: #000 !important; // Black prints faster: h5bp.com/s
		background: transparent !important;
		box-shadow: none !important;
	}

	html,body{ overflow:visible; }

	body{ padding-left:15px;padding-right:15px; }

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; // h5bp.com/t
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: 2cm .5cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	// Bootstrap components
	.navbar {
		display: none;
	}
	.table {
		td,
		th {
			background-color: #fff !important;
		}
	}
	.btn,
	.dropup > .btn {
		> .caret {
			border-top-color: #000 !important;
		}
	}
	.label {
		border: 1px solid #000;
	}

	.table {
		border-collapse: collapse !important;
	}
	.table-bordered {
		th,
		td {
			border: 1px solid #ddd !important;
		}
	}

	//body{
	//	max-width:670px;
	//	margin:0 auto;
	//}

	.container{
		width:100%;max-width:100%;
		padding:0;margin:0;
	}

	/**********************************************/
	/******************* LAYOUT *******************/
	/**********************************************/
	.page-wrapper{ padding-top:0 !important; }

	.breadcrumb{ display:none; }

	/**********************************************/
	/******************* HEADER *******************/
	/**********************************************/
	body.stickyheader{ padding-top:0; }
	.header{
		border-bottom:2px solid $c_primary;
		padding:0 0 20px 0;margin:0 0 30px;
		position:relative;

		&.page__header{
			&--up,
			&--down{
				display:block;position:relative;
				top:0 !important;
			}
		}

		.header-container,
		span.mobile-search,
		.action.main-menu-trigger{
			display:none;
		}

		.logo{
			width:160px;max-width:100%;
			margin:0;

			img.large-logo{ display:none; }
			img.small-logo{ display:block; }
		}

		&__content{
			border:none;
		}
	}

	.page{
		&__status,&.messages{
			padding:0;
		}
	}


	/***********************************************/
	/******************** CARDS ********************/
	/***********************************************/
	.card{
		page-break-inside:avoid;page-break-after:auto;page-break-before:auto;
		display:inline-block;
		width:100%;

		&__actions{ display:none; }
		&__content{ min-height:0; }
	}

	/**********************************************/
	/******************** TABS ********************/
	/**********************************************/
	.tabs{
		&__content{
			display:block !important;
			max-height:2000px;
			opacity:1;

			//color:red!important;

			*{ color:inherit !important; }

			margin:0 0 70px;padding:20px 20px;
			border:2px solid $c_primary;

			.headline.visually-hidden{
				width:auto;height:auto;
				position:relative;
				margin:0 0 20px;
			}

			&:last-child{ margin-bottom:0; }

			//.row{ margin-left:0;margin-right:0; }
		}
		&__label{ display:none; }
	}

	.section--bg .tabs__content,
	.tabs__content{
		border:none;
		padding:0;

		.product__block{ padding-left:15px;padding-right:15px; }
	}

	/**********************************************/
	/****************** PRODUCTS ******************/
	/**********************************************/
	.product{
		&__socials,&__buttons,&__actions,&__socials{ display:none !important; }

		&__gallery{
			&__images{
				margin-left:auto !important;margin-right:auto !important;
				max-width:480px;height:auto;

				.slick-track,.slide{
					width:auto !important;height:auto !important;
				}
			}
			&__nav{
				padding-left:0;padding-right:0;
				display:none !important;

				.slider__controls{ display:none; }

				.slick-track{
					position:relative;display:flex !important;
					align-items:stretch;justify-content:center;
					width:100% !important;

				}

				.slide{
					width:130px !important;height:auto !important;
					margin:0 10px 0 0 !important;padding:0 !important;
					display:block !important;

					img{
						position:relative;display:block;
						width:100%;height:auto
					}
				}
			}
		}

		&__informations{
			&__table{ page-break-inside:avoid; }
		}

		&__medias{ margin-bottom:40px; }

		&__main,
		&__block,
		&__spec,
		&__details__tab{
			page-break-inside:avoid !important;
		}

		&__main{ page-break-before:always; }

		&__block{
			&.col-md-4{
				width:50%;
			}
		}
	}

	.section__product-tab-description{
		div[class^="col-"]{
			flex-basis:50%;
			max-width:50%;

			&:last-child:nth-child(odd){
				max-width:100%;
				flex-basis:100%;
			}
		}
	}

	.catalog-product-view .section__product-tab-description .row{
		justify-content:flex-start;align-items:flex-start;
		flex-direction:row;flex-wrap:nowrap;
		display:flex;

	}

	.column-nutrition-facts{
		page-break-inside:avoid;
		align-self:flex-start;
	}

	.nutrition-facts{
		page-break-inside:avoid;
		float:none;
		width:auto;
		margin:0;
	}

	//.data,
	//.data__table,
	//.data__table__row,
	//.data__table__cell,
	//.data__table__body{
	.data{
		page-break-inside:avoid;
	}


	.section-product-main,.section-product-medias{
		max-width:100%;
		flex:0 0 100%;
	}

	.section-product-details{ padding:0; }
	.section-product-recipes{ padding:30px 0; }

	.product-social-links{ display:none; }

	.products.related{ margin:0; }

	.catalog-product-view .product__block--specs{ margin-top:0; }

	.products.related,
	.product__recipes{
		.col-sm-6{
			flex: 0 0 33.3333333333%;
			max-width: 33.3333333333%;
		}
	}

	.col-packaging{ margin-bottom:30px; }


	/**********************************************/
	/******************* FOOTER *******************/
	/**********************************************/
	.footer{
		&__main{ display:none; }
		&__bottom{
			border-top:3px solid $c_primary;
			padding-bottom:0;
		}
	}
}
