@media (max-width: 767px) {
	/********************************************************/
	/************************ HEADER ************************/
	/********************************************************/
	.header__customer li{border-bottom:none;}
	.header__customer__subs{border:none;  pointer-events:visible; }
	.header__customer__subs.logged-in{width:100%;}
	.header__additionals{width: 100%;}
	.page-wrapper{padding-top:90px;}
	html.menuopen{overflow-y:scroll;}
	.navigation a, .navigation a:hover{color:white;}
	.header .logo{margin:0 auto; display:flex; align-items: center; width:100%; max-width:135px; flex: 0 0 100%;}
	.header{padding:0 0 0 0;  transition: top 0.2s ease-in-out;z-index: 500;position:fixed;width:100%;top:0;
		&.page__header--up{top:-90px;}
		&.page__header--down{}
	}

	body.menu-open{overflow: hidden;
		.header__content{border-color:white;}
		.header-container{display:flex; flex-direction: column;  animation: slide-down .4s ease-out;}
		.page-wrapper{position:fixed; width:100%; z-index: 20;}
		.header__additionals{flex-grow:inherit;}
		.header .main-menu-trigger{
			top:8px; right: 5px;
			&::before{content:'\e616'; font-size: 50px;}
		}
		.header__additionals__lower{ display:none; }
		.header__customer{ border:none; }
		.page__header{ height: 100vh;position: fixed;left: 0;top: 0;right: 0;bottom: 0;overflow-y: scroll; padding-bottom: 20px; }
		.header__customer__subs{
			padding-bottom: 20px;
			@media (orientation: landscape) {padding-bottom: 40px;}
		}
	}
	body.search-open{
		span.mobile-search{
			left:0; top:28px;
			.closed{display:none;}
			.opened{display:block;}
		}
		.mobile-searchbar{display:block;}
		.header .search .input-text{height: 50px;line-height: 1;}
	}
	.mobile-search{
		.closed{display:block;}
		.opened{display:none;
			&::before{	content: '\e616'; font-size: 50px; line-height: inherit;color: $c_blue;	font-family: 'icons-blank-theme';vertical-align: middle;	display: inline-block;font-weight: normal; overflow: hidden;	speak: none; text-align: center;}
		}
	}
	.mobile-searchbar{border-top:2px solid $c_blue;}
	.action.main-menu-trigger{display: block; top:23px;cursor:pointer;}
	.header__content {	flex-direction: column; border-width:0 0 4px 0;
		span.mobile-search{ display: block; font-size: 1.5rem; line-height: 1.5rem; color: #2260a2; position:absolute; top:30px; left:15px; cursor:pointer;}
	}
	.header-container{display:none;z-index: 20;}
	.menu--main{
		flex-direction: column;
		> li ul{ display:none !important; }
	}
	.header__additionals__upper{flex-direction: column; border-bottom:2px solid $c_blue;}
	.nav-toggle{z-index:40;}
	.header__customer__subs{
		width:100%; border-width: 2px 2px 0 2px; opacity: inherit;left:0;

		&.not-logged{
			li:first-child{ display:none; }
		}

	}
	.header .logo img.large-logo{display: none;}
	.header .logo img.small-logo{display: block;}
	.page__status{margin-top:30px;}

	.products-grid.wishlist{
		margin-right:0;margin-bottom:0;
	}

	/*********************************************************/
	/************************ LAYOUTS ************************/
	/*********************************************************/
	.section{
		&--padding{
			&--60,
			&--80,
			&--100,
			&--120,
			&--140,
			&--160{
				padding-top:60px;padding-bottom:60px;
			}
			&--top--60,
			&--top--80,
			&--top--100,
			&--top--120,
			&--top--140,
			&--top--160{
				padding-top:60px;
			}
			&--bottom--60,
			&--bottom--80,
			&--bottom--100,
			&--bottom--120,
			&--bottom--140,
			&--bottom--160{
				padding-bottom:60px;
			}
		}
	}

	/*********************************************************/
	/************************* TEXTS *************************/
	/*********************************************************/
	.h1, .h2, .h3, .h4, .h5, .h6, .headline, .p{
		&--fancy{
			&__sub{
				font-size:40%;line-height:1;
				margin-top:0;
			}
		}
	}

	/********************************************************/
	/*********************** HOMEPAGE ***********************/
	/********************************************************/
	.cms-index-index{
		.page__banner{ margin-top:0; }
	}

	.section-home-indexation{
		.section__inner{ padding-bottom:0; }
	}

	.home{
		&__indexation{
			&__image{
				position:relative;
				top:inherit;right:inherit;
				transform:none;
				width:100%;
				margin-top:30px;
			}
		}
	}

	#tab1,#tab2,#tab3 {&:checked + label{flex:1;}}

	.category-tile{margin-bottom:1rem;  padding: 0 10px;}

	.slogan{
		padding:6% 20px;
	}

	/********************************************************/
	/******************* FLEXIBLE CONTENT *******************/
	/********************************************************/
	.flexible-content{
		//min-height:50vh;

		&__image__img{
			position:relative;display:block;
			max-width:100%;height:auto;
			min-width:0;min-height:0;
			width:auto;height:auto;
			top:0;left:0;

			transform:translate(0,0);
		}

		&--fullsize{
			.flexible-content__inner{
				padding-left:15%;padding-right:15%;
			}
		}

		> .row,
		> .container,
		> .container .row{
			//min-height:50vh;
		}
	}

	/********************************************************/
	/*********************** CATEGORY ***********************/
	/********************************************************/

	.cms-index-index, .page-products{
		.section--padding--bottom--60{padding-bottom:0;}
		.section--padding--bottom--120{padding-bottom:30px;}
		.section--padding--top--60{padding-top:30px;}
		.section--padding--top--120{padding-top:30px}
		//.section-home-indexation{margin-top: -30px;}
		.tabs--categories{flex-direction: column;}

		#select-findby{display:flex;}
		#tab2:checked + label::after,#tab1:checked + label::after,#tab3:checked + label::after{content:none;}
		.tabs__label{min-height: 60px;}
		.tabs__label::after{content:none;}
		.chevron::after { border-style: solid;	border-width: 0.15em 0.15em 0 0; content: ''; display: inline-block;
			height: 0.45em;	right: 7% ; position: absolute; top: 40%; transform: rotate(135deg);
			vertical-align: top; width: 0.45em;}
		.chevron.opened::after { transform: rotate(-45deg); top:47%;}
		.tabs-group.hidden{display:none;}
		.tabs-group{display:flex;margin-top:-1px;
			&:hover, &:focus{background:$c_primary; color:white;}
		}
	}

	/********************************************************/
	/*********************** PRODUCTS ***********************/
	/********************************************************/
	.catalog-product-view{
		.section-product-main{margin-top:20px;}
		.product__upper .product__flag{top:10px;left:20px;font-size:2.75rem;}
		.product__block--specs{margin-top:20px;}
		.section__product-tab-description .row{
			flex-direction: column;
			.col-sm-8,.col-sm-4{width:100%;max-width:100%;}
		}
		.nutrition-facts{width:280px;}
	}

	.filters{
		border-color:$c_primary;

		&--open{
			.filters__title{ background-color:$c_secondary; }
			.filters__content{ display:block; }
		}

		&__title{
			background-color:$c_primary;
			margin:0;padding:1rem 1rem;
			width:auto;height:auto;
			position:relative;
			top:0;left:0;
			clip:initial;

			font-size:rem(16);line-height:1;
			text-transform:uppercase;
			cursor:pointer;
			color:#ffffff;

			align-items:center;
			flex-wrap:nowrap;
			display:flex;

			&__icon{ margin-left:auto; }
		}
		&__actions{
			margin-bottom:2rem;
			border:none;
		}
		&__content{
			background-color:$c_light_blue;
			padding:1rem 1rem;
			display:none;
		}
		&__state{
			padding-top:0;padding-left:0;padding-right:0;
			border:none;
		}
	}
	.action.filter-clear{
		background-color:$c_accent;
		width:auto;max-width:100%;
		display:inline-block;
		padding:0.8rem;
		color:#ffffff;

		&:hover,&:focus{
			background-color:darken($c_accent,10%);
			color:#ffffff;
		}
	}
	.filter{
		&__title{
			margin-bottom:0;margin-top:-2px;
			border:2px solid $c_primary;
			background-color:#ffffff;
			color:$c_primary;
		}
		&.active{
			.filter__title{ color:#ffffff; }
			.filter__content{ border:2px solid $c_accent; }
		}
	}

	/*.product__share__button.btn,
	.product__button.btn,
	.product__button--download,
	.product__add-wishlist,
	.product__add-wishlist__button.btn,
	.product--view .product-addto-links button,
	.product--view .product-addto-links > div{
		width:100%;max-width:100%;
		margin-right:0;
	}*/

	.product__button--download{
		align-items:center;justify-content:flex-start;
		flex-flow:row nowrap;
		display:flex;

		margin-bottom:20px;
	}


	/********************************************************/
	/*********************** CONTACT ************************/
	/********************************************************/
	.contact-index-index{
		.page__banner{
			margin:50px 0 40px 0;
		}
		&.page-layout-2columns-left,&.page-layout-2columns-left{
			.sidebar-main,.sidebar-additional,.column.main{
				width:100%;
				padding:0;
			}
			.column.main{ order:3; }
			.sidebar-main{ order:1; margin:20px 0;}
			.sidebar-additional{ order:2; margin-bottom: 20px; }
		}
		.social-networks{ margin-top:0; }

		.business__locations{display:flex;flex-wrap:wrap;justify-content: space-between;
			.location{width:50%;}
		}
	}


	/********************************************************/
	/*********************** RECIPES ***********************/
	/********************************************************/
	.recipe--view{  margin-top: 20px;
		.article__headline{margin-bottom:40px;}
		.article__headcontent{ flex-direction: column;}
		.recipe_infos{margin-left:0; margin-top:20px}
	}
	.columns .sidebar-main{order:0;margin-bottom: 40px;}

	/********************************************************/
	/*********************** CUSTOMER ***********************/
	/********************************************************/
	.contact-index-index .page__titlebar{margin-top:20px;}


	/********************************************************/
	/************************ FOOTER ************************/
	/********************************************************/

	.footer__columns{
		&::before{content:none;}

		.footer__column{
			//border: 1px solid $c_blue;
			border:none;
		}
	}

	.footer{
		.newsletter-subscription{margin-top:10px;}
		.footer__block__title__icon .expander{display: block;}
		.footer__block__content{display:none;}
		.footer__block__title{
			border-width:1px;border-left:0;border-right:0;

			.closed{display:block;}
			&:hover, &:focus{color:white; background:$c_blue; cursor:pointer;}
		}

		.footer__block.open-content .footer__block__content{display:block;}
	}


	/********************************************************/
	/************************ OTHERS ************************/
	/********************************************************/
	.contentmanager-content-view .testimonies-container{max-width: 85%;margin: 0 auto;}



	.ie11, .ie10{
		.header__customer .header__customer__subs li{border-right:none;}
	}


}

@media (max-width: 767px) {
	.filter__title .btn__text, .product a .btn__text {font-size: 1rem;}
}
