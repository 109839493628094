/* FLEX */
.flex{
	display:flex;

	&--justify-start{ justify-content:flex-start; }
	&--justify-end{ justify-content:flex-end; }
	&--justify-center{ justify-content:center; }
	&--justify-space-between{ justify-content:space-between; }
	&--justify-space-around{ justify-content:space-around; }
	&--align-start{ align-items:flex-start; }
	&--align-end{ align-items:flex-end; }
	&--align-center{ align-items:center; }
	&--align-baseline{ align-items:baseline; }
	&--align-stretch{ align-items:stretch; }
	&--direction-row{ flex-direction:row; }
	&--direction-row-reverse{ flex-direction:row-reverse; }
	&--direction-column{ flex-direction:column; }
	&--direction-column-reverse{ flex-direction:column-reverse; }
	&--wrap{ flex-wrap:wrap; }
	&--wrap-reverse{ flex-wrap:wrap-reverse; }
	&--nowrap{ flex-wrap:nowrap; }
}

.flex-item{
	&--align-self-start{ align-self:flex-start; }
	&--align-self-end{ align-self:flex-end; }
	&--align-self-center{ align-self:center; }
	&--align-self-baseline{ align-self:baseline; }
	&--align-self-stretch{ align-self:stretch; }
}

// fake table
.tbl{display:table}
.tbl-rw{display:table-row;}
.tbl-cl{display:table-cell;}

// layouting
.center{
	text-align:center;vertical-align:middle;
	margin:auto;
}

.print-only{ display:none !important; }

.a-left{text-align:left;}
.a-right{text-align:right;}
.a-center{text-align:center;}

.left{ float:left; }
.right{ float:right; }

.no-gutter,.no-gutter [class*="col"]{
	padding-left:0;padding-right:0;
	margin-left:0;margin-right:0;
}

.visually-hidden{
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip: rect(0, 0, 0, 0);
	position: absolute;
}

.clearfix:before,
.clearfix:after {
	content:"";
	display:table;
}
.clearfix:after { clear:both; }
.clearfix { zoom:1; }

.nopadding{padding-left:0;padding-right: 0;}
.fullwidth{width:100%;}
.margin0auto{margin:0 auto;}
