.contact-index-index{
	.page__banner{ margin:70px 0 100px 0; }

	.columns{
		max-width:1160px;
		margin:0 auto;
	}
	&.page-layout-2columns-left,
	&.page-layout-2columns-right{
		.sidebar-main, .sidebar-additional{
			padding-right:30px;
			width:33.3333%;
		}
		.column.main{ width:66.6666%; }
	}
	.fieldset{margin:0;}
	.contact__form{
		.dropdown .mage-error[generated]{float:left;margin-bottom:20px;}
	}
	select{ font-size:1rem;
		&::-ms-expand { display: none;	}
	}
}

.locations{
	position:relative;
}

.location{
	position:relative;
	margin:0 0 40px;

	&:last-child{ margin-bottom:0; }

	&__address,&__phones{ line-height:rem(20); }
}

html[lang="fr"]{
	.contact-index-index{
		.page__titlebar__title{
			@media screen and (min-width: 992px){ font-size:rem(86); }
		}
	}
}

.contact-index-index{
	.wysiwyg{
		h1,h2,h3{ color:$c_primary; }
	}
}