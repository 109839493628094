.footer{
	position:relative;display:block;

	&__main{
		border:solid $c_primary;border-width:2px 0;
		z-index:20;
	}

	&__columns{
		padding:0 0 0 12%;margin:0;
		width:100%;height:auto;
		position:relative;


		&::before{
			border:solid $c_primary;border-width:2px 0;
			position:absolute;display:block;
			width:100%;height:64px;
			margin-top:-2px;
			top:0;left:0;
			content:" ";
		}
	}

	&__column{
		//justify-content:space-between;align-items:flex-start;
		justify-content:flex-start;align-items:flex-start;
		flex-direction:column;
		display:flex;


		border-left:2px solid $c_primary;
		background-color:#ffffff;
		margin:0;padding:0;
		position:relative;

		&--socials{
			background-color:$c_primary;
			padding:2% 3%;
			display:block;
			color:#ffffff;
            @media (max-width: 767px){padding:3%;}
			.footer__block__title{
				color:inherit;
				margin:0 0 12px;

				strong,a{ padding:0; }
			}
            .social-networks{
                @media (max-width: 767px){margin-top:16px;}
            }
		}
	}

	&__block{
		position:relative;display:block;
		width:100%;height:auto;

		&:first-child{ margin-top:-2px; }
		&:last-child{ margin-bottom:-2px; }

		&__content{
			position:relative;display:block;
			padding:30px 30px;margin:0;
		}

		&__title{
			border:solid $c_primary;border-width:2px 0;
			position:relative;display:block;
			padding:0;margin:0;

			font-size:rem(18);line-height:rem(20);
			text-transform:uppercase;
			font-weight:bold;
			color:$c_primary;

			strong,a{
				position:relative;display:block;
				padding:20px 30px;margin:0;
				width:100%;height:auto;

				font-size:inherit;line-height:inherit;
				font-weight:inherit;
				color:inherit;
			}
			a{ padding-right:70px; }

			&__icon{
				position:absolute;display:block;
				width:70px;height:100%;
				top:0;right:0;

				font-size:rem(24);line-height:1;
				color:inherit;

				svg,i{
					transform:translateX(-50%);
					position:absolute;
					top:19px;left:50%;
				}
				.expander{ display:none;}
			}

			&__link{
				&:hover,&:focus{
					background-color:$c_dark_blue;
					color:#ffffff;
				}
			}
		}
	}

	&__links-group{
		position:relative;display:block;
		width:100%;height:auto;

		.footer__block{ margin-top:-2px; }
	}

	&__links{
		position:relative;display:block;
		padding:0;margin:0 0 35px;

		&:last-child{ margin-bottom:0; }

		&__subtitle{
			margin:0 0 13px 0;
			display:block;
			width:100%;

			font-size:rem(18);line-height:rem(20);
			text-transform:uppercase;
			color:$c_dark_grey;
			font-weight:bold;
		}

		&__list{
			position:relative;display:block;
			padding:0;margin:0;

			li{
				font-size:rem(18);line-height:rem(20);
				list-style-type:none;
				color:$c_dark_grey;
				font-weight:normal;

				+ li{ margin-top:2px; }
			}

			a{
				background:transparent url("../../images/ui/bullet-lists.png") no-repeat left 4px;
				position:relative;display:block;
				width:100%;height:auto;
				padding:0 0 0 20px;

				font-size:inherit;line-height:inherit;
				font-weight:inherit;
				color:inherit;

				&:hover,&:focus{ color:$c_primary; }
			}
		}
	}



	.social-network{
		color:inherit;

		a{
			border:2px solid #ffffff;
            width:40px;height:40px;
            font-size: rem(20);line-height: rem(35);
			&:hover,&:focus{
				background-color:$c_dark_blue;
				border-color:$c_dark_blue;
				color:#ffffff;
			}
		}
	}

	&__bottom{
		padding:25px 0;
	}

	.copyright{ margin-top:0; }
}

.newsletter-subscription{
	position:relative;


	&__form{
		border:2px solid #ffffff;
		position:relative;
	}

	input[type="email"]{
		padding:0 190px 0 20px;
		border:none;

		color:$c_dark_grey;
		font-size:rem(16);
	}

	.field{ margin-bottom:0; }

	.actions{
		position:absolute;display:block;
		width:170px;height:100%;
		top:0;right:0;
	}

	.action.primary{
		position:relative;display:block;
		padding:0 20px;margin:0;
		width:100%;height:100%;

		font-size:rem(16);line-height:40px;
		text-transform:uppercase;
		font-weight:900;
	}
	@media all and (min-width : 991px) and (max-width : 1600px){
		.newsletter-subscription{
			&__form{border:none;}
		}
		input[type="email"]{padding:0 20px;}

		#newsletter-validate-detail{
			#newsletter-error{
				float:none;clear:both;
				margin:8px 0 12px 0;
			}
		}

		.actions{position:relative; width:auto;border: 2px solid #ffffff;margin-top:25px;}
	}

	@media all and (max-width : 415px){
		.newsletter-subscription{
			&__form{
				border:none;
			}
		}

		#newsletter-validate-detail{
			#newsletter-error{
				float:none;clear:both;
				margin:8px 0 12px 0;
			}
		}

		input[type="email"]{
			padding:0 20px;
		}

		.actions{position:relative; width:auto;border: 2px solid #ffffff;margin-top:25px;}
	}


	#newsletter-error{
		color:lighten($c_error,20%);
		margin-top:9px;
		float:left;
	}
}

.copyright{
	position:relative;display:block;
	margin:0;padding:0;

	font-size:rem(14);line-height:rem(18);
	text-align:center;

}
