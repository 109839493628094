.sub-search-for-content{
	position:relative;
	margin-top:40px;

	.contents-items{
		align-items:stretch;justify-content:flex-start;
		display:flex;

		position:relative;
		margin:0 -10px;
	}

	.content-item{
		padding:10px 8px;
		width:25%;

		.ct-link{
			background-color:$c_blue;
			padding:8px 12px;
			display:block;
			width:100%;

			font-size:rem(16);line-height:rem(18);
			font-weight:bold;
			color:#ffffff;

			&:hover,&:focus{
				background-color:$c_dark_grey;
				color:#ffffff;
			}
		}
	}
}