.stats{
	margin:-#{rem(6)} -#{rem(6)};
	position:relative;

	@media all and (min-width:420px){
		padding:-#{rem(10)};
	}
	@media all and (min-width:576px){
		padding:-15px -15px;
	}

	&__item{
		padding:rem(6) rem(6);
		align-self:stretch;

		@media all and (min-width:420px){
			padding:rem(10);
		}
		@media all and (min-width:576px){
			padding:15px 15px;
		}

		.stat{ height:100%; }
	}
}
.stat{
	justify-content:flex-start;align-items:flex-start;
	position:relative;display:flex;
	flex-flow:column wrap;

	> *{ max-width:100%; }

	&__icon{
		margin:0 0 Vmin(50);
		width:100%;

		img{
			width:auto;max-width:100%;height:auto;
			display:block;position:relative;
			margin:0 auto;padding:0;
		}
	}

	&__main{
		flex:1 0 auto;
		width:100%;

		text-align:center;
		color:$c_primary;

		justify-content:center;align-items:center;
		position:relative;display:flex;
		flex-flow:column wrap;

		> *{ max-width:100%; }
	}

	&__amount,&__label{
		position:relative;display:block;

		text-transform:uppercase;
		line-height:1.1;
		font-weight:900;
	}
	&__amount{
		margin:0 0 Vmin(6);
		font-size:rem(46);

		@media all and (max-width:576px){
			font-size:rem(22);
		}
		@media all and (max-width:420px){
			font-size:rem(13);
		}
	}
	&__label{
		font-size:rem(26);

		@media all and (max-width:575px){
			font-size:rem(15);
		}
		@media all and (max-width:419px){
			font-size:rem(11);
		}
	}

	&__graphic{
		position:relative;display:block;
		margin:0 auto Vmin(8) auto;
		width:auto;height:auto;
		max-width:20px;

		@media all and (min-width:420px){
			max-width:30px;
		}
	}
}