.modal-popup{
	.action-close::before{font-weight: 800;}
}

.block-share{
	&__content{}
	&__title{@include rfs(26);margin-bottom:20px;display: flex;align-items: center;
		&__icon{font-size: 30px;margin-right:10px;}
	}
	&__form{
		&__label{font-weight: 600;display: block;margin-bottom: 5px;}
		&__input{}
		&__textarea{ overflow: scroll;}
	}
	&__input-wrapper{margin-bottom: 20px;}
	&__actions{margin-top:20px;
		@media screen and (max-width: 768px) {display: flex;justify-content: flex-end;}
	}
}

.modal-footer{
	a.action-primary,button.action-primary,input.action-primary,
	a.action-secondary,button.action-secondary,input.action-secondary{
		@extend .btn;
		margin:0 7px 7px 0;
	}
	a.action-primary,button.action-primary,input.action-primary{
		@extend .btn--raised;
	}
	a.action-secondary,button.action-secondary,input.action-secondary{
		@extend .btn--outline;
	}
}