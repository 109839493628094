.product{
	&__flag{
		position:absolute;display:block;
		background-color:$c_primary;
		padding:17px 8px 7px 15px;
		bottom:0;right:0;
		min-width:100px;
		z-index:5;


		font-size:rem(47);line-height:0.2;
		font-family:$f_script;
		text-align:center;
		color:#ffffff;
	}

	&--summary{
		padding:15px 15px;margin:0;//margin:0 0 15px;

		.product__name{
			margin:0 0 20px;
			display:block;

			font-size:rem(16);line-height:rem(18);
			color:$c_primary;
			font-weight:900;

			&__link{
				color:inherit;
				&:visited{ color:inherit; }
				&:hover,&:focus{ color:$c_dark_blue; }
			}
		}

		.product__inner{ flex-grow:1; }

		.product__details{
			flex-direction:column;
			display:flex;

			> *{ width:100%;max-width:100%; }
		}

		.product__size{
			margin:0;

			font-size:rem(14);line-height:rem(16);
			color:$c_dark_grey;

			&__label{
				text-transform:uppercase;
				font-weight:900;
			}

			&__label,&__value{ display:block; }
		}
	}

	&--view{
		.page__titlebar{
			.container{ padding-left:0;padding-right:0; }

			&__title{
				padding:0;margin:0;


				font-size:rem(30);line-height:rem(36);
				font-family:$f_main;
				text-transform:none;
				text-align:left;
				font-weight:900;

				.headline--fancy{
					&__text{
						padding-right:0;
						&::before,&::after{ display:none; }
					}

				}
			}
		}

		.product{
			&__upper .product__flag{
				position:relative;display:block;
				padding:0;margin:0 0 5px;
				background:none;

				font-size:rem(60);line-height:0.4;
				color:$c_primary;
				text-align:left;
			}

			&__informations{
				position:relative;
				margin:25px 0 0 0;

				&__table{
					flex-direction:column;
					display:flex;

					border:2px solid $c_primary;
					position:relative;
					margin:0 0 25px 0;
					overflow:hidden;
					width:100%;

					font-size:rem(16);line-height:rem(18);
					text-transform:none;
					color:$c_dark_grey;
					font-weight:normal;

					strong,p,span{
						font-weight:inherit;
						color:inherit;
					}

					&__row{
						justify-content:space-between;align-items:stretch;
						//flex-wrap:wrap;
						display:flex;

						&:last-child{ margin-bottom:-2px; }

						&--upper{
							text-transform:uppercase;
							text-align:center;
							color:$c_primary;
							font-weight:900;

							.product__informations__table__cell{
								padding:22px 5px; /*Fix for first row 3/3 */

								justify-content:center;align-items:center;
								flex-direction:column;
								display:flex;


								> *{ max-width:100%; }
							}
						}
					}

					&__cell{
						padding:22px 22px;margin:-2px 0 0 -2px;
						border:2px solid $c_primary;
						max-width:100%;
						flex:1 0 auto;
						width:auto;

						&--third{
							max-width:calc(33.3333% + 2px);
							flex:0 0 calc(33.3333% + 2px);
						}
						&--third2{
							max-width:calc(66.66666% + 2px);
							flex:0 0 calc(66.66666% + 2px);
						}
						&--full{
							max-width:100%;
							flex:0 0 100%;
						}
						&--half{
							flex:0 0 50%;max-width:50%;
						}
						&--quarter{
							flex:0 0 25%;max-width:25%;
						}

						&--label{
							border-right:transparent;
							color:$c_primary;

							strong{ font-weight:900; }

							+ .product__informations__table__cell{ border-left-color:transparent; }
						}

						&:last-child{ margin-right:-2px; }

						//&--third{ width:33.33%;flex:0 0 auto;  }
					}
				}
			}
		}

		&__main{
			position:relative;

			&__additionals{
				position:relative;

				&:last-child{
					.product__buttons:last-child{ padding-bottom:0;margin-bottom:0; }
				}
			}
		}

		.attribute.sku{
			padding:22px 0;margin:-2px 0 0 -2px;
			border:2px solid $c_primary;
			max-width:33.3333%;
			flex:1 0 33.3333%;
			width:auto;

			justify-content:center;align-items:center;
			flex-direction:column;
			display:flex;

			> *{ max-width:100%; }

			.value{ display:inline-block; }
		}



		.product.attribute.overview{
			justify-content:flex-start;align-items:stretch;
			display:flex;

			margin:0;padding:0;

			.value{
				border:2px solid $c_primary;
				margin:-2px -2px 0 -2px;
				padding:22px 22px;
				flex-grow:1;

				p{
					max-width:460px;
					&:last-child{ margin:0; }
				}
			}
		}

		.product-addto-links{
			justify-content:flex-start;align-items:center;
			flex-direction:row;flex-wrap:wrap;
			display:flex;

			margin:30px 0 0 0;

			> div, a, button{ margin:0 6px 6px 0; }
		}
	}


	&__sku{
		font-size:rem(14);line-height:rem(16);
		font-weight:normal;
		color:$c_primary;
		margin:0 0 8px;
	}

	&__socials{
		display:block;


		&__button{
			padding:0;margin:0 0 0 15px;

			font-size:rem(30);line-height:1;
		}
	}

	&__buttons{
		position:relative;
		margin:30px 0;
		width:100%;
        display: flex;flex-wrap:wrap;

	}

	&__button{
		&.btn{margin:0 8px 8px 0;}

        &--download{
			justify-content:flex-start;align-items:flex-end;
			flex-wrap:nowrap;
			display:flex;
			margin-right:40px;margin-bottom:20px;

			/*padding:15px 20px;*/
			position:relative;
			max-width:210px;

			&.btn{ margin-right:40px; }


			font-size:rem(16);line-height:rem(18);
			font-weight:bold;
			color:$c_primary;

			.icon--file{ font-size:rem(34); }
			.icon--download{ font-size:rem(22); }

			&:hover,&:focus{
				//background-color:$c_primary;
				color:$c_dark_blue;
			}

			.txt{
				padding:0 15px;
				display:block;
			}
		}
        &--mylist.btn{width:48%;
            .product-mylist-btn__text{display: flex;height: 100%;align-items: center;}
        }
        &--share.btn{width:48%;margin-left:3%;}
		&--contact.btn{ width:100%;max-width:100%;margin-bottom:15px;
            .btn__text{text-align: center;display: block;}
        }
	}

	&__specs{
		position:relative;display:block;
		width:100%;height:auto;
		margin-bottom:20px;

		&:last-child{ margin-bottom:0; }
	}

	&__spec{
		position:relative;
		margin:0 0 5px 0;

		font-size:rem(16);line-height:rem(24);

		&__label,&__value{
			display:inline-block;

			font-size:inherit;line-height:inherit;
			vertical-align:top;
		}

		&__label{
			color:$c_primary;
			font-weight:900;
		}

		&__value{
			font-weight:normal;
			color:$c_dark_grey;
		}
	}

	&__block{
		border-bottom:1px solid $c_primary;
		position:relative;display:block;
		padding:30px 30px;margin:0;
		width:100%;height:auto;

		&:first-child{ padding-top:0; }
		&:last-child{ padding-bottom:0;border-bottom:none; }

		.headline{ color:$c_primary; }
	}

	&__upper{
		padding-bottom:60px;
	}

	&__gallery{

		&__images,
		&__nav__slider{
			transition:opacity 300ms ease-out;

			width:1px;height:1px;
			margin:-1px;padding:0;
			position:absolute;
			opacity:0;


			&.slick-initialized{
				width:100%;height:auto;
				position:relative;
				opacity:1;
				margin:0;
			}
		}

		&__nav{
			margin-top:45px;padding:0 50px;
			width:100%;height:auto;
			position:relative;

			.slider__controls{
				position:static;
				display:block;
				z-index:5;
			}

			.slider__arrow{
				position:absolute;display:block;
				transform:translateY(-50%);
				z-index:5;
				top:50%;

				+ .slider__arrow{ margin-left:0; }
			}

			.slider__next{ right:0; }
			.slider__prev{ left:0; }

			.slide{
				position:relative;display:block;
				cursor:pointer;

				img{
					margin:0 auto;
					display:block;
				}
			}
		}
	}
	&__add-wishlist{
		position:relative;display:block;
		//margin:30px 0 0 0;

		&__button{ position:relative; }
	}
}

.section-product-recipes{
	.product__recipes__list,.products__list{
		justify-content:center;
	}
}


.section-product-details ~ .section-product-relationships{ margin-top:-30px; }

.column .block-addbysku .qty .qty,
.bundle-options-container .input-text.qty,
.cart.table-wrapper .col.qty .input-text,
.account .table-return-items .qty .input-text,
.table-giftregistry-items .field.qty .qty,
.block-giftregistry-shared-items .qty .qty,
.table.grouped .control .qty,
.block-wishlist-info-items .input-text.qty,
.multicheckout .table-wrapper .col .qty .input-text,
.account .table-wrapper .data.table.wishlist .box-tocart .qty,
.products-grid.wishlist .product-item .box-tocart input.qty,
.sidebar .block-addbysku .fieldset .fields .field.qty .qty{
	padding-left:5px;padding-right:5px;

	font-size:rem(16);line-height:1;
	text-align:center;
}

.products-grid,.products-list,.products{
	.field.qty{
		justify-content:flex-start;align-items:center;
		display:flex;

		> label.label{ margin:0 8px 0 0;padding:0; }
	}
}

.product__details__tab{
	.column-nutrition-facts{
		@media screen and (min-width: 768px) {padding:0;}
	}
	.col-packaging{
		@media screen and (min-width: 992px) {padding:0;}
		@media screen and (max-width: 992px) {margin-top:20px;
			&:first-child{margin-top:0;}
		}
	}
}

.ie10,.ie11{
	.product--view{
		.product__informations__table__cell{
			//border-left:none;border-right:none;
			&--third{ max-width:33.3333%;flex:33.3333%; }
			&--third2{ max-width:66.6666%;flex:66.6666%; }
		}
	}
}
.tabs--product-details{
    label.tabs__label .counter{margin-left:8px;
        &::before{content:"(";}
        &::after{content:")";} 
    }
}
