.salessheetgenerator-product-view,
.salessheetgenerator-product-download{
	font-family:Arial,Helvetica,sans-serif !important;

	.page-main{ padding:0;margin:0 auto; }
	.page-wrapper{ padding:15px; }

	margin:0;padding:0;

	body, .really-empty{margin:0; padding:0;}
}

.sales-sheet{
	padding:0;margin:0;
	table-layout:fixed;
	width:100%;
	max-width:100%;

	&__main-page-wrapper{padding: 10px 20px 20px 20px;}
	.td-wrapper{display:table;
		&__div{display: inline-block;vertical-align:top;/*vertical-align: middle;*/
			&--logo{width:35%}
			&--text{width:65%}
		}
	}

	p,.p{
		font-size:1rem;line-height:1.45;
		&:last-child{ margin-bottom:0; }
	}
	&__input{border:none !important;
		line-height: normal !important;
		padding:0 !important;
		font-size:rem(16) !important;
		height: inherit !important;
	}

	&__header{
		border:2px solid $c_medium_blue;
		padding:0;margin:0;
		table-layout:fixed;
		position:relative;
		color:$c_medium_blue;

		td{
			border:2px solid $c_medium_blue;
			vertical-align:middle;
			padding:12px 12px;
			text-align:center;

			&.sales-sheet__header__metas{ text-align:left; }
			&.sales-sheet__header__additionals{
				padding:0;
				tr{ &:last-child td{ border-bottom:none; } }
				td{ border-width:0 0 2px 0; }
			}
		}

		&__metas{ width:40%; }
		&__brand,&__protein{ width:15%; }
		&__additionals{
			width:30%;
			> table{ border:none; }
		}
	}

	&__features,&__highlights{

		&__col{
			position:relative;
		}

		&__box{
			background-color:$c_medium_blue;
			width:100%;height:auto;
			padding:12px 15px;
			color:#ffffff;

			p,li{
				font-size:inherit;line-height:inherit;
				font-weight:inherit;

				&:last-child{ margin-bottom:0; }
			}
		}
	}
	&__features{
		font-size:rem(18);line-height:1;
		font-weight:bold;
	}

	&__highlights{
		font-size:rem(20);line-height:1;
		text-transform:uppercase;
		text-align:center;
		font-weight:bold;

		&__col{
			padding-top:30px;
		}
	}

	&__wrapper{
		padding:0;
	}

	&__main{
		table-layout:fixed;
		position:relative;

		&__infos{
			&__col{
				padding:6px 20px 6px 20px;

				/*&:first-child{ padding-left:0; }
				&:last-child{ padding-right:0; }*/

				width:33.333%;
			}
		}
	}

	&__benefice{
		width:33%;display: inline-block;text-align: center;line-height: 1.2;
		font-size: 17px;font-weight: 600;padding: 0 20px;vertical-align: middle;
		&::first-letter{text-transform:capitalize;}
	}

	.product{
		&__sku{
			display:block;

			font-size:rem(17);line-height:1;
			font-weight:bold;
			color:$c_medium_blue;
		}
		&__name{
			font-size:rem(21);line-height:1;
			text-transform:uppercase;
			color:$c_medium_blue;

			&:last-child{ margin-bottom:0; }
		}
		&__protein{
			img{max-width:70px;}
			&__name{text-transform:uppercase;margin-top: 5px;}
		}
		&__recipes{
			margin-top:30px;
			border:none;

			> tbody, > thead{
				> tr > td,
				> tr > th{
					padding:0;
				}
			}

			&__main{
				table-layout:fixed;
				border:none;
			}
			&__intro{
				padding:0 10px 0 0;
				width:20%;
			}
		}
		&__recipe{
			padding:0 10px;
			width:26.66%;
		}
	}

	h1.product__name{
		font-weight:bold;margin-bottom:0;
	}
	h2.product__name{
		font-weight:500;margin-bottom:0;margin-top:0;
	}

	.attributes{
		&__box{
			padding:0;margin:0;
			bordeR:none
		}
		&__label{
			margin:0 0 0.9rem 0;

			letter-spacing:1px;text-transform:uppercase;
			font-size:16px;line-height:1.2;
			font-weight:bold;
			color:#3f80b9;
		}
	}

	.attribute{
		position:relative;display:block;
		margin-bottom:17px;

		&:last-child{ margin-bottom:0; }

		&__label,&__value{ width:100%;display:block; }
	}

	.nutrition-facts{
		max-width:100%;
		float:none;
		margin:0;

		&__title{
			font-size:rem(29);
		}

		&__table{
			.small-info{ font-size:0.675rem; }
			th,td{
				padding:0.185rem 0;
				font-size:rem(14);
			}
			.blank-cell,.blank-cell ~ td,.blank-cell ~ th{ padding-top:0; }
		}
	}

	.certifications{
		padding-top:20px;margin:0 0 12px 0;
		position:relative;display:block;
		width:100%;height:auto;
		clear:both;
	}
	.certification{
		margin:0 8px 8px 0;
		width:auto;
		float:left;

		&__icon{  }
	}

	.packaging-table{
		border:1px solid $c_dark_grey;
		position:relative;
		/*margin-top:15px;*/

		&__col{
			border:1px solid $c_dark_grey;
			padding:4px 9px;

			font-size:rem(15);line-height:1.2;

			&--head{
				background-color:$c_medium_blue;
				border-color:#ffffff;

				font-weight:bold;
				color:#ffffff;
				&:first-child{border-left-color:#444;}
				&:last-child{border-right-color:#444;}
			}
		}
	}

	&__footer{
		position:relative;
		padding:10px 0 0 0;
		color:$c_medium_blue;

		&__table{
			border:2px solid $c_medium_blue;
			/*table-layout:fixed;*/
			color:inherit;
			p,.p{ color:inherit; }
			/*td,th{ vertical-align:middle; }*/
		}

		&__col{
			padding:0;
			td{padding:0;}

			&--reps{ width:40%;padding-left:0;padding-right:0;border-left:2px solid $c_medium_blue;
				table{
					border:none;
					tbody,thead{
						tr > td, tr > th{
							padding:3px 5px;
						}
					}
				}
			}

			&--infos{ width:60%;
				.headline{font-size:rem(22);}
				.p,p{font-size:rem(14);line-height: 1.4;}
			}

			/*padding-top:15px;padding-bottom:15px;
			&--logo{ width:20%;padding-left:15px;padding-right:15px;}
			&--infos{ width:40%;padding-left:30px;padding-right:30px;
				.headline{font-size:rem(22);}
			}
			&--contact{ width:40%;padding-left:15px;padding-right:15px; border-left:3px solid $c_medium_blue;border-bottom:2px solid $c_medium_blue;  }
			&--infos{
				.headline{ display:block; }
				p{ font-size:rem(14);line-height:1; }
			}
			&--reps{}*/
		}

		&__rep{
			p,.p{ font-size:rem(13);line-height:1; }
		}
	}

	&__firstpage{
		border-collapse: collapse;

		td{padding:0;}

		.firstpage__img{
			img{
				display:block;position:relative;
				height:auto;max-height:none;
				width:100%;max-width:100%;
				margin:0;padding:0;
				border:0;
			}
		}

		.firstpage__name{
			font-size:rem(32);text-transform: uppercase;font-weight:bold;text-align:center;color:$c_medium_blue;height:150px;vertical-align: middle;
			border-bottom:3px solid $c_medium_blue;
			.main-title{max-width:85%;margin:0 auto;}
			.sub-title{font-weight: 500;margin-top: 10px;}
		}
		.firstpage__banner{text-align: center;}
	}

	.attributes{
		&--allergens{
			.p,p{font-size:rem(14);line-height: 1.4;}
		}
		&--reconstitution{padding-top:30px; }
	}

}