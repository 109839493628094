/*!
 * MXO Theme (https://mxo.agency/)
 * Copyright 2018 MXO Agency Team
 */

// Libs
@import "../../../bower_components/rfs/scss/rfs";

// Configs, utils and misc.
@import "partials/fonts";
@import "partials/vars";
@import "partials/mixins";
@import "partials/utils";
@import "partials/reset";

// Components
@import "components/banners";
@import "components/texts";
@import "components/buttons";
@import "components/forms";
@import "components/tabs";
@import "components/tiles";
@import "components/cards";
@import "components/slider";
@import "components/breadcrumbs";
@import "components/filters";
@import "components/toolbars";
@import "components/pager";
@import "components/certifications";
@import "components/data-tables";
@import "components/ratings";
@import "components/nutrition-facts";
@import "components/social-networks";
@import "components/advantages";
@import "components/articles";
@import "components/expandos";
@import "components/structured-blocks";
@import "components/messages";
@import "components/login";
@import "components/blog-post.scss";
@import "components/flexible-content.scss";
@import "components/popup.scss";
@import "components/perks.scss";
@import "components/teaser-area.scss";
@import "components/ads-banners.scss";
@import "components/stats.scss";
@import "components/newsletterbar.scss";

// Layout, pages and sections
@import "layout/layout";
@import "layout/header";
@import "layout/footer";
@import "layout/homepage";
@import "layout/expertises";
@import "layout/testimonies";
@import "layout/partners";
@import "layout/category";
@import "layout/products-list";
@import "layout/products";
@import "layout/recipes";
@import "layout/technical-sheet";
@import "layout/sales-sheet";
@import "layout/contact";
@import "layout/vipzone";
@import "layout/search";
@import "layout/iconography";
@import "layout/blog";
@import "layout/customer-account";
@import "layout/wishlist";
@import "layout/calculators";
@import "layout/modal";
@import "layout/about";
@import "layout/landings";
@import "layout/landing/achat-redonne";
@import "layout/comparator";
@import "layout/newsletter";
@import "layout/guestlist";
@import "layout/team";
