.flexible-content{
	/*min-height:70vh;*/width:100%;height:auto;max-width:100%;
	position:relative;
	overflow:hidden;

	&--fullsize{
		padding-top:11%;padding-bottom:11%;

		.flexible-content__inner{ padding:0 15px 0 8%; }
	}

	&--text-position{
		&--right{
			&.flexible-content--fullsize{
				.flexible-content__inner{
					margin-left:auto;padding-left:15px;padding-right:8%;
				}
			}
		}
		&--center{
			//border:1px solid red;

			.flexible-content__inner{
				padding-left:15px;padding-right:15px;
				margin-left:auto;margin-right:auto;
			}
		}
		&--left{
			.row{ flex-direction:row-reverse; }

			&.flexible-content--fullsize{
				.row{ flex-direction:row; }
			}
		}
	}

	> *{ z-index:1; }

	&:not(.flexible-content--fullsize){
		> .row, > .container, > .container .row{
			min-height:70vh;
		}
	}

	&__bg{
		background:center center no-repeat;background-size:cover;
		position:absolute;display:block;
		width:100%;height:100%;
		pointer-events:none;
		top:0;bottom:0;
		right:0;left:0;
		z-index:0;
		opacity:1;
	}

	&__title{
		margin:0 0 20px;
		@include rfs(56);

		&__emphasis{
			position:relative;display:block;
			font-family:$f_script;
			text-transform:none;
			font-style:normal;
		}
	}

	&__subtitle{
		@include rfs(56);line-height:1;
		font-family:$f_script;
		font-weight:400;

		position:relative;display:block;
		margin:0;
	}

	&__image{
		padding:0 0;margin:0;
		align-self:stretch;
		position:relative;
		overflow:hidden;

		&__img{
			position:absolute;display:block;
			transform:translate(-50%,-50%);
			min-width:100%;min-height:100%;
			max-width:none;max-height:none;
			width:auto;height:auto;
			top:50%;left:50%;
		}
	}

	&__inner{
		position:relative;
		padding:8% 8%;

		> *{ max-width:100%; }

		.wysiwyg{
			p{
				font-size:rem(18);line-height:rem(26);
				margin-bottom:25px;
			}
			ul{
				padding-left:20px;margin-bottom:30px;
				&:last-child{ margin-bottom:0; }

				li{
					font-size:rem(24);line-height:rem(26);
					margin-bottom:5px;

					&:last-child{ margin-bottom:0; }
				}
			}
		}
	}
}