.products-grid.wishlist .product-item-photo{ margin-bottom:0; }

.wishlist{
	position:relative;
	margin:0 auto;

	.products__list{ margin:-15px -15px; }
}

.form-wishlist-items{
	.actions-toolbar{
		margin:50px 0 0 0;

		.btn{ margin-right:15px; }
		.btn:last-child{ margin-right:0; }
	}
}