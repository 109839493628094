.blog-post{	margin:0 0 40px 0; display: flex; flex-direction: column; flex-wrap: wrap;
	@media all and (max-width:480px){ width:100%; max-width:100%; padding-right:0; padding-left:0; }
	&__image{ overflow:hidden;
		img{object-fit: cover;
			@media all and (max-width:450px){ width:100%; max-width:100%;}
		}
	}
	&__content{ max-width:360px; }
	&__title{ margin-bottom: 5px;
		h2{ font-weight: bold; @extend .h4; }
	}
	&__info{ color:$c_primary; }
	&__description{ margin-top: 5px;
		p{ margin:0; display: inline; }
	}
	&__link{ font-weight: bold; }
}