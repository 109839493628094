.add-to-comparator {
    border: 2px solid #002f6c;
    border-bottom: 0;
    display: flex;
    align-items: center;

    label {
        display: flex;
        color: $c_dark_grey;
        cursor: pointer;
        width: 100%;
        align-items: center;
        line-height: normal;
        padding: 13px;

        .input-status-unchecked {
            display: inline;
        }

        .input-status-checked {
            display: none;
        }

        &.status-opened {
            .input-status-unchecked {
                display: none;
            }

            .input-status-checked {
                display: inline;
            }
        }

        @media all and (max-width: 992px) {
            font-size: rem(14);
            padding: 10px;
        }
    }

    input[type=checkbox] {
        margin: 0 15px 0 0;
        cursor:pointer;
        padding: 0;
        top: inherit;
        border-radius: 2px;
        box-shadow: none !important;
        position: relative;
        min-width: 24px;
        min-height: 24px;
        appearance: none;
        border: 1px solid $c_turquoise;
        background: white;
        @media all and (max-width: 992px) {
            min-width: 16px;
            min-height: 16px;
            margin: 0 8px 0 0;
        }

        &::before {
            content: '';
            display: none;
            width: 9px;
            height: 16px;
            border: solid white;
            border-width: 0 3px 3px 0;
            position: absolute;
            top: 1px;
            left: 7px;
            transform: rotate(45deg);
            @media all and (max-width: 992px) {
                width: 7px;
                height: 12px;
                top: 0;
                left: 4px;
                border-width: 0 2px 2px 0;
            }
        }

        &:checked {
            background: $c_turquoise;

            &::before {
                display: block;
            }
        }
    }
}


#compare_list_container {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    width: 100%;
    flex-direction: column;
    min-width: 320px;

    .container {
        max-width: 1440px;
        padding-top: 0;
    }

    .compare-list-wrapper {
        background: $c_light_blue;
        padding: 20px;
        @media all and (max-width: 576px) {padding:10px 20px;}
        .container.flex {
            @media all and (max-width: 576px) {
                flex-direction: column;
            }
        }
    }

    .compare-list {
        &__heading {
            background: $c_dark_blue;
            width: 100%;
            cursor: pointer;

            &__title {
                color: white;
                text-transform: uppercase;
                font-weight: 900;
                @media all and (max-width: 400px) {
                    font-size: 14px;
                }
            }

            &__action {
                margin-left: auto;

                button {
                    appearance: none;
                    background: transparent;
                    color: white;
                    font-weight: 900;
                    border: none;
                    font-size: rem(16);

                    .btn-compare-toggle__showbar {
                        display: block;
                    }

                    .btn-compare-toggle__hidebar {
                        display: none;
                    }

                    .btn-compare-toggle__hidebar, .btn-compare-toggle__showbar {
                        span {
                            @media all and (max-width: 576px) {
                                display: none;
                            }
                        }
                    }

                    &.active {
                        .btn-compare-toggle__showbar {
                            display: none;
                        }

                        .btn-compare-toggle__hidebar {
                            display: block;
                        }
                    }
                }
            }
        }

        &__list {
            margin-right: 20px;
            width: auto;
            flex-grow: 1;
            @media all and (max-width: 769px) {
                flex-direction: column;
                margin-right: 0;
            }
        }

        &__actions {
            margin-left: auto;
            width: 270px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .btn-compare-product.disabled {
                opacity: 0.2;
                pointer-events: none;
            }

            @media all and (max-width: 769px) {
                margin-right: auto;
                margin-left: 20px;
                margin-top: 0;
            }
            @media all and (max-width: 576px) {
                margin-right: auto;
                margin-left: auto;
                margin-top: 0;
            }

            div {
                width: 100%;

                .btn-remove, .btn-compare-product {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .btn-remove {
        margin-top: 20px;
        text-transform: uppercase;
        display: block;
        text-align: center;
        font-weight: 900;

        &:hover {
            color: $c_accent;
        }
    }
}

.compared-item {
    width: 31.33333%;
    background: white;
    position: relative;
    margin: 0 1%;

    @media all and (max-width: 1100px) {
        flex-direction: column;
    }
    @media all and (max-width: 769px) {
        width: 100%;
        flex-direction: row;
        margin: 0 0 15px 0;
    }

    &__photo {
        display: flex;
        align-items: center;

        a {
            display: flex;align-items: flex-start;
        }

        &__img {
            transition: opacity 240ms ease-in-out;
            opacity: 1;
            max-width: 120px;

            &:hover {
                opacity: 0.85;
            }

        }

        @media all and (max-width: 1100px) {
            justify-content: center;
        }
    }

    &__infos {
        padding: 25px;
        width: 100%;
        @media all and (max-width: 1100px) {
            padding: 5%;
        }
    }

    &__name {
        a {
            color: $c_blue;
            font-weight: 900;

            &:hover {
                color: $c_accent;
            }
        }
    }

    &__sku {
        a {
            color: $c_blue;
            font-weight: 500;
            margin-bottom: 4px;

            &:hover {
                color: $c_accent;
            }
        }
    }

    &__action {
        position: absolute;
        right: 15px;
        top: 10px;
        color: $c_accent;

        &:hover {
            color: $c_black;
        }

        @media all and (max-width: 1100px) {
            top: 5px;
            right: 10px;
        }
    }
}

.compared-box {
    width: 31.33333%;
    background: white;
    position: relative;
    margin: 0 1%;
    min-height: 100px;
    border: 2px dashed rgba(23, 48, 104, 0.25);
    padding: 20px;
    align-items: center;
    @media all and (max-width: 769px) {
        width: 100%;
    }

    &__input {
        margin-right: 30px;
        @media all and (max-width: 1100px) {
            margin-right: 0;
            margin-bottom: 15px;
        }
        @media all and (max-width: 769px) {
            margin-right: 20px;
            margin-bottom: 0;
        }
        @media all and (max-width: 576px) {
            margin-right: auto;
        }
    }

    input {
        border-color: #E5E5E5;
        max-width: 150px;
        color: $c_dark_blue;
        font-weight: 900;
        @media all and (max-width: 769px) {
            max-width: 100%;
        }

    }

    i {
        color: $c_turquoise;
    }

    &__action {
        font-weight: 900;
        text-transform: uppercase;
        margin-left: 20px;

        &:hover {
            color: $c_dark_grey;

            i {
                color: $c_dark_grey;
            }
        }
    }

    @media all and (max-width: 1100px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    @media all and (max-width: 769px) {
        width: 100%;
        flex-direction: row;
        margin: 0 0 15px 0;
    }

}

.empty-comparator {
    border: 1px solid $c_blue;
    text-align: center;
    margin-bottom: 20px;
    padding: 60px;
    font-size: rem(16);
    line-height: normal;
    font-weight: 900;
    color: $c_blue;
}

.product-minibox {
    border: 2px solid $c_dark_blue;
    border-width: 2px 2px 2px 0;
    width: 33.33333%;
    @media all and (max-width: 769px) {
        min-width: 135px;
    }

    &:first-child {
        border-width: 2px;
    }

    &--empty {
        display: flex;
        margin: 0;
        width: 33.33333%;
        border-style: dashed;
        border-color: rgba(23, 48, 104, 0.25);
        min-height: 165px;

        .compared-box__input {
            margin-right: 20px;
            margin-left: 10px;
            @media all and (max-width: 769px) {
                margin-right: 0;
                margin-left: 0;
            }
        }

        @media all and (max-width: 769px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .compared-box__action {
            @media all and (max-width: 769px) {
                margin-left: 0;
                margin-top: 15px;
            }
        }
    }

    &__top {
        flex-grow: 1;
        position: relative;
        @media all and (max-width: 992px) {
            flex-direction: column;
        }
    }

    &__img {
        border: 2px solid $c_dark_blue;
        border-width: 0 2px 0 0;
        @media all and (max-width: 992px) {
            border-width: 0;
        }

        a {
            display: flex;
            width: 120px;
            height: 100%;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: center !important;
            transition: opacity 240ms ease-in-out;
            opacity: 1;

            &:hover {
                opacity: 0.85;
            }

            @media all and (max-width: 992px) {
                width: 100%;
                height: 120px;
            }

        }
    }

    &__content {
        padding: 15px;
        @media all and (max-width: 992px) {
            padding: 10px;
        }

        &__name {
            display: block;
            max-width: 90%;
            line-height: normal;

            &:hover {
                color: $c_accent;
            }

            @media all and (max-width: 769px) {
                font-size: rem(12);
                strong {
                    font-weight: 900;
                }
            }
        }

        &__sku {
            &:hover {
                color: $c_accent;
            }

            @media all and (max-width: 769px) {
                font-size: rem(12);
                font-weight: 500;
            }
        }
    }

    &__remove {
        position: absolute;
        right: 15px;
        top: 5px;

        a {
            color: $c_accent;

            &:hover {
                color: $c_dark_grey;
            }

            @media all and (max-width: 769px) {
                font-size: rem(14);
            }
        }

        @media all and (max-width: 992px) {
            top: 130px;
        }
        @media all and (max-width: 769px) {
            right: 10px;
        }
    }

    &__action {
        border-top: 2px solid $c_dark_blue;
        background-color: $c_blue;

        a {
            color: White;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            padding: 15px 15px 15px 30px;
            font-weight: bold;

            i {
                margin-left: auto;
                padding-left: 20px;
                @media all and (max-width: 769px) {
                    padding-left: 10px;
                }
            }

            &:hover {
                color: $c_accent;
            }

            @media all and (max-width: 769px) {
                font-size: rem(10);
                font-weight: 900;
                padding: 7px 15px 7px 15px
            }
        }
    }

}

.comparison {
   @media all and (max-width:420px){ overflow-x: auto;}
   @media all and (min-width:770px){ overflow-x: auto;}
}

.table-comparison {
    min-width: 992px;
    @media all and (max-width: 769px) {
        min-width: 410px;
        margin: 4.5% 0;
    }

    .td-spacer {
        height: 30px;
        border-bottom: none !important;
        @media all and (max-width: 769px) {
            display: none;
        }
    }

    .add-to-favs {
        text-align: center;
        padding: 30px 15px;

        a {
            color: $c_blue;
            font-size: rem(14);
            font-weight: 900;
            text-transform: uppercase;

            i {
                color: $c_accent;
                margin-right: 10px;
                @media all and (max-width: 769px) {
                    margin-right: 6px;
                }
            }

            &:hover {
                color: $c_dark_grey;

                i {
                    color: $c_dark_grey;
                }
            }

            @media all and (max-width: 769px) {
                font-size: rem(10);
            }
        }

        @media all and (max-width: 769px) {
            padding: 0 0 15px;
        }
    }

    &__list {
        @media all and (max-width: 769px) {
            margin-bottom: 10px;
        }
    }

    &__actions {
        margin-right: 15px;
        margin-top: auto;
        margin-bottom: auto;

        &__btn {
            &--return {
                @media all and (max-width: 769px) {
                    width: 100%;
                    order: 3;
                    margin-top: 5px;
                }
            }

            &--print {
                @media all and (max-width: 769px) {
                    width: calc(50% - 5px);
                    order: 1;
                    margin-right: 5px;
                    .btn {
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            &--share {
                @media all and (max-width: 769px) {
                    width: calc(50% - 5px);
                    order: 2;
                    margin-left: 5px;
                }
            }

            .btn {
                border-width: 2px;
                width: 100%;
                margin-bottom: 5px;
                @media all and (max-width: 769px) {
                    margin-bottom: 0;
                    padding: 5px 10px;
                    padding-left: 40px !important;
                    font-size: rem(12);
                    font-weight: 900;
                }

                .btn__icon {
                    @media all and (max-width: 769px) {
                        left: 12px !important;
                        font-size: rem(16);
                    }
                }
            }
        }

        @media all and (max-width: 769px) {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            width: 100%;
            padding: 10px 20px 10px 20px;
        }
    }

    thead {
        tr {
            @media all and (max-width: 769px) {
                width: 100%;
            }

            td {
                &:first-child {
                    width: 25%;
                    padding: 0;
                    border-bottom: none !important;
                    @media all and (max-width: 769px) {
                        position: fixed;
                        border: none;
                        bottom: 0;
                        left: 0;
                        background: white;
                        z-index: 100;
                        @media all and (max-width: 769px) {
                            width: 100%;
                        }
                    }


                }

                &:last-child {
                    width: 75%;
                    padding: 0;
                    border-bottom: none !important;
                    @media all and (max-width: 769px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    tbody {
        tr {
            &.header-row {
                th {
                    background: $c_dark_blue;
                    color: white;
                    padding-left: 20px;
                    text-align: left;
                    visibility: visible !important;
                    text-transform: uppercase;
                    @media all and (max-width: 769px) {
                        font-weight: 900;
                        padding: 7px 15px 7px 15px;
                        position: relative;
                        bottom: inherit;
                        left: 0;
                        height: auto;
                        font-size: rem(14);
                    }
                }

                &--sub {
                    th {
                        background: $c_accent;
                    }
                }

            }

            th {
                color: $c_blue;
                padding: 15px 30px 15px 40px;
                vertical-align: middle;
                strong{font-weight: 900;}
                @media all and (max-width: 769px) {
                    visibility: hidden;
                    position: absolute;
                    bottom: 0;
                    left: -9999px;
                    height: 0;
                }
            }

            &.table-row {
                border-bottom: 1px solid $c_blue;
                &.removed{display:none !important;}
                td {
                    color: $c_blue;
                    font-size: rem(14);
                    padding: 15px 30px;
                    width: 33.33333%;
                    text-align: center;

                    &:nth-child(even) {
                        background: $c_light_blue;
                        @media all and (max-width: 769px) {
                            background: white;
                        }
                    }

                    &:nth-child(odd) {
                        background: white;
                        @media all and (max-width: 769px) {
                            background: $c_light_blue;
                        }
                    }

                    @media all and (max-width: 769px) {
                        padding: 8px 10px;
                        text-align: left;
                    }
                }

                .table-row__cell {
                    vertical-align: middle;
                    position: relative;
                    @media all and (max-width: 769px) {
                        vertical-align: top;
                    }

                    &::before {
                        content: attr(data-label);
                        padding-right: 60px;
                        text-transform: uppercase;
                        display: none;
                        margin-bottom: 4px;
                        font-size: rem(10);
                        font-weight: 900;
                        @media all and (max-width: 769px) {
                            display: block;
                        }
                        @media all and (max-width: 480px) {
                           // word-break: break-all;
                        }
                    }

                    @media all and (max-width: 769px) {
                        font-size: rem(10);
                    }
                }

            }
        }

    }
}
.currently-hidden{
    margin-left:auto;font-size:rem(14);
    @media all and (max-width: 769px){font-size: rem(10);}
    a{
        &:hover,&:focus,&:active{color:$c_accent;}
    }
}
.btn-hide-row {
    padding: 5px;
    border-radius: 0;
    background: none;
    border: none;
    color: white;
    margin-left: auto;

    &:hover, &:active, &:focus {
        background: none;
        border: none;
        color: white;
        box-shadow: none;
    }

    &--insub {
        &:hover, &:active, &:focus {
            color: white;
        }
    }

    .plus {
        display: none;
    }

    .minus {
        display: block;
    }

    &.hided {
        .plus {
            display: block;
        }

        .minus {
            display: none;
        }
    }

    @media all and (max-width: 769px) {
        font-size: rem(16);
    }
}

.hider {
    color: $c_accent;cursor: pointer;
    font-size: rem(16);font-weight: 600;

    &--mobile {
        display: none;
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: rem(12);
        @media all and (max-width: 769px) {
            display: block;
        }
    }

    &--main {

        @media all and (max-width: 769px) {
            display: none;
        }
    }
}

body.catalog-product_compare-index {
    .modal-popup.confirm {
        .modal-inner-wrap{
        .modal-content{text-align: center;padding:0 15%;}
        .modal-footer{display: flex;justify-content: center;}
        }
    }

    main#maincontent {
        @media all and (max-width: 769px) {
            padding: 0;
        }
    }

    .footer__bottom {
        @media all and (max-width: 769px) {
            margin-bottom: 60px;
        }
    }

    @media print {
        .td-spacer{display:none;}
        table { page-break-inside:auto !important;font-size:1vw; }
        tr    { page-break-inside:avoid!important; page-break-after:auto!important; }
        thead { display:table-header-group !important; }

        .header .logo { display: flex !important; }
        .header__content{flex-direction: column; align-items: center;}
        .headline--onlyprint{color:$c_secondary !important;margin-top:20px;}
        .table-comparison {
            margin: 0;
        }
        .table-comparison__actions, .footer, .add-to-favs, .product-minibox__action, .product-minibox__remove, img,.currently-hidden,.btn-hide-row,.hider {
            display: none;
        }
        .product-minibox__content a {
            text-decoration: none !important
        }
        .page-main {
            padding: 0;
        }
        .comparison {
            overflow: visible !important;
        }
        .table-row {
            display: table-row !important;  border-bottom:none !important;
            &.removed{display: none !important;}
        }

    }
    thead
    {
        display: table-row-group !important;
    }


}

@media print {
    .megamenu-customers{display: none !important;}
    * {
        -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
        color-adjust: exact !important; /*Firefox*/
    }
}

.modal-share {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__modal {
        background: white;
        z-index: 10;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 800px;


        .modal-header {
            color: white;
            text-transform: uppercase;
            padding: 20px 30px;
            background: $c_blue;
            font-size: rem(18);
            font-weight: bold;

        }

        .modal-content {
            padding: 40px 25px 25px;
            max-height: 75vh;
            overflow-y: auto;

            label {
                color: $c_blue;
                font-weight: 500;
                margin-bottom: 5px;
                display: block;
            }

            &__inputbox {
                margin-right: 15px;
                width: calc(33.33333% - 15px);

                &:last-child {
                    margin-right: 0;
                }

                input {
                    border-color: $c_blue;
                }

                @media all and (max-width: 576px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            &__textarea {
                width: 100%;

                textarea {
                    border-color: $c_blue;
                    height: 84px;
                }
            }
        }

        .modal-actions {
            display: flex;
            justify-content: flex-end;
            padding: 20px 25px 0 25px;
            margin-bottom: 30px;

            .btn {
                margin-left: 15px;
                font-size: rem(14);
                font-weight: 900;
            }
        }
    }

    .modal-row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        align-items: flex-end;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        &--recipients--3 {
            border-bottom: 1px solid $c_blue;
            padding-bottom: 25px;
        }

        .remove-row {
            position: absolute;
            color: $c_accent;
            right: -10px;
            top: 37px;
            @media all and (max-width: 576px) {
                right: 2px;
                top: -1px;
            }
        }
    }

    .overlay-popup {
        display: block;
        opacity: 1;
        background-color: rgba(0, 0, 0, .75);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: all .5s ease-out;
    }

    .btn-addrecipient {
        color: $c_accent;
        font-weight: bold;
        background: none;
        border: none;
        padding: 0;
        font-size: rem(16);
        line-height: rem(20);

        &:hover {
            color: $c_dark_blue;
        }
    }

}

@media all and (max-width:410px){
    tr.header-row th div.flex{flex-direction: row-reverse;justify-content: flex-end;
        .currently-hidden{order:-1;}
        .btn-hide-row{margin-left: -5px;margin-right: 10px;}
    }
}
